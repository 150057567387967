import { ReactNode, useCallback, useEffect, useReducer } from "react";
import { GetFrontendConfiguration } from "../../../services/configuration/get-frontend-configuration";
import { getOrRetry } from "../../utilities/get-or-retry";
import { FrontendConfiguration, FrontendConfigurationContext, FrontendConfigurationContextProps } from "./frontend-configuration-context";

const enableLogging = false;

export type FrontendConfigurationProviderProps = {
    children: ReactNode
}

export function FrontendConfigurationProvider({ children }: FrontendConfigurationProviderProps) {

    let [state, dispatch] = useReducer(loggingReducer, defaultState);

    let getFrontendConfigurationHandler = useCallback(async () => {
        if (state.action != "idle") {
            // Only one fetch operation should be occurring at a time.
            return;
        }
        dispatch({ type: "fetching" })
        let configuration = await getOrRetry(GetFrontendConfiguration, 2000);
        dispatch({
            type: "fetched",
            configuration
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        void getFrontendConfigurationHandler()
        let interval = setInterval(() => void getFrontendConfigurationHandler(), 15 * 60 * 1000)
        return () => clearInterval(interval);
    }, [getFrontendConfigurationHandler]);

    useEffect(() => {
        if (state.configuration?.version) {
            console.log(`Pez backend 'v${state.configuration.version}' detected.`, state.configuration);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.configuration?.version]);

    let context: FrontendConfigurationContextProps = {
        isLoading: state.action == "fetching" && state.status == "no-cache",
        configuration: state.configuration
    }

    return (
        <FrontendConfigurationContext.Provider value={context}>
            {children}
        </FrontendConfigurationContext.Provider>
    )
}

type Action =
    | { type: "fetching" }
    | { type: "fetched", configuration: FrontendConfiguration }

type State = {
    status: "cached" | "no-cache",
    action: "fetching" | "idle",
    configuration?: FrontendConfiguration;
};

const defaultState: State = {
    status: "no-cache",
    action: "idle",
    configuration: undefined
}

function loggingReducer(state: State, action: Action): State {
    if (enableLogging) {
        let before = state;
        let after = reducer(state, action)
        return after;
    }

    return reducer(state, action);
}

function reducer(state: State, action: Action): State {

    if (action.type == "fetching") {
        return {
            ...state,
            action: "fetching",
        };
    }

    if (action.type == "fetched") {
        return {
            ...state,
            status: "cached",
            action: "idle",
            configuration: action.configuration
        };
    }

    return {
        ...state
    };
}
