import { BsBan, BsCheckCircle, BsFillPersonCheckFill, BsFillPersonXFill } from "react-icons/bs";

export type LineItemAdminState =
    | "unknown"
    | "pending"
    | "vendorAssigned"
    | "vendorConfirmed"
    | "fulfilled";

export type FormatLineItemAdminStateProps = {
    state?: LineItemAdminState;
}

export function FormatLineItemAdminState({ state }: FormatLineItemAdminStateProps) {
    switch (state) {
        case "pending":
            return <BsBan className="text-secondary" />
        case "fulfilled":
            return <BsCheckCircle className="text-success" />
        case "vendorAssigned":
            return <BsFillPersonXFill className="text-warning" />
        case "vendorConfirmed":
            return <BsFillPersonCheckFill className="text-success" />
        default:
            return <>{state ?? "-"}</>
    }
}

export function FormatLineItemAdminStateLabel({ state }: FormatLineItemAdminStateProps) {
    switch (state) {
        case "pending":
            return "Pending";
        case "fulfilled":
            return "Fulfilled";
        case "vendorAssigned":
            return "Assigned";
        case "vendorConfirmed":
            return "Confirmed";
        default:
            return <>{state ?? "-"}</>
    }
}
