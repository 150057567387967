import "./app.scss";

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { AppRouter } from "./router";

if (window.location.hostname == "localhost") {
  console.warn("Sentry is disabled on localhost.");
} else {
  let isBeta = window.location.host.includes("beta");

  Sentry.init({
    dsn: "https://a7354c73609cb49c149c5c1a4d2af340@o1198410.ingest.us.sentry.io/4506995398737920",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: isBeta ? "beta" : "production",
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const container = document.getElementById("root");
const root = createRoot(container!);

// Reason for rendering the component like this:
// This is to prevent createRoot triggering twice.
// https://stackoverflow.com/a/75689530
// https://reactrouter.com/en/main/routers/router-provider
root.render(
  <StrictMode>
    <AppRouter />
  </StrictMode>
);
