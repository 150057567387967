import "property-information"; // https://github.com/remarkjs/react-markdown/issues/747

import Markdown from "react-markdown";

export type ProductDescriptionProps = {
    text?: string;
}

export function ProductDescription({ text }: ProductDescriptionProps) {
    return (
        <Markdown>
            {text}
        </Markdown>
    )
}
