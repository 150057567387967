import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type GetProductsInput = object;

export const GetProducts: ApiHandlerWithAuth<GetProductsInput, GetProductsResult> = {
    handle: async (_, authHeader) => {
        let response = await fetch("/api/v1/admin/products", {
            method: "GET",
            headers: getDefaultHeaders(authHeader)
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as GetProductsResult;
    }
}

export type GetProductsResult = {
    results: Product[],
    hasMore: boolean
}

export type Product = {
    productId: string;
    name: string;
    productDescription: string;
    vendorDescription: string;
    minimumPerOrder: number;
    published: boolean;
    capacity: number;
    categoryId: string;
    categoryName: string;
    primaryImageLink: string;
}
