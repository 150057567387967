import { keyof } from "../../../common/utilities/key-of";
import { VendorFormType } from "./vendor.type";

export const VendorFormProps = {
    firstNameProp: keyof<VendorFormType>("firstName"),
    lastNameProp: keyof<VendorFormType>("lastName"),
    businessNameProp: keyof<VendorFormType>("businessName"),
    emailAddressProp: keyof<VendorFormType>("emailAddress"),
    phoneNumberProp: keyof<VendorFormType>("phoneNumber"),
    zipCodeProp: keyof<VendorFormType>("zipCode"),
    providedServicesProp: keyof<VendorFormType>("providedServices"),
    otherProvidedServiceProp: keyof<VendorFormType>("otherProvidedService"),
    businessGoalsProp: keyof<VendorFormType>("businessGoals"),
    otherBusinessGoalProp: keyof<VendorFormType>("otherBusinessGoal")
};
