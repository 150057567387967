import { GetProductKind } from "../../../../../services/public/ordering/get-products";
import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type GetOrderLineItemsInput = {
    orderId: string;
};

export const GetOrderLineItems: ApiHandlerWithAuth<GetOrderLineItemsInput, GetOrderLineItemsResult> = {
    handle: async (input, authHeader) => {
        let response = await fetch(`/api/v1/admin/orders/${input.orderId}/line-items`, {
            method: "GET",
            headers: getDefaultHeaders(authHeader)
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as GetOrderLineItemsResult;
    }
}

export type GetOrderLineItemsResult = {
    results: LineItem[],
    hasMore: boolean
};

export type LineItemAdminState =
    | "unknown"
    | "pending"
    | "vendorAssigned"
    | "vendorConfirmed"
    | "fulfilled";

export type LineItem = {
    lineItemId: string;
    parentLineItemId?: string;
    arrivalDateTime?: string;
    arrivalTimeRequired: boolean;
    count: number;
    kind: GetProductKind;
    primaryImageLink: string;
    productDescription: string;
    productId: string;
    productName: string;
    productOptionType: string;
    productUnit: string;
    productUnitPluralized: string;
    sortOrder: number;
    setupTimeMinutes?: number;
    teardownTimeMinutes?: number;
    totalPrice: number;
    comment?: string;
    adminState: LineItemAdminState;
    adminComment?: string;
    adminAssignedVendor?: LineItemVendor;
};

export type LineItemVendor = {
    lightWeightVendorId: string;
    name: string;
    description?: string;
    emailAddress: string;
    phoneNumber?: string;
}
