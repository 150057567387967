import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type GetOrderAdditionalFeesInput = {
    orderId: string;
};

export const GetOrderAdditionalFees: ApiHandlerWithAuth<GetOrderAdditionalFeesInput, GetOrderAdditionalFeesResult> = {
    handle: async (input, authHeader) => {
        let response = await fetch(`/api/v1/admin/orders/${input.orderId}/additional-fees`, {
            method: "GET",
            headers: getDefaultHeaders(authHeader)
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as GetOrderAdditionalFeesResult;
    }
}

export type GetOrderAdditionalFeesResult = {
    results: AdditionalFee[],
    hasMore: boolean
};

export type AdditionalFee = {
    name: string;
    totalPrice: number;
    comment?: string;
};
