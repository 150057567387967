import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type UpdateLineItemInput = {
    orderId: string;
    lineItemId: string;
    comment?: string;
    state: LineItemState;
    lightWeightVendorId?: string;
};

export type LineItemState =
    | "unknown"
    | "pending"
    | "vendorAssigned"
    | "vendorConfirmed"
    | "paymentConfirmed"
    | "fulfilled";

export const UpdateLineItem: ApiHandlerWithAuth<UpdateLineItemInput, void> = {
    handle: async (input, authHeader) => {
        let response = await fetch(`/api/v1/admin/orders/${input.orderId}/line-items/${input.lineItemId}`, {
            method: "PUT",
            headers: getDefaultHeaders(authHeader),
            body: JSON.stringify({
                state: input.state,
                comment: input.comment ?? "",
                lightWeightVendorId: input.lightWeightVendorId
            })
        });

        if (!response.ok) {
            throw response;
        }
    }
}
