import { useMemo } from "react";
import { FormatTime } from "../../../../common/utilities/formatters/format-date";
import { SimpleTooltip } from "../../../../common/utilities/simple-tooltip";
import { useOrderContext } from "../provider/use-order-context";
import { ProductThumbnail } from "./line-items/product-thumbnail";
import "./services-arrival-time-header.scss";

export const ServicesArrivalTimeHeader = ({ open }: { open: boolean }) => {
    let { state: { order } } = useOrderContext();
    const { lineItems, eventAddress, eventSchedule } = order ?? { lineItems: undefined };
    const arrivalRequiringLineItems = useMemo(() => {
        return lineItems?.filter((lineItem) => lineItem.arrivalTimeRequired) || [];
    }, [lineItems]);

    return (
        <div className="d-flex flex-column flex-fill">
            <div className="mt-1">
                Services Start Time
            </div>
            {!open && eventSchedule && eventAddress && (
                <section className="d-flex flex-wrap mt-3">
                    {arrivalRequiringLineItems.map((lineItem) => (
                        <SimpleTooltip title={lineItem.productName} key={lineItem.productId}>
                            <div className="w-10 d-flex flex-column mb-3 me-3 arrival-time-summary-card">
                                <ProductThumbnail className="mb-2" src={lineItem.primaryImageLink} name={lineItem.productName} />
                                <div className="fw-bold time">
                                    <FormatTime dateTime={lineItem.arrivalDateTime} />
                                </div>
                            </div>
                        </SimpleTooltip>
                    ))}
                </section>
            )}
        </div>
    )
}
