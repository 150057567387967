import { useForm } from "react-hook-form";
import { LabelWithError } from "../../../../common/forms/label-with-error";
import { Product } from "../products-list";
import { useEffect } from "react";
import { EditProductCommand } from "../../../../common/api/handlers/admin/products/edit-product";
import { IsProductNameAvailableQuery } from "../../../../common/api/handlers/admin/products/is-product-name-available";
import { useApiHandlerWithAuth } from "../../../../common/api/use-api-handler-with-auth";

type EditProductForm = {
    name: string;
    productDescription: string;
    vendorDescription: string;
    minimumPerOrder: number;
    capacity: number;
    // categoryId?: string;
    // published?: boolean;
};

type ProductEditFormProps = {
    product: Product;
    productId: string;
    onSubmitted?: (product: Product) => void;
};

export const ProductEditForm = ({ product, productId, onSubmitted }: ProductEditFormProps) => {
    let {
        register,
        handleSubmit,
        formState: { errors, isValidating, isValid, isSubmitting },
        setError,
        setValue
    } = useForm<EditProductForm>({
        mode: "onChange",
        defaultValues: {
            name: "",
            productDescription: "",
            vendorDescription: "",
            minimumPerOrder: 1,
            capacity: 0
            // categoryId: "",
            // published: false
        }
    });
    let isNameAvailableHandler = useApiHandlerWithAuth(IsProductNameAvailableQuery);
    let editProductHandler = useApiHandlerWithAuth(EditProductCommand);

    useEffect(() => {
        if (product) {
            setValue("name", product.name, { shouldValidate: !!product.name });
            setValue("productDescription", product.productDescription, { shouldValidate: !!product.productDescription });
            setValue("vendorDescription", product.vendorDescription, { shouldValidate: !!product.vendorDescription });
            setValue("minimumPerOrder", product.minimumPerOrder, { shouldValidate: product.minimumPerOrder > 0 });
            setValue("capacity", product.capacity, { shouldValidate: product.capacity > 0 });
            // setValue("categoryId", product.categoryId);
            // setValue("published", product.published);
        }
    }, [product, setValue]);

    // TODO: debounce this.
    let nameValidator = async (updatedProductName: string) => {
        if (product?.name == updatedProductName) {
            return true;
        }

        let result = await isNameAvailableHandler({ productName: updatedProductName });
        if (result?.available) {
            return true;
        }
        return "Product Name is already in use";
    };

    const submit = async (data: EditProductForm) => {
        try {
            const updatedProduct = { productId, ...data };
            await editProductHandler(updatedProduct);
            onSubmitted?.(updatedProduct);
        } catch (e) {
            console.warn(e);
        }
    };

    const fieldWrapperClasses = "d-flex flex-column p-3 bg-white rounded border";
    const fieldInfoClasses = "mt-2 field-info";

    return (
        <form className="row gy-3 product-edit-form" onSubmit={handleSubmit(submit)} noValidate>
            <div className="col-12">
                <div className={fieldWrapperClasses}>
                    <div className="form-floating">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Example Name"
                            {...register("name", {
                                validate: nameValidator,
                                required: "Product Name is required"
                            })}
                        />
                        <LabelWithError name="name" errors={errors}>
                            Product Name
                        </LabelWithError>
                    </div>
                    <span className={fieldInfoClasses}>
                        The product name is what vendors will see first. It&apos;ll also help you organize many products. The product name must be unique.
                    </span>
                </div>
            </div>

            <div className="col-12">
                <div className={fieldWrapperClasses}>
                    <div className="form-floating">
                        <textarea
                            className="form-control description"
                            placeholder="Product Description"
                            {...register("productDescription")}
                        />
                        <label htmlFor="productDescription">Product Description</label>
                    </div>
                    <span className={fieldInfoClasses}>
                        The product description should be used to describe the item and is visible to party planners. Note any unusual factors to help party planners select the correct product.
                    </span>
                </div>
            </div>

            <div className="col-12">
                <div className={fieldWrapperClasses}>
                    <div className="form-floating">
                        <textarea
                            className="form-control description"
                            placeholder="Vendor Description"
                            {...register("vendorDescription")}
                        />
                        <label htmlFor="vendorDescription">Vendor Description</label>
                    </div>
                    <span className={fieldInfoClasses}>
                        The vendor description should be used to describe the item and is visible to vendors. Note any unusual factors to help vendors select the correct product while they inventory.
                    </span>
                </div>
            </div>

            <div className="col-12">
                <div className={fieldWrapperClasses}>
                    <div className="form-floating">
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Example Name"
                            {...register("minimumPerOrder")}
                        />
                        <LabelWithError name="minimumPerOrder" errors={errors}>
                            Minimum Per Order
                        </LabelWithError>
                    </div>
                    <span className={fieldInfoClasses}>
                        The minimum amount of the product for an order.
                    </span>
                </div>
            </div>

            <div className="col-12">
                <div className={fieldWrapperClasses}>
                    <div className="form-floating">
                        <input
                            type="number"
                            className="form-control"
                            placeholder="Example Name"
                            {...register("capacity")}
                        />
                        <LabelWithError name="capacity" errors={errors}>
                            Capacity
                        </LabelWithError>
                    </div>
                    <span className={fieldInfoClasses}>
                        The capacity of the product. Units can be abstract e.g. a chair typically can provide a capacity to one person, while a bench may provide a capacity of many people. Use this to distinguish products sizes e.g. a 3-person table vs. a 6-person table.
                    </span>
                </div>
            </div>

            <footer className="col-12 d-flex mb-3">
                <button type="submit" className="btn btn-primary"
                    disabled={isSubmitting || isValidating || !isValid}>
                    {isSubmitting ? "Saving..." : "Save"}
                </button>
            </footer>
        </form>
    );
};
