import { HttpMethodEnum } from "../../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../../common/utilities/get-auth-request-header";

export type PutOrderEventAddressRequest = {
    streetLine1?: string;
    streetLine2?: string;
    city?: string;
    state?: string;
    county?: string;
    zipCode?: string;
    locationTypeId: string;
    setupTypeId: string;
};

export const putOrderEventAddress = async (orderId: string, request: PutOrderEventAddressRequest, signal?: AbortSignal): Promise<void> => {
    const url = `/api/v1/orders/${orderId}/event-address`;
    let response: Response = await fetch(`${url}`, {
        signal,
        method: HttpMethodEnum.PUT,
        body: JSON.stringify(request),
        headers: getAuthRequestHeaders()
    });

    if (!response?.ok) {
        throw response;
    }
};
