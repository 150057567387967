import { Nav, Navbar } from "react-bootstrap";
import { NavLink, Navigate, RouteObject, useOutlet } from "react-router-dom";
import { PromotionCodesEdit } from "./promotion-codes-edit";
import { PromotionCodesList } from "./promotion-codes-list";
import { PromotionCodesNew } from "./promotion-codes-new";

export const adminPromotionCodesRoutes: RouteObject[] = [
    {
        path: "/admin/promotion-codes",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <PromotionCodesList />
            },
            {
                path: "new",
                element: <PromotionCodesNew />
            },
            {
                path: ":promotionCodeId",
                element: <Navigate to="edit" replace />
            },
            {
                path: ":promotionCodeId/edit",
                element: <PromotionCodesEdit />
            },
        ]
    }
];

function Layout() {
    const outlet = useOutlet();
    return (
        <div className="d-flex flex-column products h-100">
            <h2 className="ms-2">Promotion Codes</h2>
            <PromotionCodesNavbar />
            <div className="m-2 flex-fill d-flex flex-column">{outlet}</div>
        </div>
    );
}

function PromotionCodesNavbar() {
    return (
        <Navbar expand="sm" className="border-bottom mb-2">
            <Navbar.Toggle aria-controls="admin-products-navbar" />
            <Navbar.Collapse id="admin-products-navbar">
                <Nav className="d-flex flex-fill align-items-end">
                    <NavLink to="/admin/promotion-codes" className="nav-link">
                        List
                    </NavLink>
                    <NavLink to="/admin/promotion-codes/new" className="nav-link">
                        Create
                    </NavLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
