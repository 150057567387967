import { useMemo } from "react";
import { SimpleTooltip } from "../simple-tooltip";
import { useDateTime, useTimeAgo } from "./use-date-time";

export type FormatDateProps = {
    dateTime?: string | null;
}

export function FormatDate(props: FormatDateProps) {
    let dateTime = useDateTime(props.dateTime);
    return dateTime
        ? (
            <SimpleTooltip title={props.dateTime}>
                <span>{dateTime.toLocaleString()}</span>
            </SimpleTooltip>
        )
        : (
            <span>-</span>
        )
}

const formatDateTime: Intl.DateTimeFormatOptions = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short"
};

export function FormatDateWithAgo(props: FormatDateProps) {
    let dateTime = useDateTime(props.dateTime);
    let ago = useTimeAgo(props.dateTime);

    let simpleFormat = useMemo(() => dateTime?.toLocaleString(formatDateTime), [dateTime])

    return dateTime
        ? (
            <SimpleTooltip title={simpleFormat}>
                <span className="text-truncate">
                    {dateTime.toLocaleString()} <span className="text-muted" style={{ fontSize: "0.9rem" }}>({ago})</span>
                </span>
            </SimpleTooltip>
        )
        : (
            <div>-</div>
        )
}

const formatTime: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short"
};


export function FormatTime(props: FormatDateProps) {
    let dateTime = useDateTime(props.dateTime);

    let simpleFormat = useMemo(() => dateTime?.toLocaleString(formatTime), [dateTime])

    return dateTime
        ? (
            <SimpleTooltip title={simpleFormat}>
                <span className="text-truncate">
                    {simpleFormat}
                </span>
            </SimpleTooltip>
        )
        : (
            <div>-</div>
        )
}
