import { BannerFooter } from "../../../common/utilities/banner-footer";
import { Footer } from "../../../common/utilities/footer";

export function ContactUsPage() {
    return (
        <main>
            <section className="d-flex justify-content-center my-5">
                <div className="container d-flex flex-column">
                    <h1 className="text-center my-3">Contact Us</h1>

                    <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "400px" }}>

                        <div className="bg-white border rounded p-3" style={{ maxWidth: "500px" }}>
                            <p className="mb-3">
                                We here at Partyeezy, Inc want you to party-EEZY and celebrate those moments that really matter.
                            </p>
                            <p className="">
                                Questions, comments, issues? We are always here!
                            </p>
                            <p>
                                Drop us a line or text on <a href="tel:6263449142">(626) 344-9142</a> or send an email to <a href="mailto:hello@partyeezy.com">hello@partyeezy.com</a>.
                            </p>
                        </div>

                    </div>

                </div>
            </section>
            <BannerFooter className="col-12 p-0" />
            <Footer />
        </main>
    );
}
