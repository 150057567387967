import { Link } from "react-router-dom";
import { Order } from "../../../../../common/api/handlers/admin/orders/get-order-by-id";
import { FormatDateWithAgo } from "../../../../../common/utilities/formatters/format-date";
import { Money } from "../../../../../common/utilities/formatters/money";
import { FormatAddress } from "../../../../../common/utilities/orders/format-address";
import { ContactDetails, EventAddress, EventSchedule } from "../../../../../services/public/ordering/get-order-by-id";
import { FormatOrderState } from "../../shared/format-order-state";

type OrderDetailsHeaderSectionProps = {
    order: Order;
}

export function OrderDetailsHeaderSection({ order }: OrderDetailsHeaderSectionProps) {
    return (
        <div className="row">
            <div className="col-4">
                <div className="fs-4">Order Details</div>
                <OrderDetailsSection order={order} />
            </div>
            <div className="col-4">
                <div className="fs-4">Event Details</div>
                <EventDetailsSection {...order} />
            </div>
            <div className="col-4">
                <div className="fs-4">Contact Details</div>
                <ContactDetailsSection {...order} />
            </div>
        </div>
    )
}

function OrderDetailsSection({ order }: { order: Order }) {
    return (
        <div className="border bg-white rounded p-3">
            <div className="row">
                <div className="col">
                    <div className="sub-title">Order Number</div>
                    <div className="text-truncate">#{order.humanReadableOrderId}</div>
                </div>
                <div className="col">
                    <div className="sub-title">Amount</div>
                    <div className="text-truncate">
                        <Money amount={order.paymentIntent.amount} />
                    </div>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col">
                    <div className="sub-title">State</div>
                    <FormatOrderState state={order.state} />
                </div>
                <div className="col">
                    <div className="sub-title">Placed On</div>
                    <div className="text-truncate">
                        <FormatDateWithAgo dateTime={order.paymentIntent.createdOn} />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <div className="sub-title">Stripe Payment ID</div>
                    <a className="text-muted text-truncate"
                        href={`https://dashboard.stripe.com/payments/${order.paymentIntent.stripePaymentIntentId}`}
                        target="_blank"
                        rel="noreferrer">
                        {order.paymentIntent.stripePaymentIntentId}
                    </a>
                </div>
                <div className="col">
                    <div className="sub-title">Stripe Tax Calculation ID</div>
                    <div className="text-muted text-truncate">
                        {order.paymentIntent.stripeTaxCalculationId}
                    </div>
                </div>
            </div>
        </div>
    );
}

function ContactDetailsSection({ contactDetails }: { contactDetails: ContactDetails }) {
    return (
        <div className="border bg-white rounded p-3 position-relative">
            <div className="sub-title">Name</div>
            <div className="text-truncate">{contactDetails.name}</div>
            <div className="sub-title">Email</div>
            <div className="text-truncate">
                <a className="text-dark" href={`mailto:${contactDetails.emailAddress}`}>{contactDetails.emailAddress}</a>
            </div>
            <div className="sub-title">Phone Number</div>
            <div className="text-truncate">
                <a className="text-dark" href={`tel:${contactDetails.phoneNumber}`}>{contactDetails.phoneNumber}</a>
            </div>

            <div className="position-absolute end-0 top-0 p-3">
                <Link to={`/admin/messages/${contactDetails.phoneNumber}`}
                    className="btn btn-outline-primary">Message</Link>
            </div>
        </div>
    );
}

function EventDetailsSection({ eventSchedule, eventAddress }: { eventSchedule: EventSchedule, eventAddress: EventAddress }) {
    return (
        <div className="border bg-white rounded p-3">
            <div className="row">
                <div className="col-4">
                    <div className="sub-title">Duration</div>
                    <div className="text-truncate">
                        {eventSchedule.durationHours} hours
                    </div>
                </div>
                <div className="col-8">
                    <div className="sub-title">Starts On</div>
                    <div className="text-truncate">
                        <FormatDateWithAgo dateTime={eventSchedule.startOn} />
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-4">
                    <div className="sub-title">Location Type</div>
                    <div className="text-truncate">
                        {eventAddress.locationTypeDescription}
                    </div>
                </div>
                <div className="col-8">
                    <div className="sub-title">Setup Type</div>
                    <div className="text-truncate">
                        {eventAddress.setupTypeDescription}
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <div className="sub-title">Address</div>
                    <div className="text-truncate">
                        <FormatAddress address={eventAddress} />
                    </div>
                </div>
            </div>
        </div>
    );
}
