import { useEffect } from "react";

export function useDebouncedEffect(
    effect: (signal: AbortSignal) => void | Promise<void>,
    deps: unknown[],
    delay: number
) {
    // https://stackoverflow.com/a/61127960/2001966
    useEffect(() => {

        const controller = new AbortController();
        const timeout = setTimeout(async () => {
            try {
                await effect(controller.signal);
            } catch (error) {
                // Ignored.
            }
        }, delay);
        return () => {
            clearTimeout(timeout);
            controller.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps || []), delay]);
}
