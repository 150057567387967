[
  {
    "title": "Partyeezy?",
    "text": "One stop party shop where you can party now and pay later!"
  },
  {
    "title": "Service Area",
    "text": "Los Angeles County area, along with some parts of Orange County. \n\nIf your event is outside of our current service area you can still give us a call/text at 626-344-9142 to see if we will be able to deliver. We want you to Partyeezy.\n\nClick [here](/signup) to sign up for our newsletter and be the first to know when we're in your area!"
  },
  {
    "title": "How do I Partyeezy?",
    "text": "In your Pjs or your office, plan your party in 10 min or less.\n\nWe will curate multiple affordable party packages with only 3 simple questions. Party type, how many people, and location. Let us do the rest. No more multiple vendors and payments."
  },
  {
    "title": "Why Partyeezy?",
    "text": "Service baby!\n\nWe are here to make your party smooth and enjoyable. Weddings to birthdays, corporate parties, to any celebration. We have everything covered. Tacos to entertainers. Look at our packages and you'll see that we make it EEZY. Multiple payment options. 24 hour customer care and our Partyeezy Guarantee."
  },
  {
    "title": "Party Now and Pay Later?",
    "text": "Planning an awesome party can be expensive but that doesn't mean the party has to stop! We're partnering with Affirm to offer 0% payment options for qualifying customers so you can Partyeezy"
  },
  {
    "title": "Rentals",
    "text": "Leave the setup of inflatables to us. As for tables, chairs, & tents we will drop them off curbside.\n\nFeel free to call/text or email 24/7 regarding our setup services"
  },
  {
    "title": "How far in advance should I reserve?",
    "text": "We recommend you reserve about 2-3 weeks in advance. But don't worry, we can accommodate last minute reservations."
  },
  {
    "title": "Can I book a party within 48 hours?",
    "text": "Absolutely! We have an extensive network of vendors that can accommodate most bookings within 48 hours of your event."
  },
  {
    "title": "When do you deliver and pick up items?",
    "text": "We deliver between 7am-12pm. Pick up is the next day. Unless Same day pick up is requested (+30%)."
  },
  {
    "title": "Do we need power (electricity)?",
    "text": "Customers are responsible to provide electricity power and outlets for all rented items. If you need a generator or any other help with this call/text 24/7."
  },
  {
    "title": "I've seen some dirty jumpers!",
    "text": "Not with Partyeezy.com. Our inflatables are washed and sanitized prior to every party. Help us keep it that way by keeping the inflatables free of any trash, gum or decorations."
  },
  {
    "title": "What if I need to Cancel?",
    "text": "Take a look at our detailed cancellation policy and don't forget party interruption protection."
  },
  {
    "title": "Taco time?",
    "text": "We offer two options: drop-off delivery (think taco bar) and onsite Taco Man. Our delicious taco man services include a 1 hour setup time, 1.5 hour serving time and 1 hour clean up."
  },
  {
    "title": "How many Tacos?",
    "text": "You can estimate 4-5 tacos average per guest. Some eat more, some eat less. Either way you're going to enjoy some really good tacos."
  },
  {
    "title": "Are your tacos good?",
    "text": "Are they good? They are really good. We guarantee it. We have hit the streets and only work with the best! So just partyeezy."
  },
  {
    "title": "Do you really have a trompo or handmade tortilla option?",
    "text": "You heard it here first. Yes, one of the very few who offer this option. Call/text or email for further information."
  },
  {
    "title": "What Entertainer options do you have?",
    "text": "We have the perfect entertainment options to wow your guests. Face painting, Magicians, DJ's and balloon artists- we got you covered!"
  },
  {
    "title": "Balloon Arches?",
    "text": "Not just any balloon arches. We only work with hand selected artists to ensure the highest level of quality."
  },
  {
    "title": "Who wants to clean up, event cleanup services?",
    "text": "After a long party, who wants to worry about cleaning up? Not with Partyeezy.\n\nLet us pickup and dispose of trash. Organize your furniture and sweep up. Also did we mention we can return rentals to their proper place for pickup. You only need to provide the cleaning equipment and we do the rest."
  }
]
