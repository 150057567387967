import { ColDef, IDatasource, IGetRowsParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useRef } from "react";
import { GetLightWeightVendors, GetLightWeightVendorsInput, LightWeightVendor } from "../../../../common/api/handlers/admin/light-weight-vendors/get-light-weight-vendors";
import { useApiHandlerWithAuth } from "../../../../common/api/use-api-handler-with-auth";
import { DataGrid } from "../../../../common/utilities/data-grid";

export type LightWeightVendorGridProps = Omit<GetLightWeightVendorsInput, "offset" | "count">;

export function LightWeightVendorGrid({ ...options }: LightWeightVendorGridProps) {

    let columns = useMemo<ColDef<LightWeightVendor>[]>(() => [
        { field: "name", headerName: "Name", flex: 1, cellRenderer: TextRenderer, minWidth: 120 },
        { field: "emailAddress", headerName: "Email Address", flex: 2, cellRenderer: EmailRenderer, minWidth: 200 },
        { field: "phoneNumber", headerName: "Phone Number", flex: 1, cellRenderer: PhoneNumberRenderer, minWidth: 120 },
        { field: "description", headerName: "Description", flex: 2, cellRenderer: TextRenderer, minWidth: 120 },
        { field: "disabled", headerName: "Disabled", flex: 0.8, cellRenderer: DisabledRenderer, minWidth: 120 },
        { headerName: "Actions", flex: 1, cellRenderer: ActionsRenderer, minWidth: 80 },
    ], []);

    let handler = useApiHandlerWithAuth(GetLightWeightVendors);

    let ref = useRef<AgGridReact>(null);

    let dataSource = useMemo<IDatasource>(() => {
        return {
            getRows: (params: IGetRowsParams) => {
                void (async () => {
                    try {
                        let result = await handler({
                            offset: params.startRow,
                            count: params.endRow - params.startRow
                        });
                        let lastIndex = result.hasMore ? undefined : params.startRow + result.results.length;
                        params.successCallback(result.results, lastIndex);
                    } catch (error) {
                        params.failCallback();
                    }
                })();
            }
        };
    }, [handler]);

    return (
        <div className="flex-fill d-flex flex-column">
            <div className="d-flex mb-3">
                <button className="btn btn-outline-primary" onClick={() => {
                    ref.current?.api.refreshInfiniteCache();
                }}>Refresh</button>
            </div>
            <DataGrid columnDefs={columns}
                gridRef={ref}
                gridOptions={{ suppressCellFocus: true }}
                suppressColumnVirtualisation
                getRowId={x => x.data.lightWeightVendorId}
                rowModelType="infinite"
                datasource={dataSource}
                rowHeight={50}
                containerClassName="flex-fill" />
        </div>
    )
}

function TextRenderer({ value }: { value?: string }) {
    return value ? value : "-";
}

function EmailRenderer({ value }: { value?: string }) {
    return value
        ? <a className="text-dark" href={`mailto:${value}`}>{value}</a>
        : <>-</>
}

function PhoneNumberRenderer({ value }: { value?: string }) {
    return value
        ? <a className="text-dark" href={`tel:${value}`}>{value}</a>
        : <>-</>
}

function DisabledRenderer({ value }: { value?: boolean }) {
    return value
        ? "Disabled"
        : "Active"
}

function ActionsRenderer({ data }: { data?: LightWeightVendor }) {
    return (
        <div>
            <button className="btn btn-sm btn-outline-primary">Edit</button>
        </div>
    )
}
