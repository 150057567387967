import "./financing.scss";

import { TbPointFilled } from "react-icons/tb";
import { Assets } from "../../../assets/references";
import { BannerFooter } from "../../../common/utilities/banner-footer";
import { Footer } from "../../../common/utilities/footer";

export const FinancingInfo = () => {
  return (
    <main className="financing">
      <section className="d-flex justify-content-center my-5">
        <div className="d-flex flex-column align-items-center justify-content-center" style={{ maxWidth: 800 }}>
          <div style={{ aspectRatio: "143 / 57", height: "60px" }}>
            <img className="img-fluid" src={Assets.AffirmLogoUrl.toString()} alt="Affirm Logo" />
          </div>
          <h1 id="how-does-affirm-work" className="text-center my-3">How does Affirm work?</h1>
          <p>
            We know that planning an awesome party can be expensive,
            and that&apos;s where we can help by offering you an option to party now
            and pay later! Partyeezy has partnered with Affirm to give you a
            simple way to make your purchase with no hidden fees. You&apos;ll be
            able to party now and pay later with one of our party payment plan
            options starting at 0% APR. Pay over time without
            worrying about building interest. Checking your eligibility will
            not affect your credit score.
          </p>
          <p>
            <strong>Easy monthly payments:</strong> Provide some basic
            information and get a real-time credit decision to split your
            purchase into monthly payments. Rates are 0-36% APR
            with loans of 3, 6, or 12 month terms.
          </p>
          <ul>
            <TbPointFilled /> Example: A $1,000 purchase might cost $83.33/mo over 12 months
            at 0% APR (OAC). Down payment may be required.
          </ul>
          <p>
            <strong>Flexible repayment:</strong> Simply pay your monthly bill
            using a debit card, bank transfer at <a href="https://www.affirm.com/pay">affirm.com/pay</a>.
          </p>
          <p className="text-muted mt-3">
            Disclosure: Your rate will be 0% APR or 10-36%
            APR based on credit, and is subject to an eligibility check.
            Payment options through Affirm are provided by these lending
            partners: <a href="https://www.affirm.com/lenders">affirm.com/lenders</a>. Options depend on your purchase
            amount, and a down payment may be required.
          </p>
        </div>
      </section>

      <BannerFooter className="col-12 p-0" />

      <Footer />
    </main>
  );
};
