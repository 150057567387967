export const Regex = {
    // Taken from HTML spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
    email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    // Supports
    // +18582257388
    // 8582257388
    // +1 858 225 7388
    // 858 225 7388
    // (858) 225-7388
    phoneNumber: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    zip: /^[0-9]{5}$/,
    zipFull: /^[0-9]{5}(?:-[0-9]{4})?$/,
    positiveNonDecimalNumber: /^\d+$/,
    promotionCode: /^\w+$/
};
