import { useMemo } from "react";
import { BiError } from "react-icons/bi";
import { GetVendorSurveysQuery } from "../../../common/api/handlers/admin/surveys/get-vendor-surveys";
import { useQueryWithAuth } from "../../../common/api/use-query-with-auth";
import { FormatDateWithAgo } from "../../../common/utilities/formatters/format-date";
import { VendorSurveyStrings } from "./vendor-surveys.strings";

// TODO: Add pagination
// TODO: Sticky header
export const VendorSurveys = () => {

    let { isLoading, isError, data } = useQueryWithAuth(GetVendorSurveysQuery, {}, []);

    let vendorSurveys = useMemo(() => data ?? [], [data]);

    return (
        <>
            <header className="d-flex align-items-center mb-3">
                <h1 className="mb-0">{VendorSurveyStrings.title}</h1>
                {!isLoading && (
                    <span className="ms-3 mt-2 fs-6 fw-bold fst-italic">
                        {vendorSurveys.length}{" "}
                        {vendorSurveys.length > 1 ? VendorSurveyStrings.submissions : VendorSurveyStrings.submission}
                    </span>
                )}
            </header>
            <section className="table-responsive">
                <table className="table table-hover table-striped">
                    {/* TABLE HEADER */}
                    <thead>
                        <tr className="table-info">
                            <th className="w-15">{VendorSurveyStrings.fullName}</th>
                            <th className="w-15">{VendorSurveyStrings.businessName}</th>
                            <th className="w-10">{VendorSurveyStrings.contactInfo}</th>
                            <th className="w-10">{VendorSurveyStrings.zipCode}</th>
                            <th className="w-20">{VendorSurveyStrings.providedServices}</th>
                            <th className="w-20">{VendorSurveyStrings.businessGoals}</th>
                            <th className="w-10">{VendorSurveyStrings.submittedDate}</th>
                        </tr>
                    </thead>
                    {/* LOADING STATE */}
                    {isLoading && (
                        <tbody className="table-group-divider">
                            {[...Array(5).keys()].map((index) => (
                                <tr className="placeholder-glow" key={index}>
                                    <td>
                                        <span className="placeholder w-40"></span>
                                        <span className="placeholder ms-2 w-40"></span>
                                    </td>
                                    <td>
                                        <span className="placeholder w-80"></span>
                                    </td>
                                    <td>
                                        <span className="placeholder w-40"></span>
                                        <br />
                                        <span className="placeholder mt-2 w-40"></span>
                                    </td>
                                    <td>
                                        <span className="placeholder w-80"></span>
                                    </td>
                                    <td>
                                        <span className="placeholder w-80"></span>
                                        <span className="placeholder w-80"></span>
                                        <span className="placeholder w-80"></span>
                                        <span className="placeholder w-40"></span>
                                    </td>
                                    <td>
                                        <span className="placeholder w-80"></span>
                                        <span className="placeholder w-80"></span>
                                        <span className="placeholder w-80"></span>
                                        <span className="placeholder w-40"></span>
                                    </td>
                                    <td>
                                        <span className="placeholder w-80"></span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    )}
                    {/* ERROR STATE */}
                    {isError && (
                        <tbody className="table-group-divider">
                            <tr>
                                <td colSpan={7}>
                                    <div className="d-flex flex-column align-items-center p-5 text-danger" role="alert">
                                        <BiError className="me-2 fs-1" />
                                        <span className="fs-5 fw-bold">{VendorSurveyStrings.error}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    )}
                    {/* NO DATA STATE */}
                    {!isLoading && !isError && vendorSurveys?.length == 0 && (
                        <tbody className="table-group-divider">
                            <tr>
                                <td colSpan={7}>
                                    <div className="d-flex flex-column align-items-center p-5" role="alert">
                                        <span className="fs-5 fw-bold">{VendorSurveyStrings.noData}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    )}
                    {/* DATA STATE */}
                    {!isLoading && !isError && vendorSurveys?.length > 0 && (
                        <tbody className="table-group-divider">
                            {vendorSurveys.map((vendorSurvey) => (
                                <tr key={vendorSurvey.id}>
                                    <td>{vendorSurvey.fullName}</td>
                                    <td>{vendorSurvey.businessName}</td>
                                    <td>
                                        <a href={`mailto:${vendorSurvey.emailAddress}`}>{vendorSurvey.emailAddress}</a>
                                        <br />
                                        <a href={`tel:${vendorSurvey.phoneNumber}`}>{vendorSurvey.phoneNumber}</a>
                                    </td>
                                    <td>{vendorSurvey.zipCode}</td>
                                    <td>
                                        <span>{vendorSurvey.providedServices}</span>
                                        <br />
                                        {vendorSurvey.otherProvidedService?.length > 0 && (
                                            <span>
                                                <b>{VendorSurveyStrings.other}:</b> {vendorSurvey.otherProvidedService}
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        <span>{vendorSurvey.businessGoals}</span>
                                        <br />
                                        {vendorSurvey.otherBusinessGoal?.length > 0 && (
                                            <span>
                                                <b>{VendorSurveyStrings.other}:</b> {vendorSurvey.otherBusinessGoal}
                                            </span>
                                        )}
                                    </td>
                                    <td><FormatDateWithAgo dateTime={vendorSurvey.timestamp} /></td>
                                </tr>
                            ))}
                        </tbody>
                    )}
                </table>
            </section>
        </>
    );
};
