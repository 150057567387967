import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type GetProductsInput = {
    productId: string;
};

export const GetOneProductCommand: ApiHandlerWithAuth<GetProductsInput, Product> = {
    handle: async ({ productId }, authHeader) => {
        let response = await fetch(`/api/v1/admin/products/${productId}`, {
            method: "GET",
            headers: getDefaultHeaders(authHeader)
        });

        if (!response.ok) {
            throw response;
        }

        return (await response.json()) as Product;
    }
};

export type Product = {
    productId: string;
    name: string;
    productDescription: string;
    published: boolean;
    capacity: number;
    categoryId: string;
    categoryName: string;
    primaryImageLink: string;
    vendorDescription: string;
    minimumPerOrder: number;
    hasPricingTiers: boolean;
};
