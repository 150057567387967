import { HttpMethodEnum } from "../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../common/utilities/get-auth-request-header";

export type AcceptInviteRequest = {
    emailAddress: string;
    token: string;
    notAfter: number;
    notBefore: number;
    password: string;
};

export const acceptInvite = async (request: AcceptInviteRequest, signal?: AbortSignal): Promise<void> => {
    const url = "/api/v1/login/accept-invite";
    let response: Response = await fetch(`${url}`, {
        signal,
        method: HttpMethodEnum.POST,
        body: JSON.stringify(request),
        headers: getAuthRequestHeaders()
    });

    if (!response?.ok) {
        throw response;
    }
};
