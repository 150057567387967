import { Nav, Navbar } from "react-bootstrap";
import { NavLink, RouteObject, useOutlet } from "react-router-dom";
import { Conversations } from "./conversations";
import { MessagesByConversation } from "./messages-by-conversation";

export const adminMessagesRoutes: RouteObject[] = [
    {
        path: "/admin/messages",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Conversations />
            },
            {
                path: ":conversation",
                element: <MessagesByConversation />
            }
        ]
    }
];

function Layout() {
    const outlet = useOutlet();
    return (
        <div className="d-flex flex-column products h-100">
            <h2 className="ms-2">Messages</h2>
            <OrderNavbar />
            <div className="m-2 flex-fill d-flex flex-column">{outlet}</div>
        </div>
    );
}

function OrderNavbar() {
    return (
        <Navbar expand="sm" className="border-bottom mb-2">
            <Navbar.Toggle aria-controls="admin-products-navbar" />
            <Navbar.Collapse id="admin-products-navbar">
                <Nav className="me-auto">
                    <NavLink to="/admin/messages" className="nav-link">
                        List
                    </NavLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
