import "./vendor-layout.scss";
import clsx from "clsx";
import React from "react";
import { useOutlet } from "react-router-dom";
import { RequireVendor } from "../require-auth/require-auth";

type VendorLayoutProps = {
    className?: string;
};

export const VendorLayout = ({ className }: VendorLayoutProps) => {
    const outlet = useOutlet();
    const classes = clsx("container", className);

    return (
        <React.Fragment>
            <RequireVendor>
                <main className={classes}>{outlet}</main>
            </RequireVendor>
        </React.Fragment>
    );
};
