import "./product-images.scss";

import { useCallback, useEffect, useReducer, useState } from "react";
import { DeleteProductImageCommand } from "../../../../common/api/handlers/admin/product-images/delete-product-image";
import { GetProductImages, ProductImage } from "../../../../common/api/handlers/admin/product-images/get-product-images";
import { SetPrimaryProductImageCommand } from "../../../../common/api/handlers/admin/product-images/set-primary-product-image";
import { useApiHandlerWithAuth } from "../../../../common/api/use-api-handler-with-auth";
import { AddProductImage } from "./product-add-image";

type ProductImagesProps = {
    productId: string;
    productImageId?: string;
};

export const ProductImages = ({ productId, productImageId }: ProductImagesProps) => {

    let [state, dispatch] = useReducer(reducer, getDefaultState());

    let getProductImagesHandler = useApiHandlerWithAuth(GetProductImages);
    let deleteHandler = useApiHandlerWithAuth(DeleteProductImageCommand);
    let setPrimaryHandler = useApiHandlerWithAuth(SetPrimaryProductImageCommand);

    let refresh = useCallback(async () => {
        dispatch({ type: "updating" });
        let response = await getProductImagesHandler({ productId });
        dispatch({ type: "updated", images: response?.results ?? [] });
    }, [getProductImagesHandler, productId]);

    let deleteImage = async (productImageId: string) => {
        dispatch({ type: "updating" });
        await deleteHandler({ productId, productImageId });
        await refresh();
    };

    let setPrimaryImage = async (productImageId: string) => {
        dispatch({ type: "updating" });
        await setPrimaryHandler({ productId, productImageId });
        await refresh();
    };

    useEffect(() => {
        void refresh();
    }, [productImageId, refresh]);

    return (
        <section className="row">
            <div className="mb-3">
                Product images are used to aid vendors in correctly categorizing their inventory. Multiple images can be uploaded, but only one is the <i>Primary</i> image for a given product.
            </div>

            <AddProductImage productId={productId} onUploaded={() => refresh()} />

            <div className="my-3">
                {state.images.map(image => (
                    <ProductImageCard
                        {...image}
                        key={image.productImageId}
                        disabled={state.status != "idle"}
                        onDelete={deleteImage}
                        onSetPrimary={setPrimaryImage}
                    />
                ))}
                {!state.images.length && (
                    <div className="text-center">No images exist yet.</div>
                )}

            </div>

            {/* Just to add some spacing on the bottom */}
            <div className="py-3"></div>

        </section>
    );
};

type ProductImageProps = {
    productImageId: string;
    imageLink: string;
    isPrimary: boolean;
    onDelete: (productImageId: string) => void;
    onSetPrimary: (productImageId: string) => void;
    disabled: boolean;
};

const ProductImageCard = (props: ProductImageProps) => {

    return (
        <section className="admin-product-image-card mt-2">
            <div className="card">
                <div className="product-image-container bg-body-tertiary border rounded d-flex justify-content-center align-items-center m-3">
                    <img src={props.imageLink}
                        className="product-image img-fluid bg-white rounded"
                        alt="Product Image" />
                </div>

                <div className="d-flex flex-column justify-content-center m-3 flex-fill">
                    <div className="row">
                        <div className="col-lg-6">
                            <button
                                disabled={props.disabled || props.isPrimary}
                                onClick={() => props.onSetPrimary(props.productImageId)}
                                className="btn btn-outline-primary w-100">
                                Set Primary
                            </button>
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-2">
                            <button
                                disabled={props.disabled}
                                onClick={() => props.onDelete(props.productImageId)}
                                className="btn btn-outline-danger w-100">
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

type State = {
    status: "is-loading" | "idle";
    images: Image[];
}

type Image = {
    productImageId: string;
    imageLink: string;
    isPrimary: boolean;
}

type Action =
    | { type: "updating" }
    | { type: "updated", images: Image[]; };

function getDefaultState(): State {
    return {
        images: [],
        status: "idle"
    };
}

function reducer(state: State, action: Action): State {
    if (action.type == "updating") {
        return {
            ...state,
            status: "is-loading"
        }
    }

    if (action.type == "updated") {
        return {
            ...state,
            images: [...action.images],
            status: "idle"
        }
    }

    return {
        ...state
    }
}
