import { VendorFormProps } from "./vendor.constants";

const { providedServicesProp, businessGoalsProp } = VendorFormProps;

export const VendorFormText = {
  caption: "Partner with us!",
  subCaption: "All fields/questions are required.",
  questions: {
    intro: "",
    businessInfo: "First, let us know how to contact you.",
    [providedServicesProp]:
      "Which services do you provide? (Check all that apply)",
  },
  firstName: "First Name",
  lastName: "Last Name",
  businessName: "Business Name",
  fullName: "Name",
  emailAddress: "Email Address",
  phoneNumber: "Phone Number",
  zipCode: "Service Area Zip Code",
  rentals: "Rentals",
  rentalsList: {
    tables: "Tables",
    chairs: "Chairs",
    tents: "Tents",
    bouncers: "Bouncers",
    generators: "Generators",
  },
  services: "Services",
  servicesList: {
    tacoCatering: "Taco Catering",
    desertBars: "Dessert Bars",
    balloonArches: "Balloon Arches",
    photographyVideography: "Photography/Videography",
    facePainting: "Face Painting",
    balloonAnimals: "Balloon Animals",
    djLiveMusic: "DJ/Live Music",
    magician: "Magician",
    photoBooths: "Photo Booths",
    partyCleanUpServices: "Party Clean-up Services",
  },
  otherProvidedService: "Other Provided Services",
  validationMessages: {
    emailAddress: {
      pattern: "Email Address is invalid",
    },
    phoneNumber: {
      pattern: "Phone Number is invalid",
    },
    zipCode: {
      pattern: "Zip Code is invalid",
    },
  },
  back: "Back",
  next: "Next",
  submit: "Submit",
  submitSuccess:
    "Thank you for your interest in partnering with us! We will be in touch soon.",
};
