import "./products.scss";
import { NavLink, RouteObject, useLocation, useOutlet } from "react-router-dom";
import { ProductsListPage } from "./products-list";
import { Nav, Navbar } from "react-bootstrap";
import { AdminProductsCreatePage } from "./products-create";
import { AdminProductsEditPage } from "./products-edit";

export const productPaths = {
    list: "/admin/products",
    create: "/admin/products/new",
    edit: "/admin/products/:productId/edit"
};

export const productRoutes: RouteObject[] = [
    {
        Component: ProductsLayout,
        children: [
            {
                path: productPaths.list,
                Component: ProductsListPage
            },
            {
                path: productPaths.create,
                Component: AdminProductsCreatePage
            },
            {
                path: productPaths.edit,
                Component: AdminProductsEditPage
            }
        ]
    }
];

function ProductsLayout() {
    const outlet = useOutlet();
    return (
        <div className="d-flex flex-column products h-100">
            <header className="d-flex align-items-center my-3">
                <h2 className="ms-2 me-auto mb-0">Products</h2>
                <ProductsHeaderActions />
            </header>
            <div className="m-2 flex-fill">{outlet}</div>
        </div>
    );
}

function ProductsHeaderActions() {
    let location = useLocation();

    return (
        <>
            {location?.pathname !== productPaths.list && (
                <NavLink to={productPaths.list} className="btn btn-primary mx-2">
                    View Products
                </NavLink>
            )}
            {location?.pathname !== productPaths.create && (
                <NavLink to={productPaths.create} className="btn btn-primary mx-2">
                    Create New Product
                </NavLink>
            )}
        </>
    )
}

function ProductsNavbar() {
    return (
        <Navbar expand="sm" className="border-bottom mb-2">
            <Navbar.Toggle aria-controls="admin-products-navbar" />
            <Navbar.Collapse id="admin-products-navbar">
                <Nav className="me-auto">
                    <NavLink to={productPaths.list} className="nav-link">
                        List
                    </NavLink>
                    <NavLink to={productPaths.create} className="nav-link">
                        Create New Product
                    </NavLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
