import clsx from "clsx";

export type FooterProps = {
    className?: string;
}

export function Footer({ className }: FooterProps) {
    return (
        <footer
            className={clsx("d-flex flex-column justify-content-center align-items-center p-4", className)}
            title="Made with Love in Texas, USA">
            <a href="mailto:hello@partyeezy.com" className="link-dark">
                hello@partyeezy.com
            </a>
            <a href="tel:6263449142" className="link-dark">
                (626) 344-9142
            </a>
            <p className="d-none d-sm-block">
                Made with ♡ | Partyeezy, Inc | Copyright © {new Date().getFullYear()}
            </p>
            <p className="d-flex flex-column align-content-center text-center d-sm-none">
                <span>Partyeezy, Inc</span>
                <span>Copyright © {new Date().getFullYear()}</span>
                <span>Made with ♡</span>
            </p>
        </footer>
    )
}
