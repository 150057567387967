import { useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { BsChevronRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { GetProducts } from "../../../common/api/handlers/admin/products/get-products";
import { useQueryWithAuth } from "../../../common/api/use-query-with-auth";
import { LabelWithError } from "../../../common/forms/label-with-error";
import { LoadingIcon } from "../../../common/utilities/loading-icon";

type ProductFilter = {
    search?: string;
};

export type Product = {
    productId: string;
    name: string;
    productDescription: string;
    vendorDescription: string;
    minimumPerOrder: number;
    published?: boolean;
    capacity: number;
    categoryId?: string;
    categoryName?: string;
    primaryImageLink?: string;
};

type SortType = "name" | "capacity";

function sortFunction(input: Product[], by: SortType): Product[] {
    switch (by) {
        case "name":
            return input.sort((x, y) => x.name < y.name ? -1 : 1);
        default:
            return input;
    }
}

export function ProductsListPage() {
    let [filter, setFilter] = useState<ProductFilter>({});
    let { data, isLoading } = useQueryWithAuth(GetProducts, {}, [filter.search]);

    let [sort, setSort] = useState<SortType>("name");

    let filteredData = useMemo(
        () => sortFunction((data?.results ?? []).filter((x) => !filter.search || x.name.toLocaleLowerCase().includes(filter.search.toLocaleLowerCase())), sort),
        [data?.results, filter.search, sort]
    );

    return (
        <div className="d-flex flex-column h-100">
            <Filter filter={filter} onChange={(x) => setFilter(x)} />
            {isLoading && (
                <div className="d-flex justify-content-center align-items-center flex-fill">
                    <LoadingIcon />
                </div>
            )}
            {!isLoading && filteredData.map((x) => <ListRow key={x.productId} product={x} />)}
            {!isLoading && filteredData.length == 0 && <div className="text-center my-3">No products found.</div>}
        </div>
    );
}

function Filter(props: { filter: ProductFilter; onChange: (filter: ProductFilter) => void }) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm<ProductFilter>({
        mode: "onChange"
    });

    const onSubmit: SubmitHandler<ProductFilter> = (data) => {
        props.onChange(data);
    };

    watch((data) => {
        props.onChange(data);
    }, {});

    return (
        <form className="mb-2 bg-white p-4 rounded border" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-floating">
                <input type="text" className="form-control" placeholder="Search Products" {...register("search")} />
                <LabelWithError errors={errors} name="search">
                    Search
                </LabelWithError>
            </div>
        </form>
    );
}

function ListRow({ product }: { product: Product }) {
    return (
        <Link
            to={`${product.productId}/edit`}
            className="text-decoration-none text-dark list-item d-flex p-3 mb-2 border rounded"
            role="button"
        >
            <div className="product-thumbnail d-flex justify-content-center align-items-center rounded bg-white border">
                {product.primaryImageLink && (
                    <img
                        src={product.primaryImageLink}
                        alt="Product image"
                        className="img-fluid rounded"
                    />
                )}
            </div>

            <div className="ms-2 d-flex flex-column justify-content-center ms-3">
                <h3 className="text-capitalize">{product.name}</h3>
                <p className="text-muted">{product.productDescription ?? <i>No description.</i>}</p>
            </div>

            <div className="ms-auto d-flex align-items-center">
                <BsChevronRight />
            </div>
        </Link>
    );
}
