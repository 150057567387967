import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type CreateVendorInviteInput = {
    name: string;
    contactEmailAddress: string;
    firstLoginEmail: string;
}

export const CreateVendorInviteCommand: ApiHandlerWithAuth<CreateVendorInviteInput, CreateVendorInviteResult> = {
    handle: async (input, authHeader, signal) => {
        let response = await fetch("/api/v1/admin/vendor-invites", {
            method: "POST",
            headers: getDefaultHeaders(authHeader),
            body: JSON.stringify(input),
            signal
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as CreateVendorInviteResult;
    }
}

export type CreateVendorInviteResult = {
    vendorInviteId: string;
    loginId: string;
}
