import "./data-grid.scss";

import { ICellRendererParams } from "ag-grid-community";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import clsx from "clsx";
import { LoadingIcon } from "./loading-icon";

export type DataGridProps<TData> =
    { containerClassName?: string, gridRef?: React.RefObject<AgGridReact> }
    & (AgGridReactProps<TData> | AgGridReactProps<TData>);

export function DataGrid<TData>({ containerClassName, gridRef, ...props }: DataGridProps<TData>) {

    props.gridOptions = {
        ...props.gridOptions,
        loadingOverlayComponent: LoadingOverlay,
        noRowsOverlayComponent: NoItemsOverlay
    }

    return (
        <div className={clsx("data-grid ag-theme-quartz rounded overflow-hidden", containerClassName)}>
            <AgGridReact {...props} ref={gridRef} />
        </div>
    )
}

function LoadingOverlay(props: ICellRendererParams & { loadingMessage: string }) {
    return (
        <div className="d-flex align-items-center justify-content-center">
            <LoadingIcon />
            <div className="text-muted">{props.loadingMessage}</div>
        </div>
    );
}


function NoItemsOverlay(props: ICellRendererParams & { noRowsMessageFunc: () => string }) {
    return (
        <div className="text-muted">
            No results
        </div>
    );
}
