import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type UpdatePromotionCodeInput = {
    promotionCodeId: string;
    code: string;
    notes?: string;
    discount: number;
    isDisabled: boolean;
};

export const UpdatePromotionCode: ApiHandlerWithAuth<UpdatePromotionCodeInput, void> = {
    handle: async ({ promotionCodeId, ...input }, authHeader) => {
        let response = await fetch(`/api/v1/admin/promotions/${promotionCodeId}`, {
            method: "PUT",
            headers: getDefaultHeaders(authHeader),
            body: JSON.stringify(input)
        });

        if (!response.ok) {
            throw response;
        }
    }
}
