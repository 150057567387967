import { Nav, Navbar } from "react-bootstrap";
import { NavLink, RouteObject, useOutlet } from "react-router-dom";
import { LightWeightVendorList } from "./light-weight-vendor-list/light-weight-vendor-list";
import { CreateLightWeightVendorButton } from "./shared/create-light-weight-vendor-button";

export const adminLightWeightVendorsRoutes: RouteObject[] = [
    {
        path: "/admin/light-weight-vendors",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <LightWeightVendorList />
            }
        ]
    }
];

function Layout() {
    const outlet = useOutlet();
    return (
        <div className="d-flex flex-column products h-100">
            <h2 className="ms-2">Vendors (Light-Weight)</h2>
            <LightWeightVendorNavbar />
            <div className="m-2 flex-fill d-flex flex-column">{outlet}</div>
        </div>
    );
}

function LightWeightVendorNavbar() {
    return (
        <Navbar expand="sm" className="border-bottom mb-2">
            <Navbar.Toggle aria-controls="admin-products-navbar" />
            <Navbar.Collapse id="admin-products-navbar">
                <Nav className="d-flex flex-fill align-items-end">
                    <NavLink to="/admin/light-weight-vendors" className="nav-link">
                        List
                    </NavLink>
                    <CreateLightWeightVendorButton className="ms-auto" />
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
