import { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { OverlayTriggerRenderProps } from "react-bootstrap/esm/OverlayTrigger";
import * as uuid from "uuid";

export type SimpleTooltipProps = {
    title?: string | null;
    children: React.ReactElement | ((props: OverlayTriggerRenderProps) => React.ReactNode);
}

export function SimpleTooltip({ title, children }: SimpleTooltipProps) {
    let id = useMemo(() => uuid.v4(), []);
    return title
        ? (
            <OverlayTrigger overlay={props => <Tooltip id={id} {...props}>{title}</Tooltip>}>
                {children}
            </OverlayTrigger>
        )
        : <>{children}</>
}
