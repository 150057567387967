import { useState } from "react";
import { Modal } from "react-bootstrap";
import { GetProductItem } from "../../../../../services/public/ordering/get-products";
import { useOrderContext } from "../../provider/use-order-context";
import { LineItemsSummary } from "./line-items-summary";
import { ProductsList } from "./products-list";

type AddLineItemsPropType = {
    availableProducts: GetProductItem[];
}

export const AddLineItems = (props: AddLineItemsPropType) => {
    const { state: { isLoading } } = useOrderContext();

    const [showAddProductModal, setShowAddProductModal] = useState(false);

    const handleAddItemsClick = () => {
        setShowAddProductModal(true);
    };

    const handleClose = () => {
        setShowAddProductModal(false);
    }

    return (
        <section className="d-flex align-items-center justify-content-center pb-3 mx-n3 border-bottom">
            <button className="btn btn-outline-primary" onClick={handleAddItemsClick} disabled={isLoading}>Looking for something else?</button>
            <Modal show={showAddProductModal} onHide={handleClose} size="lg" scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>Add More Items</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LineItemsSummary />
                    <ProductsList {...props} />
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={handleClose}>Done</button>
                </Modal.Footer>
            </Modal>
        </section>
    );
};
