import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type GetProductImagesInput = {
    productId: string;
};

export const GetProductImages: ApiHandlerWithAuth<GetProductImagesInput, GetProductImagesResult> = {
    handle: async ({ productId }, authHeader) => {
        let response = await fetch(`/api/v1/admin/products/${productId}/images`, {
            method: "GET",
            headers: getDefaultHeaders(authHeader)
        });

        if (!response.ok) {
            throw response;
        }

        return (await response.json()) as GetProductImagesResult;
    }
};

export type GetProductImagesResult = {
    results: ProductImage[];
    hasMore: boolean;
};

export type ProductImage = {
    productImageId: string;
    imageLink: string;
    isPrimary: boolean;
};
