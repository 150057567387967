import { ColDef, IDatasource, IGetRowsParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { GetOrders, GetOrdersInput, Order, OrderState } from "../../../../common/api/handlers/admin/orders/get-orders";
import { useApiHandlerWithAuth } from "../../../../common/api/use-api-handler-with-auth";
import { DataGrid } from "../../../../common/utilities/data-grid";
import { FormatDateWithAgo } from "../../../../common/utilities/formatters/format-date";
import { FormatOrderState } from "../shared/format-order-state";

export type OrderGridProps = Omit<GetOrdersInput, "offset" | "count">;

export function OrderGrid({ ...options }: OrderGridProps) {

    let columns = useMemo<ColDef<Order>[]>(() => [
        { field: "humanReadableOrderId", headerName: "Order ID", pinned: true, initialWidth: 150, cellRenderer: OrderIdRenderer },
        { field: "state", headerName: "Status", cellRenderer: OrderStatusRenderer },
        { field: "paymentIntent.createdOn", headerName: "Ordered On", cellRenderer: DateTimeRenderer },
        { field: "eventSchedule.startOn", headerName: "Event Start", cellRenderer: DateTimeRenderer },
        { field: "contactDetails.name", headerName: "Contact" },
        { field: "contactDetails.emailAddress", headerName: "Contact Email" },
        { field: "contactDetails.phoneNumber", headerName: "Contact Phone" },
        { field: "lineItems", headerName: "Line Items Assigned", pinned: true, cellRenderer: LineItemSummaryRenderer },
    ], []);

    let handler = useApiHandlerWithAuth(GetOrders);

    let ref = useRef<AgGridReact>(null);

    let dataSource = useMemo<IDatasource>(() => {
        return {
            getRows: (params: IGetRowsParams) => {
                void (async () => {
                    try {
                        let result = await handler({
                            search: options.search,
                            hasOrderState: options.hasOrderState,
                            hasLineItemState: options.hasLineItemState,
                            orderPlacedAfter: options.orderPlacedAfter,
                            orderPlacedBefore: options.orderPlacedBefore,
                            eventAfter: options.eventAfter,
                            eventBefore: options.eventBefore,
                            offset: params.startRow,
                            count: params.endRow - params.startRow
                        });
                        let lastIndex = result.hasMore ? undefined : params.startRow + result.results.length;
                        params.successCallback(result.results, lastIndex);
                    } catch (error) {
                        params.failCallback();
                    }
                })();
            }
        };
    }, [handler, options.eventAfter, options.eventBefore, options.hasLineItemState, options.hasOrderState, options.orderPlacedAfter, options.orderPlacedBefore, options.search])

    return (
        <div className="flex-fill d-flex flex-column">
            <DataGrid columnDefs={columns}
                gridRef={ref}
                gridOptions={{ suppressCellFocus: true }}
                suppressColumnVirtualisation
                getRowId={x => x.data.orderId}
                rowModelType="infinite"
                datasource={dataSource}
                containerClassName="flex-fill" />
        </div>
    )
}

function OrderStatusRenderer({ value }: { value?: OrderState }) {
    return <FormatOrderState state={value} />
}

function DateTimeRenderer({ value }: { value?: string }) {
    return (
        <FormatDateWithAgo dateTime={value} />
    )
}

function OrderIdRenderer({ data }: { data?: Order }) {
    return (
        <Link to={`./${data?.orderId}`} className="text-dark">
            #{data?.humanReadableOrderId ?? "-"}
        </Link>
    )
}

function LineItemSummaryRenderer({ data }: { data?: Order }) {

    let {
        fulfilled = 0,
        paymentConfirmed = 0,
        pending = 0,
        total = 0,
        unknown = 0,
        vendorAssigned = 0,
        vendorConfirmed = 0
    } = data?.lineItems ?? {};

    return (
        <>
            {vendorAssigned}/{total} ({pending} pending)
        </>
    )
}
