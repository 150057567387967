export const VendorSurveyStrings = {
    title: "Vendor Surveys",
    fullName: "Full Name",
    businessName: "Business Name",
    contactInfo: "Contact Info",
    zipCode: "Zip Code",
    providedServices: "Provided Services",
    businessGoals: "Business Goals",
    submittedDate: "Submitted Date",
    noData: "There are no vendor surveys to display.",
    error: "There was an error loading the vendor surveys.",
    submissions: "Submissions",
    submission: "Submission",
    other: "Other"
};
