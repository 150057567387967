import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type IsVendorNameAvailableInput = {
    vendorName: string;
}

export const IsVendorNameAvailableQuery: ApiHandlerWithAuth<IsVendorNameAvailableInput, IsVendorNameAvailableResult> = {
    handle: async ({ vendorName }, authHeader, signal) => {
        let response = await fetch(`/api/v1/admin/vendors/name-available/${vendorName}`, {
            method: "GET",
            headers: getDefaultHeaders(authHeader),
            signal
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as IsVendorNameAvailableResult;
    }
}

export type IsVendorNameAvailableResult = {
    available: boolean;
}
