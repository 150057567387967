import { useMemo } from "react";
import { OptionProps, components } from "react-select";
import { GetLightWeightVendors, LightWeightVendor } from "../../../../common/api/handlers/admin/light-weight-vendors/get-light-weight-vendors";
import { useQueryWithAuth } from "../../../../common/api/use-query-with-auth";
import { BootstrapSyncSelect } from "../../../../common/components/select/bootstrap-sync-select";

export type LightWeightVendorSelectorProps = {
    value?: string;
    onChange: (value?: string) => void;
}

export function LightWeightVendorSelector({ value, onChange }: LightWeightVendorSelectorProps) {

    let { data, isLoading } = useQueryWithAuth(GetLightWeightVendors, {}, []);

    let options = useMemo(() => {
        return (data?.results ?? []).map(x => ({
            label: x.name,
            value: x.lightWeightVendorId,
            data: x
        }));
    }, [data?.results])

    let selectedOption = useMemo(() => options.find(x => x.value == value), [options, value])

    return (
        <BootstrapSyncSelect
            components={{ Option: RenderListOption }}
            isMulti={false}
            options={options}
            value={selectedOption}
            onChange={x => onChange(x?.data?.lightWeightVendorId)}
            isLoading={isLoading}
        />
    )
}

const RenderListOption = (props: OptionProps<LightWeightVendorOption>) => {
    let { data } = props.data
    return (
        <components.Option {...props}>
            <div key={props.innerProps.key} className="d-flex flex-column">
                <div className="d-flex justify-content-between">
                    <div>
                        {data?.name}
                    </div>
                    <div className="text-truncate">
                        {data?.emailAddress}
                    </div>
                </div>
                <div style={{ fontSize: "0.8rem" }}>
                    {data?.description}
                </div>
            </div>
        </components.Option>
    );
};

type LightWeightVendorOption = {
    label: string;
    value: string;
    data: LightWeightVendor;
}
