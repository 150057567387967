import { useMemo } from "react";
import { BiError } from "react-icons/bi";
import { GetPlannerSurveysQuery } from "../../../common/api/handlers/admin/surveys/get-planner-surveys";
import { useQueryWithAuth } from "../../../common/api/use-query-with-auth";
import { FormatDateWithAgo } from "../../../common/utilities/formatters/format-date";

// TODO: Add pagination
// TODO: Sticky header
export const PlannerSurveys = () => {

    let { isLoading, isError, data } = useQueryWithAuth(GetPlannerSurveysQuery, {}, []);

    let plannerSurveys = useMemo(() => data ?? [], [data]);

    return (
        <div>
            <header className="d-flex align-items-center mb-3">
                <h1 className="mb-0">Planner Surveys</h1>
                {!isLoading && (
                    <span className="ms-3 mt-2 fs-6 fw-bold fst-italic">
                        {plannerSurveys.length} {plannerSurveys.length > 1 ? "submissions" : "submission"}
                    </span>
                )}
            </header>
            <section className="table-responsive">
                <table className="table table-hover table-striped">
                    {/* TABLE HEADER */}
                    <thead>
                        <tr className="table-info">
                            <th className="w-25">Full Name</th>
                            <th className="w-25">Contact Info</th>
                            <th className="w-25">Zip Code</th>
                            <th className="w-25">Submitted Date</th>
                        </tr>
                    </thead>
                    {/* LOADING STATE */}
                    {isLoading && (
                        <tbody className="table-group-divider">
                            {[...Array(5).keys()].map((index) => (
                                <tr className="placeholder-glow" key={index}>
                                    <td>
                                        <span className="placeholder w-40"></span>
                                        <span className="placeholder ms-2 w-40"></span>
                                    </td>
                                    <td>
                                        <span className="placeholder w-80"></span>
                                        <br />
                                        <span className="placeholder w-80"></span>
                                    </td>
                                    <td>
                                        <span className="placeholder w-40"></span>
                                    </td>
                                    <td>
                                        <span className="placeholder w-80"></span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    )}
                    {/* ERROR STATE */}
                    {isError && (
                        <tbody className="table-group-divider">
                            <tr>
                                <td colSpan={4}>
                                    <div className="d-flex flex-column align-items-center p-5 text-danger" role="alert">
                                        <BiError className="me-2 fs-1" />
                                        <span className="fs-5 fw-bold">
                                            There was an error loading the planner surveys.
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    )}
                    {/* NO DATA STATE */}
                    {!isLoading && !isError && plannerSurveys?.length == 0 && (
                        <tbody className="table-group-divider">
                            <tr>
                                <td colSpan={4}>
                                    <div className="d-flex flex-column align-items-center p-5" role="alert">
                                        <span className="fs-5 fw-bold">There are no planner surveys to display.</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    )}
                    {/* DATA STATE */}
                    {!isLoading && !isError && plannerSurveys?.length > 0 && (
                        <tbody className="table-group-divider">
                            {plannerSurveys.map((plannerSurvey) => (
                                <tr key={plannerSurvey.id}>
                                    <td>{plannerSurvey.fullName}</td>
                                    <td>
                                        <a href={`mailto:${plannerSurvey.emailAddress}`}>
                                            {plannerSurvey.emailAddress}
                                        </a>
                                        <br />
                                        <a href={`tel:${plannerSurvey.phoneNumber}`}>{plannerSurvey.phoneNumber}</a>
                                    </td>
                                    <td>{plannerSurvey.zipCode}</td>
                                    <td>
                                        <FormatDateWithAgo dateTime={plannerSurvey.timestamp} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    )}
                </table>
            </section>
        </div>
    );
};
