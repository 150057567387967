import { createContext, useContext } from "react";

export type AuthContextUser = {
    emailAddress?: string;
    givenName?: string;
    familyName?: string;
    accessToken: string;
    tokenType: string;
    isAdmin: boolean;
    isVendor: boolean;
}

export type AuthContextType = {
    login: (username: string, password: string) => Promise<AuthContextUser | null>;
    logout: () => Promise<void>;
    isLoading: boolean;
    user?: AuthContextUser | null;
};

export const AuthContext = createContext<AuthContextType>({
    isLoading: true,
    login: (username: string, password: string) => { return Promise.resolve(null) },
    logout: async () => { }
});

export function useAuth(): AuthContextType {
    let context = useContext<AuthContextType>(AuthContext);
    return context;
}
