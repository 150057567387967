import "./landing.scss";

import { useCallback } from "react";
import { RiEdit2Line, RiHeartLine, RiMoneyDollarBoxLine, RiShakeHandsLine, RiStarFill, RiStarSmileLine } from "react-icons/ri";
import { TbPointFilled } from "react-icons/tb";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { Assets } from "../../assets/references";
import { BannerFooter } from "../../common/utilities/banner-footer";
import { Footer } from "../../common/utilities/footer";
import { plannerPaths } from "../planner/planner.routes";
import { publicPaths } from "../public/public.routes";
import { LandingHeader } from "./landing-header";
import { PartyPlannerForm, PartyPlannerFormType } from "./party-planner-form";

export const Landing = () => {

    const navigate = useNavigate();

    const handlePartyPlannerFormSubmit = useCallback((data: PartyPlannerFormType) => {
        navigate({
            pathname: plannerPaths.packages,
            search: createSearchParams({
                zipCode: data.partyLocation,
                size: data.partySize?.toString(),
                typeId: data.partyTypeId,
            }).toString()
        });
    }, [navigate])

    return (
        <main className="landing-page pez-font">
            <LandingHeader />
            <section className="top-section landing d-flex justify-content-center position-relative">
                <div className="position-absolute top-0 start-0 end-0 bottom-0 background-image">
                </div>
                <div className="position-absolute top-0 start-0 end-0 bottom-0">
                    <div className="d-flex flex-column align-content-center justify-content-center text-center h-100">
                        <div className="main-slogan">
                            Your One Stop Party Shop
                        </div>
                        <div className="main-slogan-caption mb-1">
                            <span className="caption-part">
                                <span className="text">
                                    Tables & Chairs
                                </span>
                            </span>
                            <span className="caption-part">
                                <TbPointFilled className="mx-1 separator" />
                                <span className="text">Bounce Houses</span>
                            </span>
                            <span className="caption-part">
                                <TbPointFilled className="mx-1 separator" />
                                <span className="text">Taco Man</span>
                            </span>
                            <span className="caption-part">
                                <TbPointFilled className="mx-1 separator" />
                                <span className="text">Entertainers</span>
                            </span>
                            <span className="caption-part">
                                <TbPointFilled className="mx-1 separator" />
                                <span className="text">Party Clean Up and More!</span>
                            </span>
                        </div>
                        <div className="d-flex flex-column planner-form">
                            <PartyPlannerForm onSubmit={handlePartyPlannerFormSubmit} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="marketing-section py-5 d-flex align-items-center">
                <div className="container">
                    <div className="d-flex flex-column align-items-center">
                        <RiHeartLine size={30} />
                        <p className="mt-3 text-center">
                            Finally, a simple, easy, and affordable way to book everything you need for your next party - all in 10 minutes.
                        </p>
                    </div>
                </div>
            </section>
            {/* <section id="party-essentials" className="party-essentials-section py-5">
                <PartyEssentials />
            </section> */}
            <section id="how-it-works" data-offset="100" className="how-it-works-section scroll-offset py-5">
                <div className="container">
                    <div className="d-flex justify-content-center how-it-works-header fs-2">
                        Here&apos;s How It Works!
                    </div>
                    <div className="row mt-4 w-100">
                        <div className="col-lg-4">
                            <div className="d-flex justify-content-center mb-4">
                                <RiEdit2Line size={50} />
                            </div>
                            <div className="text-center h5">1. Plan your Party in 10 min.</div>
                            <p className="text-center">
                                Forget working with multiple vendors! Book all the rentals,
                                services and food catering you need for your party with one order.
                            </p>
                            <p className="text-center">
                                Last minute party planning? No problem!
                                <br />
                            </p>
                            <p className="text-center">
                                Party planning has never been easier!
                            </p>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex justify-content-center mb-4">
                                <RiStarSmileLine size={50} />
                            </div>
                            <div className="text-center h5">2. Partyeezy Does the Work</div>
                            <p className="text-center">
                                We secure locally vetted vendors for you. Confirm your party details instantly.
                            </p>
                            <p className="text-center">“100% Satisfaction Guarantee”</p>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex justify-content-center mb-4">
                                <RiMoneyDollarBoxLine size={50} />
                            </div>
                            <div className="text-center h5">3. Confirm & Pay</div>
                            <p className="text-center">
                                If you&apos;re happy with the details, sit back and relax. Need
                                changes or have questions? <Link to={publicPaths.contactUs}>Simply contact us!</Link>
                            </p>
                            <p className="text-center">
                                Party now, pay later! We offer interest free payments to make
                                partying even easier.
                            </p>
                        </div>
                    </div>
                    <div className="text-center mt-3 fw-medium">
                        Got questions? No worries, check out our <Link to={publicPaths.faq}>FAQ</Link>.
                    </div>
                </div>
            </section>
            <section id="party-now-pay-later" className="party-now-section scroll-offset py-5">
                <div className="container d-flex flex-column justify-content-center align-items-center">
                    <div style={{ aspectRatio: "143 / 57", height: "40px" }}>
                        <img className="img-fluid" src={Assets.AffirmLogoUrl.toString()} alt="Affirm Logo" />
                    </div>
                    <div className="vendor-sign-up-text pb-3 fs-2 mt-3">
                        Party Now, Pay Later!
                    </div>
                    <p className="pb-1 text-center">
                        Planning an awesome party can be expensive but that doesn&apos;t mean the party has to stop!  We&apos;re partnering with <Link to={publicPaths.financingInfo}>Affirm</Link> to offer 0% payment options for qualifying customers so you can Partyeezy
                    </p>
                    <Link to={publicPaths.financingInfo} className="btn btn-outline-dark">
                        Learn more about financing
                    </Link>
                </div>
            </section>
            <section id="our-promise" className="landing scroll-offset promise-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="p-5 col-lg-7">
                            <div className="ms-auto" style={{ maxWidth: "800px" }}>
                                <div className="promise-section-header d-flex fs-2">
                                    The Partyeezy Promise
                                </div>
                                <p>
                                    Every party is meant to be memorable, so we pledge to uphold
                                    your complete satisfaction with the party services you arrange
                                    through us. If you have any doubts, our customer service team
                                    is here to assist you without delay. If, despite our best
                                    efforts, your happiness remains unfulfilled, we reiterate our
                                    promise to refund 100% of your order.”
                                </p>
                                <ul className="list-unstyled ms-0 ms-sm-3">
                                    <li className="mb-1">
                                        <RiStarFill className="promise-section-bullet me-2" />
                                        Checked, washed, and sanitized before every rental
                                    </li>
                                    <li className="mb-1">
                                        <RiStarFill className="promise-section-bullet me-2" />
                                        Adherence to safety standards set by the manufacturer for
                                        anchoring
                                    </li>
                                    <li className="mb-1">
                                        <RiStarFill className="promise-section-bullet me-2" />
                                        Thoroughly evaluated to guarantee top-tier quality
                                    </li>
                                    <li className="mb-1">
                                        <RiStarFill className="promise-section-bullet me-2" />
                                        Timely setup and on-time arrival
                                    </li>
                                    <li className="mb-1">
                                        <RiStarFill className="promise-section-bullet me-2" />
                                        Clear and straightforward pricing
                                    </li>
                                    <li className="mb-1">
                                        <RiStarFill className="promise-section-bullet me-2" />
                                        100% satisfaction guaranteed
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="promise-image col-lg-5 ">
                            {/* <img className="promise-image" src="landing-promise-section.jpeg"></img> */}
                        </div>
                    </div>
                </div>
            </section>
            <section id="become-a-partner" className="vendor-section scroll-offset py-5">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-center ">
                        <div>
                            <div className="text-center vendor-section-header pb-3">
                                Become a Partyeezy Partner
                            </div>
                            <div className="d-flex justify-content-center pb-3">
                                <RiShakeHandsLine size={50} />
                            </div>

                            <div className="text-center pb-3">
                                Wanna help us get the party started? We are always looking for top notch vendors to join the team!
                            </div>
                            <div className="d-flex justify-content-center">
                                <Link to={publicPaths.vendor} className="vendor-section-btn btn btn-outline-dark px-3 py-2">
                                    <strong>YES</strong>, SIGN ME UP!
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <BannerFooter className="col-12 p-0" />
            <Footer />
        </main >
    );
};


// To use lazy() for chunking, react only supports default imports. Hate it.
// Alternate solution: https://github.com/facebook/react/issues/14603#issuecomment-726551598
export default Landing;
