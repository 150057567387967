import "./admin-layout.scss";

import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import Gravatar from "react-gravatar";
import { BiHomeAlt2, BiMenu } from "react-icons/bi";
import { BsPeople, BsTelephone } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";
import { IoPricetagsOutline } from "react-icons/io5";
import { MdManageAccounts } from "react-icons/md";
import { RiSurveyLine, RiUser5Line } from "react-icons/ri";
import { Link, NavLink, useLocation, useOutlet } from "react-router-dom";
import { Assets } from "../../../../assets/references";
import { adminPaths } from "../../../../pages/admin/admin.routes";
import { productPaths } from "../../../../pages/admin/products/products";
import { useAuth } from "../../../auth/use-auth";
import { RequireAdmin } from "../require-auth/require-auth";

type AdminLayoutProps = {
    className?: string;
};

export const AdminLayout = ({ className }: AdminLayoutProps) => {
    const outlet = useOutlet();
    const classes = clsx("container p-3 h-100", className);
    return (
        <React.Fragment>
            <RequireAdmin>
                <div className="d-flex admin-layout d-flex flex-column flex-sm-row wh-100">
                    <Sidebar />
                    <div className="scroll-container flex-fill h-100">
                        <main className={classes}>{outlet}</main>
                    </div>
                </div>
            </RequireAdmin>
        </React.Fragment>
    );
};

function Sidebar() {
    const [showMobileSideBar, setShowMobileSideBar] = useState(false);

    const handleClose = () => setShowMobileSideBar(false);
    const handleShow = () => setShowMobileSideBar(true);

    // Close when clicking a link that causes a navigation.
    let { pathname } = useLocation();
    useEffect(() => handleClose(), [pathname]);

    return (
        <>
            {/* Desktop */}
            <div className="sidebar desktop-sidebar flex-column flex-shrink-0 p-3 bg-white d-none d-sm-flex">
                <Link
                    to={adminPaths.home}
                    className="d-flex align-items-center justify-content-center text-decoration-none px-4 pt-3"
                >
                    <img className="logo-img" src={Assets.LogoBlackUrl.toString()} alt="Partyeezy" />
                </Link>
                <SidebarContent />
            </div>

            {/* Mobile */}
            <div className="sidebar mobile-sidebar flex-column flex-shrink-0 bg-white d-flex d-sm-none p-2 mb-2">
                <div className="d-flex justify-content-between">
                    <BiMenu className="menu-toggle" onClick={handleShow} />
                    <Link to={adminPaths.home} className="d-flex align-items-center text-decoration-none">
                        <img className="logo-img" src={Assets.LogoBlackUrl.toString()} alt="Partyeezy" />
                    </Link>
                    <div className="end-padding" />
                </div>
                <Offcanvas show={showMobileSideBar} onHide={handleClose} responsive="sm">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Admin</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="desktop-sidebar d-flex flex-column">
                        <SidebarContent />
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </>
    );
}

function SidebarContent() {
    let { user } = useAuth();
    return (
        <>
            <div className="mt-sm-4"></div>
            <ul className="nav flex-column mb-auto">
                <li className="nav-item">
                    <NavLink to={adminPaths.home} className="nav-link d-flex align-items-center">
                        <BiHomeAlt2 className="me-2" />
                        Home
                    </NavLink>
                    <NavLink
                        to={adminPaths.vendorSurveys}
                        className="nav-link d-flex align-items-center">
                        <RiSurveyLine className="me-2" />
                        Vendor Surveys
                    </NavLink>
                    <NavLink
                        to={adminPaths.plannerSurveys}
                        className="nav-link d-flex align-items-center">
                        <RiUser5Line className="me-2" />
                        Planner Surveys
                    </NavLink>
                    <NavLink to={productPaths.list} className="nav-link d-flex align-items-center">
                        <MdManageAccounts className="me-2" />
                        Products Management
                    </NavLink>
                    <NavLink to="/admin/promotion-codes" className="nav-link d-flex align-items-center">
                        <IoPricetagsOutline className="me-2" />
                        Promotion Codes
                    </NavLink>
                    {/* <NavLink to={adminVendorPaths.list} className="nav-link d-flex align-items-center">
                        <BsPeople className="me-2" />
                        Vendor Management
                    </NavLink> */}
                    <NavLink to="/admin/light-weight-vendors" className="nav-link d-flex align-items-center">
                        <BsPeople className="me-2" />
                        Vendors (Light-Weight)
                    </NavLink>
                    <NavLink to="/admin/orders" className="nav-link d-flex align-items-center">
                        <FiShoppingCart className="me-2" />
                        Order Management
                    </NavLink>
                    <NavLink to="/admin/messages" className="nav-link d-flex align-items-center">
                        <BsTelephone className="me-2" />
                        Customer Messaging
                    </NavLink>
                </li>
            </ul>
            <hr />
            <div className="d-flex align-items-center">
                <Gravatar email={user?.emailAddress} className="rounded-circle me-2" />
                <span className="text-truncate">{user?.emailAddress}</span>
            </div>
        </>
    );
}
