import "./line-items-summary.scss";

import { useMemo } from "react";
import { SimpleTooltip } from "../../../../../common/utilities/simple-tooltip";
import { useOrderContext } from "../../provider/use-order-context";
import { ProductThumbnail } from "./product-thumbnail";

export const LineItemsSummary = () => {
    const { state: { order } } = useOrderContext();
    let { lineItems } = order ?? { lineItems: [] };
    let allowedLineItems = useMemo(() => {
        return (lineItems ?? [])
            .filter(item => item.kind == "rental" || item.kind == "service")
            .toSorted((a, b) => a.sortOrder < b.sortOrder ? -1 : 1);
    }, [lineItems]);
    return (
        <section className="p-3 pt-0">
            <h4 className="mb-3">Line Items {allowedLineItems?.length > 0 ? `(${allowedLineItems.length})` : ""}</h4>
            <section className="d-flex flex-wrap align-items-center pb-3 border-bottom">
                <LineItemsSummarySlim />
            </section>
        </section>);
}

export const LineItemsSummarySlim = () => {
    const { state: { order } } = useOrderContext();
    let { lineItems } = order ?? { lineItems: [] };
    let allowedLineItems = useMemo(() => {
        return (lineItems ?? [])
            .filter(item => item.kind == "rental" || item.kind == "service")
            .toSorted((a, b) => a.sortOrder < b.sortOrder ? -1 : 1);
    }, [lineItems]);
    return (
        <>
            {allowedLineItems?.map((lineItem) => (
                <SimpleTooltip title={lineItem.productName} key={lineItem.productId}>
                    <div className="position-relative mb-3 me-3 w-10 line-items-summary">
                        <span className="z-3 position-absolute top-0 end-0 badge rounded-pill bg-primary count">x{lineItem.count}</span>
                        <ProductThumbnail src={lineItem.primaryImageLink} name={lineItem.productName} />
                    </div>
                </SimpleTooltip>
            ))}
        </>
    );
}
