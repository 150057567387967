import { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useFrontendConfiguration } from "../../../../common/providers/frontend-configuration/use-frontend-configuration";
import { LoadingIcon } from "../../../../common/utilities/loading-icon";
import { GetLocationTypesResponse, getLocationTypes } from "../../../../services/public/get-location-types";
import { GetSetupTypesResponse, getSetupTypes } from "../../../../services/public/get-setup-types";
import { GetOrderByIdResponse, getOrderById } from "../../../../services/public/ordering/get-order-by-id";
import { GetProductItem, getProducts } from "../../../../services/public/ordering/get-products";
import { PostOrderPayResponse, postOrderPay } from "../../../../services/public/ordering/post-order-pay";
import { PutOrderBillingAddressRequest, putOrderBillingAddress } from "../../../../services/public/ordering/put-order-billing-address";
import { PutOrderContactDetailsRequest, putOrderContactDetails } from "../../../../services/public/ordering/put-order-contact-details";
import { PutOrderEventAddressRequest, putOrderEventAddress } from "../../../../services/public/ordering/put-order-event-address";
import { PutOrderEventScheduleRequest, putOrderEventSchedule } from "../../../../services/public/ordering/put-order-event-schedule";
import { PutOrderLineItemsRequest, putOrderLineItems } from "../../../../services/public/ordering/put-order-line-items";
import { PutOrderPromotionCode, PutOrderPromotionCodeRequest } from "../../../../services/public/ordering/put-order-promotion-code";
import { PutOrderSpecialInstructions, PutOrderSpecialInstructionsRequest } from "../../../../services/public/ordering/put-order-special-instructions";

export type OrderContextActions = {
    reload: () => Promise<void>;
    loadProducts: (signal?: AbortSignal) => Promise<void>;
    setLineItems: (request: PutOrderLineItemsRequest, signal?: AbortSignal) => Promise<void>;
    setEventSchedule: (request: PutOrderEventScheduleRequest, signal?: AbortSignal) => Promise<void>;
    setEventAddress: (request: PutOrderEventAddressRequest, signal?: AbortSignal) => Promise<void>;
    setContactDetails: (request: PutOrderContactDetailsRequest, signal?: AbortSignal) => Promise<void>;
    setBillingAddress: (request: PutOrderBillingAddressRequest, signal?: AbortSignal) => Promise<void>;
    setSpecialInstructions: (request: PutOrderSpecialInstructionsRequest, signal?: AbortSignal) => Promise<void>;
    setPromotionCodeRequest: (request: PutOrderPromotionCodeRequest, signal?: AbortSignal) => Promise<void>;
    pay: (signal?: AbortSignal) => Promise<PostOrderPayResponse>;
    setTermsAccepted: (termsAccepted: boolean) => void;
}

export type OrderContextState = {
    order?: GetOrderByIdResponse,
    products?: GetProductItem[],
    locationTypes: GetLocationTypesResponse[],
    setupTypes: GetSetupTypesResponse[],
    isLoading: boolean,
    termsAccepted: boolean,
}

export type OrderContextProps = {
    actions: OrderContextActions;
    state: OrderContextState;
}

export const OrderContext = createContext<OrderContextProps>({
    actions: {
        reload: () => { throw "This method is only valid within a OrderProvider." },
        loadProducts: () => { throw "This method is only valid within a OrderProvider." },
        setLineItems: () => { throw "This method is only valid within a OrderProvider." },
        setEventSchedule: () => { throw "This method is only valid within a OrderProvider." },
        setEventAddress: () => { throw "This method is only valid within a OrderProvider." },
        setContactDetails: () => { throw "This method is only valid within a OrderProvider." },
        setBillingAddress: () => { throw "This method is only valid within a OrderProvider." },
        setSpecialInstructions: () => { throw "This method is only valid within a OrderProvider." },
        setPromotionCodeRequest: () => { throw "This method is only valid within a OrderProvider." },
        pay: () => { throw "This method is only valid within a OrderProvider." },
        setTermsAccepted: () => { throw "This method is only valid within a OrderProvider." },
    },
    state: {
        order: undefined,
        products: undefined,
        isLoading: true,
        locationTypes: [],
        setupTypes: [],
        termsAccepted: false,
    }
});

export type OrderProviderProps = {
    orderId: string;
    children: ReactNode;
}

export function OrderProvider({ orderId, children }: OrderProviderProps) {

    let [order, setOrder] = useState<GetOrderByIdResponse>();
    let [products, setProducts] = useState<GetProductItem[]>();
    let [isLoading, setIsLoading] = useState<boolean>(true);
    let [termsAccepted, setTermsAccepted] = useState<boolean>(false);

    let reload = useCallback(async () => {
        try {
            const response = await getOrderById(orderId);
            setOrder(response);
        } catch (error) {
            console.error(error);
        }
    }, [orderId]);

    let loadProducts = useCallback(async (signal?: AbortSignal) => {
        try {
            const response = await getProducts({}, signal);
            setProducts(response?.results || []);
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        if (!products || products.length === 0) {
            void loadProducts();
        }
    }, [loadProducts, products]);

    let executeAndReload = useCallback(async (handler: () => Promise<void>) => {
        setIsLoading(true);
        try {
            await handler();
            await reload();
        } finally {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    let locationTypes = useLocationTypes();
    let setupTypes = useSetupTypes();

    let actions = useMemo<OrderContextActions>(() => {
        return {
            reload: () => executeAndReload(() => Promise.resolve()),
            loadProducts: (signal) => loadProducts(signal),
            setLineItems: (request, signal) => executeAndReload(() => putOrderLineItems(orderId, request, signal)),
            setEventSchedule: (request, signal) => executeAndReload(() => putOrderEventSchedule(orderId, request, signal)),
            setEventAddress: (request, signal) => executeAndReload(() => putOrderEventAddress(orderId, request, signal)),
            setContactDetails: (request, signal) => executeAndReload(() => putOrderContactDetails(orderId, request, signal)),
            setBillingAddress: (request, signal) => executeAndReload(() => putOrderBillingAddress(orderId, request, signal)),
            setSpecialInstructions: (request, signal) => executeAndReload(() => PutOrderSpecialInstructions(orderId, request, signal)),
            setPromotionCodeRequest: (request, signal) => executeAndReload(() => PutOrderPromotionCode(orderId, request, signal)),
            pay: (signal) => postOrderPay(orderId, signal),
            setTermsAccepted: (termsAccepted) => setTermsAccepted(termsAccepted),
        }
    }, [executeAndReload, loadProducts, orderId])

    let state = useMemo<OrderContextState>(() => {
        return {
            orderId,
            order,
            products,
            isLoading,
            locationTypes,
            setupTypes,
            termsAccepted,
        }
    }, [orderId, order, products, isLoading, locationTypes, setupTypes, termsAccepted]);

    let context = useMemo<OrderContextProps>(() => {
        return {
            actions,
            state
        }
    }, [actions, state]);

    useEffect(() => {
        void (async () => {
            setIsLoading(true);
            try {
                await reload();
            } finally {
                setIsLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let { isLoading: isLoadingConfiguration } = useFrontendConfiguration();

    let showLoading = (state.isLoading && !state.order) || isLoadingConfiguration;

    return (
        <OrderContext.Provider value={context}>
            {showLoading
                ? (
                    <div className="d-flex justify-content-center align-items-center flex-fill">
                        <LoadingIcon />
                    </div>
                )
                : (
                    <>
                        {children}
                    </>
                )}
        </OrderContext.Provider>
    )
}

function useLocationTypes() {
    let [locationTypes, setLocationTypes] = useState<GetLocationTypesResponse[]>([]);
    useEffect(() => {
        if (locationTypes?.length === 0) {
            const loadLocationTypes = async () => {
                try {
                    let response = await getLocationTypes();
                    setLocationTypes(response);
                } catch (error) {
                    console.warn(error);
                }
            };
            void loadLocationTypes();
        }
    }, [locationTypes]);

    return locationTypes;
}

function useSetupTypes() {
    let [setupTypes, setSetupTypes] = useState<GetSetupTypesResponse[]>([]);
    useEffect(() => {
        if (setupTypes?.length === 0) {
            const loadSetupTypes = async () => {
                try {
                    let response = await getSetupTypes();
                    setSetupTypes(response);
                } catch (error) {
                    console.warn(error);
                }
            };
            void loadSetupTypes();
        }
    }, [setupTypes]);

    return setupTypes;
}
