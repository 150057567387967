import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CreateVendorInviteCommand, CreateVendorInviteInput } from "../../../common/api/handlers/admin/vendors/create-vendor-invite";
import { IsVendorNameAvailableQuery } from "../../../common/api/handlers/admin/vendors/is-vendor-name-available";
import { useApiHandlerWithAuth } from "../../../common/api/use-api-handler-with-auth";
import { CustomErrorMessage } from "../../../common/forms/custom-error-message";
import { LabelWithError } from "../../../common/forms/label-with-error";
import { adminVendorPaths } from "./vendors";

export function VendorsInvite() {


    let navigator = useNavigate();
    let isNameAvailableHandler = useApiHandlerWithAuth(IsVendorNameAvailableQuery);
    let createDraftHandler = useApiHandlerWithAuth(CreateVendorInviteCommand);

    let {
        register,
        handleSubmit,
        formState: { errors, isValidating, isValid, isSubmitting },
        setError
    } = useForm<CreateVendorInviteInput & { root: never }>({
        mode: "onChange",
        defaultValues: {
            name: "",
            contactEmailAddress: "",
            firstLoginEmail: ""
        }
    });

    let nameValidator = async (vendorName: string) => {
        let result = await isNameAvailableHandler({ vendorName });
        if (result?.available) {
            return true;
        }
        return "Vendor name is already in use";
    };

    let submit = async (data: CreateVendorInviteInput) => {
        try {
            let { vendorInviteId } = await createDraftHandler(data);
            navigator(adminVendorPaths.list, { state: { vendorInviteId } });
        } catch (error) {
            setError("root", {
                message: "Failed to create vendor invite."
            });
        }
    };

    return (
        <form className="form" onSubmit={handleSubmit(submit)}>
            <div className="row">
                <div className="col-6">
                    <div className="row">
                        <div className="col">
                            Let&apos;s create that vendor invite!
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Example Name"
                                    {...register("name", {
                                        validate: nameValidator,
                                        required: true
                                    })}
                                />
                                <LabelWithError name="name" errors={errors}>
                                    Vendor Legal Name
                                </LabelWithError>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col">
                            <p>
                                The vendor&apos;s name should typically be their registered company name (or their registered DBA). If no company name is registered, their full legal name can be used as a substitute.
                            </p>
                            <p>
                                The vendor&apos;s name must be unique to prevent confusion in the future.
                            </p>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col">
                            <div className="form-floating">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Example Email"
                                    {...register("contactEmailAddress", {
                                        required: true
                                    })}
                                />
                                <LabelWithError name="contactEmailAddress" errors={errors}>
                                    Contact Email Address
                                </LabelWithError>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col">
                            <p>
                                This will be how we contact this vendor in the future. Typically, this email address is expected to be controlled by many employees - we won&apos;t send anything sensitive to this address.
                            </p>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col">
                            <div className="form-floating">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Example Email"
                                    {...register("firstLoginEmail", {
                                        required: true
                                    })}
                                />
                                <LabelWithError name="firstLoginEmail" errors={errors}>
                                    Administrator Email Address
                                </LabelWithError>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col">
                            <p>
                                We&apos;ll create the login to administrator this vendor account and and the vendor invite to this address. This email address may match the &quot;Contact Email Address&quot; above, although this is not required.
                            </p>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-sm d-flex">
                            <button
                                type="submit"
                                className="btn btn-primary ms-auto"
                                disabled={isSubmitting || isValidating || !isValid}>
                                Create
                            </button>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-sm d-flex">
                            <CustomErrorMessage className="ms-auto" name="root" errors={errors} />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}
