export const Home = () => {
    return (
        <div>
            <h1>Home</h1>
            <p>This is just a placeholder.</p>
        </div>
    );
};

// To use lazy() for chunking, react only supports default imports. Hate it.
// Alternate solution: https://github.com/facebook/react/issues/14603#issuecomment-726551598
export default Home;
