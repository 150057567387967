import clsx from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { PaymentElement, useElements } from "@stripe/react-stripe-js";
import { Regex } from "../../../../common/constants/regex";
import { LabelWithError } from "../../../../common/forms/label-with-error";
import { addressToString } from "../../../../common/utilities/address-to-string";
import { GetAddressByPlaceIdResponse } from "../../../../services/public/address-validation/get-address-by-place-id";
import { Address, AddressAutocomplete } from "../address-autocomplete";
import { useOrderContext } from "../provider/use-order-context";

type BillingAddressForm = {
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    allowSmsNotifications: boolean;
    billingAddress: Address;
}

export function PaymentDetailsSection({ next }: { next: () => void }) {
    let { actions: { setBillingAddress, setContactDetails }, state: { order } } = useOrderContext();
    let { billingAddress, contactDetails, eventAddress } = order ?? {
        billingAddress: undefined,
        eventAddress: undefined,
        contactDetails: undefined
    };

    let [sameAsPartyVenue, setSameAsPartyVenue] = useState<boolean>(false);
    let [isStripeValid, setIsStripeValid] = useState<boolean>(false);
    const elements = useElements();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid },
        watch
    } = useForm<BillingAddressForm>({
        mode: "onChange",
    });

    const eventAddressString = useMemo(() => {
        return addressToString(eventAddress || {});
    }, [eventAddress]);

    useEffect(() => {
        if (billingAddress && eventAddress) {
            let isBillingSameAsPartyVenue = addressToString(billingAddress) == addressToString(eventAddress as GetAddressByPlaceIdResponse);
            setSameAsPartyVenue(isBillingSameAsPartyVenue);
        }
    }, [billingAddress, eventAddress]);

    useEffect(() => {
        if (contactDetails) {
            const { name, emailAddress, phoneNumber, allowSmsNotifications } = contactDetails;
            setValue("contactName", name, { shouldValidate: !!name });
            setValue("contactEmail", emailAddress, { shouldValidate: !!emailAddress });
            setValue("contactPhone", phoneNumber, { shouldValidate: !!phoneNumber });
            setValue("allowSmsNotifications", allowSmsNotifications);
        }
        if (billingAddress) {
            setValue("billingAddress", billingAddress, { shouldValidate: !!billingAddress });
        }
    }, [billingAddress, contactDetails, setValue]);

    const onSubmit = async (data: BillingAddressForm) => {
        try {
            if (!data) {
                throw new Error("Contact details & Billing address are not set");
            }

            const { billingAddress, contactName, contactEmail, contactPhone, allowSmsNotifications } = data;
            await setContactDetails({
                name: contactName,
                emailAddress: contactEmail,
                phoneNumber: contactPhone,
                allowSmsNotifications: allowSmsNotifications
            });
            await setBillingAddress(billingAddress);

            next();
        } catch (error) {
            console.error(error);
        }
    };

    const handleSameAsPartyVenueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (eventAddress) {
            setSameAsPartyVenue(event.target.checked);
            setValue("billingAddress", eventAddress, { shouldValidate: true });
        }
    };

    const handleStripeChange = async (event: { complete: boolean, empty: boolean, collapsed: boolean, value: { type: string }, elementType: string }) => {
        if (event.complete) {
            let error = await elements?.submit();
            if (error?.error) {
                console.warn("Stripe report complete, but invalid:", error);
                setIsStripeValid(false);
            } else {
                console.log("Stripe reports complete and valid.")
                setIsStripeValid(true);
            }
        } else {
            console.warn("Stripe reports incomplete:", event);
            setIsStripeValid(false);
        }
    };

    let formBillingAddress = watch("billingAddress");

    return (
        <>
            <form className="m-n3" onSubmit={handleSubmit(onSubmit)}>
                <section className="p-3 pt-0 row g-3">
                    <section className="col-12 col-md-6">
                        <div className="form-group">
                            <LabelWithError errors={errors} name="contactName">Name</LabelWithError>
                            <input
                                type="text"
                                className="form-control"
                                id="contactName"
                                {...register("contactName", {
                                    required: true
                                })} />
                        </div>
                    </section>
                    <section className="col-12 col-md-6">
                        <div className="form-group">
                            <LabelWithError errors={errors} name="contactEmail">Email Address</LabelWithError>
                            <input
                                type="email"
                                className="form-control"
                                id="contactEmail"
                                {...register("contactEmail", {
                                    required: true,
                                    pattern: {
                                        value: Regex.email,
                                        message: "Email Address is invalid"
                                    }
                                })} />
                        </div>
                    </section>
                    <section className="col-12 col-md-6">
                        <div className="form-group">
                            <LabelWithError errors={errors} name="contactPhone">Phone Number</LabelWithError>
                            <input
                                type="tel"
                                className="form-control"
                                id="contactPhone"
                                {...register("contactPhone", {
                                    required: true,
                                    pattern: {
                                        value: Regex.phoneNumber,
                                        message: "Phone Number is invalid"
                                    }
                                })} />
                        </div>
                    </section>
                    <section className="col-12 col-md-6 d-flex align-items-center" style={{ flexDirection: "row" }}>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="allowSmsNotifications"
                                {...register("allowSmsNotifications")}
                            />
                            <label className="form-check-label user-select-none" htmlFor="allowSmsNotifications">
                                Yes! Send me order notifications via SMS.
                            </label>
                            <div className="text-muted" style={{ fontSize: "0.8rem" }}>Message and data rates may apply.</div>
                        </div>
                    </section>
                </section>

                <section className="p-3 border-top">
                    <div className="mb-2">Payment Details</div>
                    <PaymentElement options={{
                        layout: {
                            type: "tabs",
                            defaultCollapsed: false,
                            radios: true,
                            spacedAccordionItems: false
                        },
                        fields: {
                            billingDetails: "never"
                        },
                        defaultValues: {
                            billingDetails: {
                                address: {
                                    country: "US"
                                }
                            }
                        }
                    }} onChange={handleStripeChange} />
                </section>

                <section className="p-3 pt-0">
                    <div className="row g-3">
                        <section className="col-12">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="sameAsPartyVenue"
                                    checked={sameAsPartyVenue}
                                    onChange={handleSameAsPartyVenueChange}
                                    disabled={!eventAddressString}
                                />
                                <label className="form-check-label user-select-none" htmlFor="sameAsPartyVenue">
                                    Billing Address is same as Party Address {eventAddressString &&
                                        (<b>({eventAddressString.trim()})</b>)
                                    }
                                </label>
                            </div>
                        </section>
                        {!sameAsPartyVenue && (
                            <>
                                <section className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="partyDate">Billing Address Line 1</label>
                                        <AddressAutocomplete
                                            onChange={(x) => setValue("billingAddress", x, { shouldValidate: true })}
                                            address={formBillingAddress}
                                            labelOverride={formBillingAddress?.streetLine1}
                                        />
                                    </div>
                                </section>
                                <section className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="street2">Billing Address Line 2</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="street2"
                                            {...register("billingAddress.streetLine2")} />
                                    </div>
                                </section>
                                <section className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="city">City</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="city"
                                            {...register("billingAddress.city", {
                                                required: true
                                            })} />
                                    </div>
                                </section>
                                <section className="col-12 col-md-3">
                                    <div className="form-group">
                                        <label htmlFor="state">State</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="state"
                                            {...register("billingAddress.state", {
                                                required: true
                                            })} />
                                    </div>
                                </section>
                                <section className="col-12 col-md-3">
                                    <div className="form-group">
                                        <LabelWithError errors={errors} name="zipCode">Zip Code</LabelWithError>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="zipCode"
                                            {...register("billingAddress.zipCode", {
                                                required: true,
                                                pattern: {
                                                    value: Regex.zipFull,
                                                    message: "Zip Code is invalid"
                                                }
                                            })} />
                                    </div>
                                </section>
                            </>
                        )}
                    </div>
                </section>

                <section className="p-3 border-top">
                    <div className="row">
                        <section className="col-12 col-md-6">
                            <PromotionCodeForm />
                        </section>
                    </div>
                </section>

                <section className="p-3 border-top d-flex align-items-center">
                    <div className="text-muted">
                        Your billing details will be securely sent to <a href="https://stripe.com" target="_blank" rel="noreferrer" className="text-muted">Stripe</a>, our payment processor.
                    </div>
                    <button type="submit"
                        className="btn btn-outline-primary ms-auto"
                        disabled={!isValid || !isStripeValid}>
                        Review Order
                    </button>
                </section>
            </form>
        </>
    )
}

function PromotionCodeForm() {

    let { actions: { setPromotionCodeRequest }, state: { order } } = useOrderContext();

    let [isValid, setIsValid] = useState<boolean>(true);
    let [code, setCode] = useState<string>();
    let [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setCode(order?.promotionCode.code);
        setIsValid(!!order?.promotionCode.isValid || !order?.promotionCode.code);
    }, [order?.promotionCode.code, order?.promotionCode.isValid]);

    let apply = useCallback(async () => {
        setIsLoading(true);
        try {
            await setPromotionCodeRequest({ code: code })
        } finally {
            setIsLoading(false);
        }
    }, [code, setPromotionCodeRequest]);

    return (
        <div className="form-group">
            <label htmlFor="code" className={clsx(!isValid && "text-danger")}>
                {isValid ? "Promotion Code" : "Promotion Code is invalid"}
            </label>
            <div className="d-flex">
                <input type="text"
                    className={clsx("form-control text-uppercase", !isValid && "border border-danger")}
                    onChange={x => {
                        setCode(x.target.value);
                        setIsValid(true);
                    }}
                    value={code}
                    id="code"></input>
                <button className="btn btn-outline-primary ms-3"
                    disabled={isLoading}
                    onClick={apply}>
                    Apply
                </button>
            </div>
        </div>
    )
}
