import { DateTime } from "luxon";
import { LineItem } from "../../../../services/public/ordering/get-order-by-id";
import { ProductThumbnail } from "./line-items/product-thumbnail";
import "./services-arrival-time-card.scss";

type ServicesArrivalTimeCardProps = {
    lineItem: LineItem;
    timeRange: string[];
    onArrivalTimeSelection: (lineItemId: string, arrivalDateTime: string) => void;
};

export const ServicesArrivalTimeCard = ({ lineItem, timeRange, onArrivalTimeSelection }: ServicesArrivalTimeCardProps) => {

    const handleArrivalTimeSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
        onArrivalTimeSelection(lineItem.lineItemId, event.target.value);
    };

    return (
        <li className="mb-2 p-3 border-bottom d-flex align-items-center services-arrival-time-card" key={lineItem.lineItemId}>
            <ProductThumbnail
                className="me-3 arrival-time-image"
                src={lineItem.primaryImageLink}
                name={lineItem.productName} />
            <section className="w-60 d-flex flex-column me-3">
                <h6 className="card-title me-2">{lineItem.productName}</h6>
                {
                    lineItem.setupTimeMinutes && lineItem.teardownTimeMinutes && (
                        <i className="service-info text-muted">
                            {lineItem.productName} requires {lineItem.setupTimeMinutes} minutes to setup before start time and {lineItem.teardownTimeMinutes} minutes after the session to tear down.
                        </i>
                    )
                }
            </section>
            <select
                className="form-select w-25 ms-auto"
                aria-label="Select an Arrival Time"
                value={DateTime.fromISO(lineItem.arrivalDateTime).toISO()!} // HACK to get ISO times to always match.
                onChange={handleArrivalTimeSelection}>
                <option value="">Select a time</option>
                {timeRange.map((isoTime) => (
                    <option key={isoTime} value={isoTime}>{DateTime.fromISO(isoTime).toLocaleString(DateTime.TIME_SIMPLE)}</option>
                ))}
            </select>
        </li>
    );
};
