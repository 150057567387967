import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type DisableProductInput = {
    productId: string;
};

export const DisableProductCommand: ApiHandlerWithAuth<DisableProductInput, void> = {
    handle: async ({ productId }, authHeader) => {
        let response = await fetch(`/api/v1/admin/products/${productId}/disable`, {
            method: "POST",
            headers: getDefaultHeaders(authHeader)
        });

        if (!response.ok) {
            throw response;
        }
    }
}
