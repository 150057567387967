import "./login.scss";

import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../common/auth/use-auth";
import { FormLayout } from "../../../common/components/layouts/form-layout/form-layout";
import { LabelWithError } from "../../../common/forms/label-with-error";
import { keyof } from "../../../common/utilities/key-of";
import { adminPaths } from "../../admin/admin.routes";

const formText = {
    emailAddress: "Email Address",
    password: "Password"
};

type LoginFormType = {
    emailAddress: string;
    password: string;
};

function Login() {
    const {
        register,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors, isValid, isSubmitting }
    } = useForm<LoginFormType>({
        defaultValues: { emailAddress: "", password: "" }
    });

    let emailAddressProp = keyof<LoginFormType>("emailAddress");
    let passwordProp = keyof<LoginFormType>("password");

    let navigate = useNavigate();
    let { state } = useLocation();
    let { login } = useAuth();

    const onSubmit: SubmitHandler<LoginFormType> = async (data) => {
        clearErrors();
        let user = await login(data.emailAddress, data.password);
        if (user) {
            if (state?.redirectTo) {
                navigate(state?.redirectTo);
            } else if (user.isAdmin) {
                navigate(adminPaths.home);
            } else if (user.isVendor) {
                navigate(adminPaths.home);
            }
        } else {
            setError("password", { message: "Password is incorrect." }, { shouldFocus: true });
        }
    };

    return (
        <>
            <h1 className="text-center mt-3">Login</h1>
            <FormLayout>
                <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column">
                    <div className="form-floating mb-3">
                        <input
                            type="email"
                            className="form-control"
                            placeholder={formText.emailAddress}
                            autoComplete="username"
                            {...register(emailAddressProp, { required: true })}
                        />
                        <LabelWithError errors={errors} name={emailAddressProp}>
                            {formText.emailAddress}
                        </LabelWithError>
                    </div>

                    <div className="form-floating mb-3">
                        <input
                            type="password"
                            className="form-control"
                            placeholder={formText.password}
                            autoComplete="current-password"
                            {...register(passwordProp, { required: true })}
                        />
                        <LabelWithError errors={errors} name={passwordProp}>
                            {formText.password}
                        </LabelWithError>
                    </div>

                    <button className="btn btn-outline-primary" type="submit" disabled={!isValid || isSubmitting}>
                        Submit
                    </button>
                </form>
            </FormLayout>
        </>
    );
}

// To use lazy() for chunking, react only supports default imports. Hate it.
export default Login;
