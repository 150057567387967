export function distinct<T>(
  collection: T[],
  comparer: (a: T, b: T) => boolean
) {
  return collection.filter(
    (value, index, array) => array.findIndex((x) => comparer(value, x)) == index
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function groupBy<T, K extends keyof any>(
  list: T[],
  getKey: (item: T) => K
) {
  // https://stackoverflow.com/a/62765924/2001966
  return list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);

    if (!previous[group]) {
      previous[group] = [];
    }

    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);
}
