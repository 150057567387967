import { useMemo } from "react";
import { FaCalendarAlt, FaClock, FaTruckPickup } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { addressToString } from "../../../../common/utilities/address-to-string";
import { FormatDateWithAgo, FormatTime } from "../../../../common/utilities/formatters/format-date";
import { useOrderContext } from "../provider/use-order-context";
import { SameDayPickupAddonProductId } from "./line-items/well-known-items";

export function PartyDetailsHeader({ open }: { open: boolean }) {
    let { state: { order } } = useOrderContext();
    let { eventSchedule, eventAddress, lineItems } = order ?? {
        eventSchedule: undefined,
        eventAddress: undefined,
        lineItems: undefined
    };

    let sameDayPickupAddon = useMemo(() => {
        return lineItems?.find(x => x.productId == SameDayPickupAddonProductId);
    }, [lineItems]);

    return (
        <div className="d-flex flex-column justify-content-center">
            <div className="mt-1">
                Party Details
            </div>
            {!open && eventSchedule && eventAddress && (
                <div className="row mt-2">
                    <div className="col-12 d-flex align-items-center mb-2">
                        <FaLocationDot className="me-2 text-primary" />
                        <span>{addressToString(eventAddress)}</span>
                    </div>
                    <div className="col-12 col-lg-6 d-flex align-items-center mb-2">
                        <FaCalendarAlt className="me-2 text-primary" />
                        <FormatDateWithAgo dateTime={eventSchedule?.startOn} />
                    </div>
                    <div className="col-12 col-lg-6 d-flex align-items-center text-truncate mb-2">
                        <FaClock className="me-2 text-primary" />
                        <FormatTime dateTime={eventSchedule?.startOn} />
                        <div className="ms-1">
                            for {eventSchedule?.durationHours}
                        </div>
                        <div className="ms-1">
                            {eventSchedule?.durationHours == 1 ? "hour" : "hours"}
                        </div>
                    </div>
                    <div className="col-12 mb-2">
                        <div className="d-flex align-items-center text-truncate">
                            <FaTruckPickup className="me-2 text-primary" />
                            <div>
                                {sameDayPickupAddon ? `Same Day Pickup ${sameDayPickupAddon.comment}` : "Standard Pickup"}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
