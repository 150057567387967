import { ReactNode, useCallback, useMemo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useFrontendConfiguration } from "../../../../common/providers/frontend-configuration/use-frontend-configuration";
import { useOrderContext } from "./use-order-context";

export function StripeElementsProvider({ children }: { children: ReactNode }) {

    let { configuration } = useFrontendConfiguration();

    let initStripe = useCallback(async () => {
        return await loadStripe(configuration!.payments.stripePublishableApiKey);
    }, [configuration]);

    let stripe = useMemo(initStripe, [initStripe]);

    let { state } = useOrderContext();

    let amount = (state.order?.totalPriceIncludingTaxes ?? state.order?.totalPriceExcludingTaxes) ?? 0;
    let cents = Math.floor(Math.max(amount * 100, 50)); // Fake a value here just to let the user enter data in.

    return (
        <Elements stripe={stripe} options={{
            mode: "payment",
            amount: cents,
            currency: "usd",
            appearance: {
                theme: "stripe",
                variables: {
                    colorPrimary: "#df744a", // --bs-primary
                    borderRadius: ".375rem", // --bs-border-radius
                    colorDanger: "#dc3545", // --bs-danger
                    fontSizeBase: "1rem", // --bs-body-font-size
                    colorText: "#212529", // --bs-body-color
                    fontFamily: "Roboto, arial, sans-serif",
                },
                rules: {
                    ".TabLabel--selected": {
                        color: "#212529" // --bs-body-color
                    }
                }
            },
            fonts: [
                {
                    cssSrc: "https://fonts.bunny.net/css?family=Roboto"
                }
            ]
        }}>
            {children}
        </Elements>
    )
}
