import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type GetPlannerSurveysInput = object;

export const GetPlannerSurveysQuery: ApiHandlerWithAuth<GetPlannerSurveysInput, GetPlannerSurveysResult> = {
    handle: async (_, authHeader) => {
        let response = await fetch("/api/v1/surveys/planner", {
            method: "GET",
            headers: getDefaultHeaders(authHeader)
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as GetPlannerSurveysResult;
    }
}

export type GetPlannerSurveysResult = {
    id: string;
    timestamp: string;
    fullName: string;
    emailAddress: string;
    phoneNumber: string;
    zipCode: string;
}[];
