import "./public-layout.scss";

import clsx from "clsx";
import { useOutlet } from "react-router-dom";
import { PublicHeader } from "./public-header";

type UnAuthLayoutProps = {
  className?: string;
};

export function PublicLayout({ className }: UnAuthLayoutProps) {
  const outlet = useOutlet();
  const classes = clsx("d-flex flex-column flex-fill", className);

  return (
    <>
      <PublicHeader />
      <main className={classes}>{outlet}</main>
    </>
  );
}
