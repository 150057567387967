import "./products-edit.scss";
import { useParams } from "react-router-dom";
import { Product } from "./products-list";
import { useApiHandlerWithAuth } from "../../../common/api/use-api-handler-with-auth";
import { GetOneProductCommand } from "../../../common/api/handlers/admin/products/get-one-product";
import { useEffect, useState } from "react";
import { ProductEditForm } from "./shared/product-edit-form";
import { ProductImages } from "./shared/product-images";
import { ProductToggle } from "./shared/product-toggle";

export function AdminProductsEditPage() {
  let { productId = "" } = useParams();
  let [isLoading, setIsLoading] = useState(false);
  let [product, setProduct] = useState<Product>();
  let getProductHandler = useApiHandlerWithAuth(GetOneProductCommand);

  if (!productId) {
    throw "Product id is falsy, this shouldn't be possible.";
  }

  useEffect(() => {
    const getProductDetails = async () => {
      try {
        setIsLoading(true);
        let response = await getProductHandler({ productId });
        setProduct(response);
        if (!response) {
          throw "Failed to get product details.";
        }
      } catch (error) {
        console.warn(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!product && !isLoading) {
      void getProductDetails();
    }
  }, [getProductHandler, isLoading, product, productId]);

  const handleSubmission = (product: Product) => {
    setProduct(product);
  };

  return (
    <div className="d-flex flex-column edit-admin-product h-100">
      {isLoading && <div className="col">Loading...</div>}
      {!isLoading && product && (
        <>
          <section className="row flex-fill overflow-auto">
            <section className="col-lg-5">
              <h3>Edit Product Details</h3>
              <ProductEditForm
                product={product}
                productId={productId}
                onSubmitted={handleSubmission}
              />


              <div className="row mt-3">
                <div className="col-12">
                  <h3>Danger Zone</h3>
                  <div className="p-3 bg-white border rounded d-flex flex-column mb-3">
                    <ProductToggle productId={productId} />
                    <span className="fst-italic field-info mt-2">
                      A disabled product is hidden from vendors and most admin
                      views. If this product is widely used by vendors, make
                      sure to communicate deprecating the product. Re-enabling a
                      disabled product will restore any existing inventory.
                    </span>
                  </div>
                </div>
              </div>
            </section>
            <section className="col-lg-7">
              <h3>Product Images</h3>
              <ProductImages productId={productId} />
            </section>
          </section>
        </>
      )}
    </div>
  );
}
