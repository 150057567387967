import "./order-details.scss";

import { useMemo } from "react";
import { FaCalendarAlt, FaMobileAlt } from "react-icons/fa";
import { FaLocationDot, FaPerson, FaRegClock } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FormatDateWithAgo, FormatTime } from "../../../common/utilities/formatters/format-date";
import { AddressMap } from "../../../common/utilities/orders/address-map";
import { FormatAddressWithDirections } from "../../../common/utilities/orders/format-address";
import { Address, GetOrderByIdResponse } from "../../../services/public/ordering/get-order-by-id";
import { LineItemsSummarySlim } from "../ordering/sections/line-items/line-items-summary";

type Order = GetOrderByIdResponse;

export function OrderDetails({ order }: { order: Order }) {
    return (
        <div className="order-details bg-white border rounded mt-5">
            <div className="p-3">
                <h1 className="fs-3 fw-light m-0">Order #{order.humanReadableOrderId}</h1>
            </div>

            <div className="border-top p-3 pb-0">
                <div className="section-title">Order Summary</div>
                <div className="d-flex flex-wrap align-items-center">
                    <LineItemsSummarySlim />
                </div>
            </div>

            <div className="border-top p-3">
                <div className="section-title">Event Address</div>
                <AddressMap address={order.eventAddress} />
                <div className="mt-1 d-flex align-items-center">
                    <FormatAddressWithDirections address={order.eventAddress} />
                </div>
            </div>

            <div className="border-top p-3">
                <div className="section-title">Event Schedule</div>
                <div className="row">
                    <div className="col-12 col-lg-6 d-flex align-items-center">
                        <FaCalendarAlt className="me-2" />
                        <FormatDateWithAgo dateTime={order.eventSchedule.startOn} />
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="d-flex align-items-center text-truncate">
                            <FaRegClock className="me-2" />
                            <FormatTime dateTime={order.eventSchedule.startOn} />
                            <div className="ms-1">
                                for {order.eventSchedule.durationHours}
                            </div>
                            <div className="ms-1">
                                {order.eventSchedule.durationHours == 1 ? "hour" : "hours"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="border-top p-3">
                <div className="section-title">Contact Details</div>

                <div className="row">
                    <div className="col-12 col-lg-4 d-flex align-items-center">
                        <FaPerson className="me-2" />
                        <div className="text-truncate">{order.contactDetails.name}</div>
                    </div>
                    <div className="col-12 col-lg-4 d-flex align-items-center">
                        <FaMobileAlt className="me-2" />
                        <a href={`tel:${order.contactDetails.phoneNumber}`} className="text-dark text-truncate">
                            {order.contactDetails.phoneNumber}
                        </a>
                    </div>
                    <div className="col-12 col-lg-4 d-flex align-items-center">
                        <MdEmail className="me-2" />
                        <a href={`mailto:${order.contactDetails.emailAddress}`} className="text-dark text-truncate">
                            {order.contactDetails.emailAddress}
                        </a>
                    </div>
                </div>
            </div>

            <div className="border-top p-3">
                <div className="section-title">Special Instructions</div>
                <i>{order.specialInstructions ?? "None"}</i>
            </div>

            <div className="border-top p-3 d-flex flex-column align-items-center">
                <div className="text-muted">Need Help?</div>
                <EmailUsButton />
            </div>

        </div>
    )
}

function EmailUsButton() {
    return (
        <a href="mailto:hello@partyeezy.com" className="btn btn-outline-primary mt-2">Contact Us</a>
    )
}
