import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type SendMessageInput = {
    conversation: string;
    message: string;
};

export const SendMessage: ApiHandlerWithAuth<SendMessageInput, void> = {
    handle: async ({ conversation, message }, authHeader) => {
        let response = await fetch(`/api/v1/admin/messages/${conversation}`, {
            method: "POST",
            headers: getDefaultHeaders(authHeader),
            body: JSON.stringify({
                message: message
            })
        });

        if (!response.ok) {
            throw response;
        }
    }
}
