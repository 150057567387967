import { ValidateResult } from "react-hook-form";
import { IsPromotionCodeAvailable } from "../../../../common/api/handlers/admin/promotion-codes/is-promotion-code-available";
import { useApiHandlerWithAuth } from "../../../../common/api/use-api-handler-with-auth";
import { Regex } from "../../../../common/constants/regex";

export function usePromotionCodeValidation(originalCode?: string) {

    let handler = useApiHandlerWithAuth(IsPromotionCodeAvailable);

    return {
        codeValidator: async (code?: string): Promise<ValidateResult> => {
            if (!code) {
                return "Promotion code is required";
            }

            let normalizedCode = code.trim();

            if (!Regex.promotionCode.test(normalizedCode)) {
                return "Promotion code format is not valid"
            }

            if (originalCode == null || originalCode != normalizedCode) {
                try {
                    let result = await handler({ code: normalizedCode });
                    if (!result.available) {
                        return "Promotion code already exists"
                    }
                } catch (error) {
                    return "Promotion code failed to validate"
                }
            }

            return true;
        },
        discountValidator: (discount?: number): ValidateResult => {

            if (discount == null) {
                return "Discount is required"
            }

            if (discount <= 0) {
                return "Discount must have a positive value"
            }

            if (discount >= 100) {
                return "Discount cannot be so great"
            }
        }
    }
}
