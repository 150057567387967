import "./order-section.scss";

import clsx from "clsx";
import { ReactElement, useEffect, useMemo, useRef } from "react";
import { Accordion } from "react-bootstrap";
import { BsCheckCircleFill, BsChevronRight } from "react-icons/bs";
import { Menu } from "./planner-order";

export type AccordionSectionProps = {
    header: (open: boolean) => ReactElement;
    section: ReactElement;
    menu: Menu;
    selectedMenu: Menu;
    onSelect: (menu: Menu) => void;
    complete: boolean;
}

export function AccordionSection({ header, section, menu, selectedMenu, onSelect, complete }: AccordionSectionProps) {

    let selected = useMemo(() => selectedMenu == menu, [menu, selectedMenu]);

    let ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const closeAnimationDuration = 0.35 * 1000;
        let timeout = setTimeout(() => {
            if (selected) {
                ref.current?.scrollIntoView({ behavior: "smooth" })
            }
        }, closeAnimationDuration);
        return () => clearTimeout(timeout);
    }, [selected])

    return (
        <div className={clsx("menu border rounded-3 mt-3 bg-white", selected && "active")} ref={ref}>
            <div onClick={() => onSelect(menu)}
                role={clsx(!selected && "button")}
                className="w-100 menu-header d-flex justify-content-between p-3 align-items-center rounded-3">
                <div className="w-90 d-flex flex-grow-1">
                    {complete && (
                        <BsCheckCircleFill className="me-3 mt-1 text-success" style={{ height: 24, width: 24 }} />
                    )}
                    {!complete && (
                        <BsCheckCircleFill className="me-3 text-muted" style={{ height: 24, width: 24 }} />
                    )}
                    <div className="d-flex w-90">{header(selected)}</div>
                </div>
                <BsChevronRight className="chevron flex-grow-1" />
            </div>
            <Accordion.Collapse eventKey={menu}>
                <div className="menu-body border-top p-3">
                    {section}
                </div>
            </Accordion.Collapse>
        </div>
    )
}
