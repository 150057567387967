import "./banner-footer.scss";

import clsx from "clsx";

export type BannerFooterProps = {
    className?: string;
}

export function BannerFooter({ className }: BannerFooterProps) {
    return (
        <div className={clsx("banner-footer w-100", className)} />
    )
}
