import "property-information"; // https://github.com/remarkjs/react-markdown/issues/747

import { Accordion } from "react-bootstrap";
import Markdown from "react-markdown";
import faqJson from "./faq-section.json";

const faq = faqJson as FaqItem[];

export function FaqSection() {
    return (
        <div className="">
            <Accordion>
                {faq.map((x, i) => (
                    <Accordion.Item key={i} eventKey={i.toString()}>
                        <Accordion.Header>
                            <div className="text-capitalize" style={{ fontSize: "var(--bs-btn-font-size)", fontWeight: "var(--bs-btn-font-weight)" }}>{x.title}</div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="p-3 pb-0">
                                <Markdown>
                                    {x.text}
                                </Markdown>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            <div className="d-flex flex-column align-items-center my-5">
                <h4 className="mb-1 text-uppercase">Still Have Questions?</h4>
                <a href="mailto:hello@partyeezy.com" className="fs-5 text-uppercase">
                    Contact Us
                </a>
            </div>
        </div>
    )
}

type FaqItem = {
    title: string;
    text: string;
}
