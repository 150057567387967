import { ReactNode, useEffect } from "react";
import Tap from "@tapfiliate/tapfiliate-js";

const AccountId = "48483-a75a66";

export type TapfiliateProviderProps = {
    children: ReactNode;
}

export function TapfiliateProvider({ children }: TapfiliateProviderProps) {
    useEffect(() => {
        try {
            Tap.init(AccountId);
        } catch (error) {
            console.warn("Failed to init Tapfiliate, it was likely blocked.", error);
        }
    }, []);
    return children;
}

export function tapfiliateConversion(orderId: string, amount: number) {
    try {
        Tap.conversion(orderId, amount);
    } catch (error) {
        console.warn("Failed to record a conversion with Tapfiliate, it was likely blocked.", error);
    }
}
