import clsx from "clsx";
import { useState } from "react";
import { useApiHandlerWithAuth } from "../../../../common/api/use-api-handler-with-auth";
import { DisableProductCommand } from "../../../../common/api/handlers/admin/products/disable-product";
import { EnableProductCommand } from "../../../../common/api/handlers/admin/products/enable-product";

export type ProductToggleProps = {
    defaultValue?: boolean;
    className?: string;
    productId: string;
}

export function ProductToggle({ defaultValue, className, productId }: ProductToggleProps) {

    let [isDisabled, setIsDisabled] = useState(() => defaultValue);
    let [isLoading, setIsLoading] = useState(() => defaultValue);

    let disableHandler = useApiHandlerWithAuth(DisableProductCommand);
    let enableHandler = useApiHandlerWithAuth(EnableProductCommand);

    let toggle = async () => {
        setIsLoading(true);
        let handler = isDisabled ? enableHandler : disableHandler;
        try {
            await handler({ productId });
            setIsDisabled(!isDisabled);
        } catch (error) {
            console.warn(error);
        }
        setIsLoading(false);
    };

    return isDisabled
        ? <button className={clsx("btn btn-outline-success", className)} disabled={isLoading} onClick={toggle}>Enable Product</button>
        : <button className={clsx("btn btn-outline-danger", className)} disabled={isLoading} onClick={toggle}>Disable Product</button>
}
