import "./product-add-image.scss";

import clsx from "clsx";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BiImageAdd } from "react-icons/bi";
import { CreateProductImageCommand } from "../../../../common/api/handlers/admin/product-images/create-product-image";
import { useApiHandlerWithAuth } from "../../../../common/api/use-api-handler-with-auth";

export type AddProductImageProps = {
    productId: string;
    className?: string;
    onUploaded?: (productImageId: string) => void;
}

type Status = {
    totalCount: number;
    currentIndex: number;
    currentFileUploading?: string;
}

export function AddProductImage({ productId, className, onUploaded }: AddProductImageProps) {

    let [status, setStatus] = useState<Status>();

    let uploadHandler = useApiHandlerWithAuth(CreateProductImageCommand);
    let uploadImages = useCallback(async (files: File[]) => {
        setStatus({ totalCount: files.length, currentIndex: 0 });
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            setStatus({ totalCount: files.length, currentIndex: i + 1, currentFileUploading: file.name });
            try {
                let result = await uploadHandler({ productId, file });
                if (onUploaded) {
                    onUploaded(result.productImageId);
                }
            } catch (error) {
                console.warn("Failed to upload file:", file, error);
            }
        }
        setStatus(undefined);
    }, [uploadHandler]);

    return (
        <div className={clsx("add-product-image", className)}>
            {!status &&
                <DropZone onAccept={uploadImages} />}
            {status &&
                <UploadStatus status={status} />}
        </div>
    )
}

function DropZone({ onAccept }: { onAccept: (files: File[]) => void }) {

    const onDrop = useCallback((acceptedFiles: File[]) => {
        onAccept(acceptedFiles);
    }, [onAccept]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
            "image/gif": [".gif"],
            "image/webp": [".webp"],
            "image/avif": [".avif"],
        }
    })

    return (
        <div {...getRootProps()}
            className={clsx(
                "drop-zone d-flex align-items-center justify-content-center",
                isDragActive && "active"
            )}
            role="button">
            <input {...getInputProps()} />
            <BiImageAdd className="icon" />
            <div className="d-flex flex-column ms-3">
                <p className="mb-1">
                    Drag & drop or <span className="text-decoration-underline">choose an image</span> to upload.
                </p>
                <p className="m-0">
                    PNG, JPG, and GIF images are supported.
                </p>
            </div>
        </div>
    )
}

function UploadStatus({ status }: { status: Status }) {

    return (
        <div className="upload-status d-flex flex-column align-items-center justify-content-center">
            <p className="fs-3">
                Uploading image {status.currentIndex}/{status.totalCount}...
            </p>
            <div className="progress w-60 mb-2">
                <div className="progress-bar" role="progressbar" style={{
                    width: `${status.currentIndex / (status.totalCount) * 100}%`
                }} />
            </div>
            <p className="m-0 text-truncate w-80 text-center">
                {status.currentFileUploading}
            </p>
        </div>
    )
}
