import { useMemo } from "react";
import { useOrderContext } from "../provider/use-order-context";
import { CancellationInsuranceAddonProductId, HazardInsuranceAddonProductId } from "./line-items/well-known-items";

export function PartyOptionsHeader({ open }: { open: boolean }) {

    let { state: { order } } = useOrderContext();
    let state = useMemo(() => {
        return {
            hasSpecialInstructions: !!order?.specialInstructions,
            hasHazardInsurance: !!order?.lineItems.find(x => x.productId == HazardInsuranceAddonProductId),
            hasCancelationInsurance: !!order?.lineItems.find(x => x.productId == CancellationInsuranceAddonProductId),
        }
    }, [order?.lineItems, order?.specialInstructions]);
    let summary = useMemo(() => {
        return [
            state.hasSpecialInstructions ? "Noted Special Request" : "No Special Requests",
            state.hasHazardInsurance ? "With Hazard Insurance" : null,
            state.hasCancelationInsurance ? "With Cancelation Insurance" : null,
        ].filter(x => !!x).join(", ");
    }, [state.hasCancelationInsurance, state.hasHazardInsurance, state.hasSpecialInstructions]);

    return (
        <div className="d-flex flex-fill">
            <div className="d-flex flex-column">
                Party Options (Optional)
                {!open && (
                    <div className="text-muted d-flex">
                        {summary}
                    </div>
                )}
            </div>
        </div>
    )
}
