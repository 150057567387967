import "./animated-checkbox.scss";

import clsx from "clsx";
import { forwardRef } from "react";

export type AnimatedCheckboxProps = Omit<React.ComponentPropsWithoutRef<"input">, "type">;

export const AnimatedCheckbox = forwardRef<HTMLInputElement, AnimatedCheckboxProps>((props, ref) => (
    <input ref={ref} type="checkbox" {...props} className={clsx("animated-checkbox", props.className)} />
));

AnimatedCheckbox.displayName = "AnimatedCheckbox";
