import { useLocation } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { keyof } from "../../../common/utilities/key-of";
import { CustomErrorMessage } from "../../../common/forms/custom-error-message";
import { acceptInvite } from "../../../services/login/accept-invite";
import { LabelWithError } from "../../../common/forms/label-with-error";
import { useEffect, useState } from "react";
import { publicPaths } from "../public.routes";
import { FormLayout } from "../../../common/components/layouts/form-layout/form-layout";

const formText = {
    emailAddress: "Email Address",
    password: "Password",
    confirmPassword: "Confirm Password"
};

type AcceptInviteFormType = {
    root: unknown;
    emailAddress: string;
    password: string;
    confirmPassword: string;
};


export function AcceptInvite() {

    let location = useLocation();
    let fragment = location.hash;
    let result = parseFragment(fragment);

    return result.success
        ? <ValidInvite {...result} />
        : <InvalidInvite />
}

function InvalidInvite() {
    return (
        <FormLayout>
            <h1 className="text-center">Sorry</h1>
            <p className="text-center">It looks like your invite is invalid.</p>
        </FormLayout>
    );
}

function ValidInvite(props: ParseResult) {

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors, isValid },
        trigger,
        watch
    } = useForm<AcceptInviteFormType>({
        defaultValues: { emailAddress: props.emailAddress, password: "" },
        mode: "onChange"
    });

    let emailAddressProp = keyof<AcceptInviteFormType>("emailAddress");
    let passwordProp = keyof<AcceptInviteFormType>("password");
    let confirmPasswordProp = keyof<AcceptInviteFormType>("confirmPassword");

    let [success, setSuccess] = useState(false);

    const onSubmit: SubmitHandler<AcceptInviteFormType> = async (data) => {
        try {
            await acceptInvite({
                emailAddress: data.emailAddress,
                password: data.password,
                token: props.token!,
                notAfter: props.notAfter!,
                notBefore: props.notBefore!
            });
            setSuccess(true);
        } catch (error) {
            console.warn(error);
            setError("root", { message: "Failed to validate invite link." }, { shouldFocus: true });
        }
    };

    const password = watch(passwordProp);
    useEffect(() => {
        void trigger(confirmPasswordProp);
    }, [confirmPasswordProp, password, trigger]);

    if (success) {
        return (
            <FormLayout>
                <h1 className="text-center">You&apos;ve been invited!</h1>
                <div className="alert alert-success text-center" role="alert">
                    <p>Your login was activated.</p>
                    <p className="m-0">Welcome to Partyeezy!</p>
                </div>
                <a href={publicPaths.login} className="btn btn-outline-primary w-100">
                    Login
                </a>
            </FormLayout>
        )
    }

    return (
        <FormLayout>
            <h1 className="text-center">You&apos;ve been invited!</h1>
            <p className="text-center">To start using your Partyeezy account please set a password below.</p>
            <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column">
                <div className="form-floating mb-3">
                    <input
                        type="email"
                        disabled={true}
                        className="form-control"
                        placeholder={formText.emailAddress}
                        autoComplete="username"
                        {...register(emailAddressProp, { required: true })}
                    />
                    <LabelWithError errors={errors} name={emailAddressProp}>{formText.emailAddress}</LabelWithError>
                </div>

                <div className="form-floating mb-3">
                    <input
                        type="password"
                        className="form-control"
                        placeholder={formText.password}
                        autoComplete="new-password"
                        {...register(passwordProp, {
                            required: true,
                            validate: validatePassword
                        })}
                    />
                    <LabelWithError errors={errors} name={passwordProp}>{formText.password}</LabelWithError>
                </div>

                <div className="form-floating mb-3">
                    <input
                        type="password"
                        className="form-control"
                        placeholder={formText.confirmPassword}
                        autoComplete="new-password"
                        {...register(confirmPasswordProp, {
                            required: true,
                            validate: (x, other) => x == other.password || "Passwords must match.",
                            deps: [passwordProp]
                        })}
                    />
                    <LabelWithError errors={errors} name={confirmPasswordProp}>{formText.confirmPassword}</LabelWithError>
                </div>

                <div className="d-flex justify-content-center">
                    <CustomErrorMessage className="pb-2" showIcon name="root" errors={errors} />
                </div>

                <button className="btn btn-outline-primary" type="submit" disabled={!isValid}>
                    Accept Invite
                </button>
            </form>
        </FormLayout>
    );
}

type ParseResult = {
    emailAddress?: string,
    token?: string,
    notAfter?: number,
    notBefore?: number,
    success: boolean
}

function parseFragment(fragment?: string): ParseResult {

    let params = new URLSearchParams(fragment?.substring(1) ?? "");
    let result = {
        emailAddress: params.get("emailAddress")!,
        token: params.get("token")!,
        notAfter: parseInt(params.get("notAfter")!),
        notBefore: parseInt(params.get("notBefore")!),
    }

    return {
        ...result,
        success: !!result.emailAddress
            && !!result.token
            && !!result.notAfter
            && !!result.notBefore
    }
}

function validatePassword(password?: string): true | string {
    if (password == null) {
        return "Password must not be empty";
    }
    if (password.length < 6) {
        return "Password must be at least 6 characters";
    }
    return true;
}
