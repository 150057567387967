import queryString from "query-string";
import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type GetConversationsInput = {
    offset?: number;
    count?: number;
};

export const GetConversations: ApiHandlerWithAuth<GetConversationsInput, GetConversationsResult> = {
    handle: async (input, authHeader) => {
        let response = await fetch(`/api/v1/admin/messages?${queryString.stringify(input)}`, {
            method: "GET",
            headers: getDefaultHeaders(authHeader)
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as GetConversationsResult;
    }
}

export type GetConversationsResult = {
    results: Conversation[];
    hasMore: boolean;
};

export type CustomerMessageKind =
    | "unknown"
    | "fromApp"
    | "toApp";

export type Conversation = {
    conversation: string;
    totalMessagesCount: string;
    lastMessage: {
        messageId: string;
        conversation: string;
        kind: CustomerMessageKind;
        fromPhoneNumber: string;
        toPhoneNumber: string;
        price: number;
        body: string;
        errorMessage?: string;
        hasError: boolean;
        timeStamp: string;
    };
}
