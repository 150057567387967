import { Navigate, RouteObject } from "react-router-dom";
import { PublicLayout } from "../../common/components/layouts/public-layout/public-layout";
import { PlannerOrderStatus } from "./order-status/planner-order-status";
import { PlannerOrder } from "./ordering/planner-order";
import { PlannerPackages } from "./package-search/planner-packages";

export const plannerPaths = {
    packages: "/planner/packages",
    order: "/order/:orderId",
    orderByMenu: "/order/:orderId/:menu?",
    status: "/order/:orderId/status",
};

export const plannerRoutes: RouteObject[] = [
    {
        element: <PublicLayout />,
        children: [
            {
                path: plannerPaths.packages,
                element: <PlannerPackages />
            },
            {
                path: plannerPaths.order,
                element: <Navigate to="./line-items" replace />
            },
            {
                path: plannerPaths.orderByMenu,
                element: <PlannerOrder />
            },
            {
                path: plannerPaths.status,
                element: <PlannerOrderStatus />
            }
        ]
    }
];
