import { ColDef, IDatasource, IGetRowsParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useRef } from "react";
import { FaPerson } from "react-icons/fa6";
import { GiPartyHat } from "react-icons/gi";
import { Link } from "react-router-dom";
import { Conversation, CustomerMessageKind, GetConversations } from "../../../common/api/handlers/admin/messages/get-conversations";
import { useApiHandlerWithAuth } from "../../../common/api/use-api-handler-with-auth";
import { DataGrid } from "../../../common/utilities/data-grid";
import { FormatDateWithAgo } from "../../../common/utilities/formatters/format-date";

export function Conversations() {
    return (
        <div className="d-flex flex-fill">
            <ConversationsGrid />
        </div>
    )
}

export function ConversationsGrid() {

    let columns = useMemo<ColDef<Conversation>[]>(() => [
        { field: "lastMessage.kind", headerName: "", initialWidth: 50, cellRenderer: KindRenderer },
        { field: "conversation", headerName: "Conversation", initialWidth: 162, cellRenderer: ConversationLinkRenderer },
        { field: "totalMessagesCount", headerName: "Count", initialWidth: 82 },
        { field: "lastMessage.timeStamp", headerName: "Last Message Sent", cellRenderer: DateTimeRenderer, initialWidth: 226 },
        { field: "lastMessage.body", headerName: "Last Message", initialWidth: 650 },
    ], []);

    let handler = useApiHandlerWithAuth(GetConversations);

    let ref = useRef<AgGridReact>(null);

    let dataSource = useMemo<IDatasource>(() => {
        return {
            getRows: (params: IGetRowsParams) => {
                void (async () => {
                    try {
                        let result = await handler({
                            offset: params.startRow,
                            count: params.endRow - params.startRow
                        });
                        let lastIndex = result.hasMore ? undefined : params.startRow + result.results.length;
                        params.successCallback(result.results, lastIndex);
                    } catch (error) {
                        params.failCallback();
                    }
                })();
            }
        };
    }, [handler])

    return (
        <div className="flex-fill d-flex flex-column">
            <DataGrid columnDefs={columns}
                gridRef={ref}
                gridOptions={{ suppressCellFocus: true }}
                suppressColumnVirtualisation
                getRowId={x => x.data.conversation}
                rowModelType="infinite"
                datasource={dataSource}
                containerClassName="flex-fill" />
        </div>
    )
}

function ConversationLinkRenderer({ value }: { value?: string }) {
    return (
        <Link to={`./${value}`} className="text-dark">{value}</Link>
    )
}

function DateTimeRenderer({ value }: { value?: string }) {
    return (
        <FormatDateWithAgo dateTime={value} />
    )
}

function KindRenderer({ value }: { value?: CustomerMessageKind }) {
    if (value == "fromApp") {
        return <GiPartyHat className="text-muted" />
    }
    if (value == "toApp") {
        return <FaPerson />
    }
}
