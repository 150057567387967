import { useCallback, useEffect, useMemo, useState } from "react";
import { Money } from "../../../../../../common/utilities/formatters/money";
import { OrderLineItem } from "../../../../../../services/public/ordering/put-order-line-items";
import { useOrderContext } from "../../../provider/use-order-context";
import { SameDayPickupAddonProductId } from "../well-known-items";

type SameDayPickupAddonProps = {
    startOn: string;
    durationHours: number;
};

const enum AddOnEnum {
    SameDayPickup = "SameDayPickup",
    StandardPickup = "StandardPickup",
}

export function SameDayPickupAddon({ startOn, durationHours }: SameDayPickupAddonProps) {
    let [isLoading, setIsLoading] = useState(false);
    let { actions: { setLineItems }, state: { order } } = useOrderContext();
    let { lineItems } = order ?? { lineItems: undefined };

    const hasAddon = useMemo(() => {
        return !!lineItems?.find(x => x.productId == SameDayPickupAddonProductId);
    }, [lineItems]);

    const timeRange = useMemo(() => {
        const timeRange = [];
        if (startOn && durationHours) {
            const startOnDateTime = new Date(startOn);
            let startHours = startOnDateTime.getHours() + durationHours;
            startOnDateTime.setHours(startHours);
            const endDateTime = new Date(startOnDateTime);
            let endHours = endDateTime.getHours() + 4;
            endDateTime.setHours(endHours);

            while (startOnDateTime <= endDateTime) {
                const time = startOnDateTime.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                });
                timeRange.push(time);
                startOnDateTime.setMinutes(startOnDateTime.getMinutes() + 30);
            }
        }

        return timeRange;
    }, [durationHours, startOn]);

    let [sameDayPickupTime, setSameDayPickupTime] = useState<string>("");

    // Set initial state
    useEffect(() => {
        if (!lineItems || lineItems?.length == 0) {
            return;
        }

        const addOn = lineItems.find(x => x.productId == SameDayPickupAddonProductId);
        setSameDayPickupTime(addOn?.comment ?? timeRange[0]);
    }, [lineItems, timeRange]);

    // Update same day pick up time if startOn and durationHours changed
    useEffect(() => {
        if (hasAddon && startOn && durationHours > 0) {
            setSameDayPickupTime(timeRange[0]);
        }
    }, [startOn, durationHours, hasAddon, timeRange]);

    let handleChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        try {
            const exists = event.target.value == AddOnEnum.SameDayPickup as string;
            let updatedLineItems: OrderLineItem[] = [];
            if (!exists) {
                // Remove
                updatedLineItems = [...lineItems || []].filter(x => x.productId != SameDayPickupAddonProductId) ?? [];
            } else {
                // Add
                updatedLineItems = [...lineItems ?? []];
                if (!updatedLineItems.find(x => x.productId == SameDayPickupAddonProductId)) {
                    updatedLineItems.push({
                        productId: SameDayPickupAddonProductId,
                        count: 1,
                        comment: timeRange[0],
                    })
                }
            }
            updatedLineItems = updatedLineItems.map((lineItem) => {
                return {
                    productId: lineItem.productId,
                    count: lineItem.count,
                    childrenLineItems: lineItem.childrenLineItems,
                    comment: lineItem.comment,
                }
            });
            await setLineItems(updatedLineItems);
        } finally {
            setIsLoading(false);
        }
    }, [lineItems, setLineItems, timeRange]);

    let pricing = useMemo(
        () => lineItems?.find(x => x.productId == SameDayPickupAddonProductId)?.totalPrice ?? null,
        [lineItems]
    );

    const handleSameDayPickupSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const sameDayPickupTimeSelection = event.target.value;
        setSameDayPickupTime(sameDayPickupTimeSelection);
    };

    useEffect(() => {
        if (!hasAddon) {
            return;
        }

        const addOn = lineItems?.find(x => x.productId == SameDayPickupAddonProductId);
        if (sameDayPickupTime && sameDayPickupTime !== addOn?.comment) {
            const updatedLineItems = lineItems?.map((lineItem) => {
                let comment = lineItem.comment;
                if (lineItem.productId == SameDayPickupAddonProductId) {
                    comment = sameDayPickupTime;
                }
                return {
                    productId: lineItem.productId,
                    count: lineItem.count,
                    childrenLineItems: lineItem.childrenLineItems,
                    comment,
                };
            }) ?? [];
            void setLineItems(updatedLineItems);
        }
    }, [hasAddon, sameDayPickupTime, lineItems, setLineItems]);

    return (
        <div className="form-group">
            <div>
                Pickup Time (Rentals)
            </div>
            <div className="d-flex flex-column mt-2 p-3 border bg-white rounded">
                <div className="d-flex flex-column flex-md-row user-select-none">
                    <div className="form-check mb-2 me-3">
                        <input className="form-check-input"
                            type="radio"
                            name="pickup-time-radio"
                            id="standard-pickup"
                            checked={!hasAddon}
                            value={AddOnEnum.StandardPickup}
                            onChange={handleChange}
                            disabled={isLoading || !startOn || !durationHours} />
                        <label className="form-check-label" htmlFor="standard-pickup">
                            Standard pickup times are perfect!
                        </label>
                    </div>
                    <div className="form-check mb-2">
                        <input className="form-check-input"
                            type="radio"
                            name="pickup-time-radio"
                            id="same-day-pickup"
                            checked={hasAddon}
                            value={AddOnEnum.SameDayPickup}
                            onChange={handleChange}
                            disabled={isLoading || !startOn || !durationHours} />
                        <label className="form-check-label" htmlFor="same-day-pickup">
                            I need same-day pickup (+30%).
                        </label>
                    </div>
                    {!!pricing && (
                        <div className="ms-md-auto fw-semibold">
                            +<Money amount={pricing} />
                        </div>
                    )}
                </div>
                {hasAddon && (
                    <section className="row">
                        <div className="mt-3 col-12 col-md-4">
                            <div className="form-label">Same Day Pickup Time</div>
                            <select
                                className="form-select"
                                aria-label="Same Day Pickup Time"
                                value={sameDayPickupTime}
                                onChange={handleSameDayPickupSelection}
                                disabled={isLoading || !startOn || !durationHours}>
                                {timeRange.map((time) => (
                                    <option key={time} value={time}>{time}</option>
                                ))}
                            </select>
                        </div>
                    </section>
                )}
                <div className="text-muted mt-2">
                    <p className="mb-2">
                        <strong>We deliver between 7am-12pm.</strong> Pick up is the next day, unless same-day pick up is requested.
                    </p>
                </div>
            </div>
        </div>
    )
}
