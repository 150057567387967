export function pushIntoGTag(...params: unknown[]) {
    try {
        if (window) {
            let dataLayer = (window as unknown as { dataLayer?: unknown[] }).dataLayer;
            if (dataLayer) {
                dataLayer.push(params)
            }
        }
    } catch (error) {
        console.warn("Failed to push into the GTags data layer, it was likely blocked.", error);
    }
}
export function pushGTagEvent(eventName: string, params?: Record<string, string>) {
    pushIntoGTag("event", eventName, { ...params })
}
