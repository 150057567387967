import { useMemo } from "react";
import { FaMobileAlt } from "react-icons/fa";
import { FaPerson } from "react-icons/fa6";
import { MdEmail, MdMessage } from "react-icons/md";
import { LineItem } from "../../../../services/public/ordering/get-order-by-id";
import { useOrderContext } from "../provider/use-order-context";

type PaymentDetailsHeaderProps = {
    open: boolean;
    arrivalRequiringLineItems: LineItem[];
};

export function PaymentDetailsHeader({ open, arrivalRequiringLineItems }: PaymentDetailsHeaderProps) {
    let { state: { order } } = useOrderContext();
    let { contactDetails } = order ?? {
        contactDetails: undefined
    };

    let hasAllArrivalTimesSelected = useMemo(() => {
        return arrivalRequiringLineItems?.length > 0 ? arrivalRequiringLineItems?.every((lineItem) => lineItem.arrivalDateTime) : false;
    }, [arrivalRequiringLineItems]);


    return (
        <div className="d-flex flex-column justify-content-center">
            <div className="mt-1">
                Payment Information
            </div>
            {!open && hasAllArrivalTimesSelected && contactDetails && (
                <div className="row g-2 mt-2">
                    <div className="col-12 col-md-6 d-flex align-items-center">
                        <FaPerson className="me-2 text-primary" />
                        <span>{contactDetails?.name}</span>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center">
                        <MdEmail className="me-2 text-primary" />
                        <span>{contactDetails?.emailAddress}</span>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center">
                        <FaMobileAlt className="me-2 text-primary" />
                        <span>{contactDetails?.phoneNumber}</span>
                    </div>
                    <div className="col-12 col-md-6 d-flex align-items-center">
                        <MdMessage className="me-2 text-primary" />
                        <span>{contactDetails?.allowSmsNotifications ? "Receive Text Alerts" : "No Text Alerts"}</span>
                    </div>

                </div>
            )}
        </div>
    )
}
