import { HttpMethodEnum } from "../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../common/utilities/get-auth-request-header";

export type GetFrontendConfigurationResponse = {
    version: string;
    environment: string;
    isDemo?: boolean;
    payments: {
        enabled: boolean;
        isProduction: boolean;
        stripePublishableApiKey: string;
        stripeClientId: string;
    }
};

export const GetFrontendConfiguration = async (signal?: AbortSignal): Promise<GetFrontendConfigurationResponse> => {
    let response: Response = await fetch("/api/v1/configuration", {
        signal,
        method: HttpMethodEnum.GET,
        headers: getAuthRequestHeaders()
    });

    if (!response?.ok) {
        throw response;
    }

    return await response.json() as GetFrontendConfigurationResponse;
};
