import { ColDef, IDatasource, IGetRowsParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import clsx from "clsx";
import { useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { GetPromotionCodes, PromotionCode } from "../../../common/api/handlers/admin/promotion-codes/get-promotion-codes";
import { useApiHandlerWithAuth } from "../../../common/api/use-api-handler-with-auth";
import { DataGrid } from "../../../common/utilities/data-grid";

export function PromotionCodesList() {

    let columns = useMemo<ColDef<PromotionCode>[]>(() => [
        { field: "code", headerName: "Code", cellRenderer: PromotionCodeRenderer },
        { field: "discount", headerName: "Discount", cellRenderer: DiscountRenderer },
        { field: "notes", headerName: "Notes", flex: 1 },
    ], []);

    let handler = useApiHandlerWithAuth(GetPromotionCodes);

    let ref = useRef<AgGridReact>(null);

    let dataSource = useMemo<IDatasource>(() => {
        return {
            getRows: (params: IGetRowsParams) => {
                void (async () => {
                    try {
                        let result = await handler({
                            offset: params.startRow,
                            count: params.endRow - params.startRow
                        });
                        let lastIndex = result.hasMore ? undefined : params.startRow + result.results.length;
                        params.successCallback(result.results, lastIndex);
                    } catch (error) {
                        params.failCallback();
                    }
                })();
            }
        };
    }, [handler])

    return (
        <div className="flex-fill d-flex flex-column">
            <DataGrid columnDefs={columns}
                gridRef={ref}
                gridOptions={{ suppressCellFocus: true }}
                suppressColumnVirtualisation
                getRowId={x => x.data.promotionCodeId}
                rowModelType="infinite"
                datasource={dataSource}
                containerClassName="flex-fill" />
        </div>
    )
}

function PromotionCodeRenderer({ value, data }: { value?: string, data?: PromotionCode }) {
    return (
        <Link to={`./${data?.promotionCodeId}/edit`} className={clsx("text-uppercase", !!data?.isDisabled && "text-decoration-line-through text-muted")}>
            {value ?? "-"}
        </Link>
    )
}

function DiscountRenderer({ value }: { value?: number }) {
    return (
        <div>
            {
                value == null
                    ? "-"
                    : `${Math.abs(value) * 100}% Off`
            }
        </div>
    )
}
