import { HttpMethodEnum } from "../../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../../common/utilities/get-auth-request-header";
import { GetProductKind } from "./get-products";

export type OrderState =
  | "created"
  | "paymentPending"
  | "paymentIssue"
  | "paymentConfirmed"
  | "canceled"
  | "fulfilled";

export type GetOrderByIdResponse = {
  packageDetails: PackageDetails;
  additionalFees: AdditionalFee[];
  billingAddress: Address;
  contactDetails: ContactDetails;
  eventAddress: EventAddress;
  eventSchedule: EventSchedule;
  lineItems: LineItem[];
  orderId: string;
  humanReadableOrderId: number;
  specialInstructions: string | null;
  lineItemsPriceExcludingTaxes: number;
  addOnPriceExcludingTaxes: number;
  totalPriceExcludingTaxes: number;
  totalPriceIncludingTaxes?: number;
  totalTaxes?: number;
  state: OrderState;
  promotionCode: PromotionCode;
};

export type PackageDetails = {
  headCount: number;
  partyTypeId: string;
  partyTypeName: string;
};

export type AdditionalFee = {
  name: string;
  totalPrice: number;
  comment?: string;
};

export type ContactDetails = {
  name: string;
  emailAddress: string;
  phoneNumber: string;
  allowSmsNotifications: boolean;
};

export type EventSchedule = {
  startOn: string;
  durationHours: number;
};

export type EventAddress = Address & {
  locationTypeId: string;
  locationTypeDescription: string;
  setupTypeId: string;
  setupTypeDescription: string;
};

export type Address = {
  streetLine1: string;
  streetLine2: string;
  city: string;
  state: string;
  county: string;
  zipCode: string;
};

export type ChildrenLineItem = Omit<LineItem, "childrenLineItems">;

export type LineItem = {
  lineItemId: string;
  arrivalDateTime: string;
  arrivalTimeRequired: boolean;
  childrenLineItems: ChildrenLineItem[];
  count: number;
  kind: GetProductKind;
  primaryImageLink: string;
  productDescription: string;
  productId: string;
  productName: string;
  productOptionType: string;
  productUnit: string;
  productUnitPluralized: string;
  sortOrder: number;
  setupTimeMinutes?: number;
  teardownTimeMinutes?: number;
  totalPrice: number;
  comment?: string;
};

export type PromotionCode = {
  code?: string;
  isValid: boolean;
};

export const getOrderById = async (
  orderId: string,
  signal?: AbortSignal
): Promise<GetOrderByIdResponse> => {
  const url = `/api/v1/orders/${orderId}`;
  let response: Response = await fetch(`${url}`, {
    signal,
    method: HttpMethodEnum.GET,
    headers: getAuthRequestHeaders(),
  });

  if (!response?.ok) {
    throw response;
  }

  return (await response.json()) as GetOrderByIdResponse;
};
