import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type CreateProductDraftInput = {
    productName: string;
    hasPricingTiers: boolean;
};

export const CreateProductDraftCommand: ApiHandlerWithAuth<CreateProductDraftInput, CreateProductDraftResult> = {
    handle: async ({ productName, hasPricingTiers }, authHeader) => {
        let response = await fetch("/api/v1/admin/products", {
            method: "POST",
            headers: getDefaultHeaders(authHeader),
            body: JSON.stringify({
                productName: productName.trim(),
                hasPricingTiers
            })
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as CreateProductDraftResult;
    }
}

export type CreateProductDraftResult = {
    productId: string;
}
