import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { distinct } from "../../../../common/utilities/collection-helpers";
import { LineItem } from "../../../../services/public/ordering/get-order-by-id";
import { useOrderContext } from "../provider/use-order-context";
import { ServicesArrivalTimeCard } from "./services-arrival-time-card";

type ServicesArrivalTimeSectionProps = {
    next: () => void;
    arrivalRequiringLineItems: LineItem[];
};

export const ServicesArrivalTimeSection = ({ next, arrivalRequiringLineItems }: ServicesArrivalTimeSectionProps) => {

    const { state: { order }, actions: { setLineItems } } = useOrderContext();
    const { eventSchedule, lineItems } = order ?? { eventSchedule: undefined, lineItems: undefined };

    const [updatedLineItems, setUpdatedLineItems] = useState<LineItem[]>([]);
    useEffect(() => {
        setUpdatedLineItems(arrivalRequiringLineItems ?? []);
    }, [arrivalRequiringLineItems])

    let timeRange = useTimeRange(eventSchedule?.startOn, eventSchedule?.durationHours);

    const handleSubmit = async () => {
        try {
            let newLineItems = distinct(updatedLineItems.concat(lineItems ?? []), (x, y) => x.lineItemId == y.lineItemId)
            await setLineItems(newLineItems);
            next();
        } catch (error) {
            console.error(error);
        }
    };

    const handleArrivalTimeSelection = useCallback((lineItemId: string, arrivalDateTime: string) => {
        let newList = updatedLineItems.map(lineItem => {
            if (lineItem.lineItemId == lineItemId) {
                return {
                    ...lineItem,
                    arrivalDateTime,
                };
            }
            return lineItem;
        });
        setUpdatedLineItems(newList);
    }, [updatedLineItems]);

    const hasAllArrivalTimesSelected = useMemo(() => updatedLineItems.every(x => x.arrivalTimeRequired && !!x.arrivalDateTime), [updatedLineItems]);

    return (
        <>
            <h6 className="mb-3">When would you like your services to start?</h6>
            <ul className="mx-n3 mt-n3" style={{ listStyleType: "none", paddingInlineStart: 0 }}>
                {updatedLineItems
                    .toSorted((a, b) => a.productName < b.productName ? -1 : 1)
                    .map((lineItem) => (
                        <ServicesArrivalTimeCard
                            key={lineItem.productId}
                            lineItem={lineItem}
                            timeRange={timeRange}
                            onArrivalTimeSelection={handleArrivalTimeSelection} />
                    ))}
            </ul>
            <footer className="d-flex justify-content-end">
                <button type="button"
                    className="btn btn-outline-primary"
                    disabled={!hasAllArrivalTimesSelected}
                    onClick={handleSubmit}>
                    Save and Continue
                </button>
            </footer>
        </>
    );
};

function useTimeRange(startOn?: string, durationHours?: number) {
    const timeRange = useMemo(() => {
        const timeRange: string[] = [];
        if (startOn && durationHours) {

            let startRange = DateTime.fromISO(startOn);
            let endRange = startRange.plus({ hours: durationHours });
            let currentTime = startRange;

            while (currentTime <= endRange) {
                timeRange.push(currentTime.toISO()!);
                currentTime = currentTime.plus({ minutes: 30 })
            }
        }
        return timeRange;
    }, [durationHours, startOn]);
    return timeRange;
}
