import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type GetVendorSurveysInput = object;

export const GetVendorSurveysQuery: ApiHandlerWithAuth<GetVendorSurveysInput, GetVendorSurveysResult> = {
    handle: async (_, authHeader) => {
        let response = await fetch("/api/v1/surveys/vendor", {
            method: "GET",
            headers: getDefaultHeaders(authHeader)
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as GetVendorSurveysResult;
    }
}

export type GetVendorSurveysResult = {
    id: string;
    timestamp: string;
    fullName: string;
    businessName: string;
    emailAddress: string;
    phoneNumber: string;
    zipCode: string;
    providedServices: string[];
    otherProvidedService: string;
    businessGoals: string[];
    otherBusinessGoal: string;
}[];
