import "./landing-header.scss";

import clsx from "clsx";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Assets } from "../../assets/references";
import { useFrontendConfiguration } from "../../common/providers/frontend-configuration/use-frontend-configuration";
import { publicPaths } from "../public/public.routes";

export type SelectableSection = {
    section: React.RefObject<HTMLElement | undefined>;
    isInView: boolean;
}


export const LandingHeader = () => {

    let { configuration } = useFrontendConfiguration();
    let isDemoMode = configuration?.isDemo ?? false;

    return (
        <>
            <div className="text-center p-1 text-uppercase message bg-dark text-light">
                {isDemoMode
                    ? (
                        <span className="fw-bold">Demo Mode</span>
                    )
                    : (
                        <span>Now serving Southern California, Los Angeles County, Orange County, and San Bernardino County</span>
                    )}
            </div>
            <Navbar expand="lg" collapseOnSelect className="landing-header p-3 z-3" style={{ minHeight: 76 }}>
                <div className="container-fluid px-xxl-0">
                    <Link to={publicPaths.landing} className="navbar-brand">
                        <img className="logo-img" src={Assets.LogoBlackUrl.toString()} alt="Partyeezy" />
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mt-2 mt-lg-1 d-flex flex-fill">
                            <LandingHeaderLink text="How it works"
                                href="#how-it-works" />
                            <LandingHeaderLink text="Party Now, Pay Later"
                                href="#party-now-pay-later" />
                            <LandingHeaderLink text="Our promise"
                                href="#our-promise" />
                            <LandingHeaderLink text="Become a partner"
                                href="#become-a-partner"
                                className="ms-lg-auto" />
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </>
    );
};

type LandingHeaderLinkProps = {
    text: string;
    href: string;
    className?: string;
}

function LandingHeaderLink({ text, href, className }: LandingHeaderLinkProps) {
    return (
        <Nav.Link className={clsx("text-black text-uppercase", className)}
            href={href}>
            {text}
        </Nav.Link>
    )
}
