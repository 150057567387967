import clsx from "clsx";
import Select from "react-select";
import classes from "./bootstrap-select.module.scss";

export const BootstrapSyncSelect: Select = (props) => {
    return (
        <div className={clsx("form-control", classes["bootstrap-async-select"])} style={{
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            paddingLeft: 0,
            height: "100%"
        }}>
            <Select
                styles={{
                    container: (base) => ({
                        ...base,
                        minWidth: "250px"
                    }),
                    option: (base, { isDisabled, isFocused, isSelected }) => ({
                        ...base,
                        backgroundColor: isSelected
                            ? "var(--bs-primary)"
                            : isFocused
                                ? "var(--bs-secondary-bg)"
                                : "transparent",
                        color: isDisabled
                            ? "var(--bs-black)"
                            : isSelected
                                ? "var(--bs-white)"
                                : "inherit",
                        ":active": {
                            backgroundColor: !isDisabled
                                ? isSelected
                                    ? "var(--bs-primary)"
                                    : "var(--bs-primary-border-subtle)"
                                : undefined,
                        },
                    }),
                    control: (base, state) => ({
                        ...base,
                        border: "0",
                        backgroundColor: "transparent",
                        alignItems: "end",
                        borderColor: state.isFocused ? "var(--focus-border-color)" : "",
                        outline: state.isFocused ? 0 : "",
                        boxShadow: state.isFocused ? "var(--focus-shadow)" : "",
                        // minHeight: "calc(3.5rem + calc(var(--bs-border-width) * 2))",
                    }),
                    indicatorsContainer: (base) => ({
                        ...base,
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        // display: "none"
                    }),
                    indicatorSeparator: (base) => ({
                        ...base,
                        display: "none"
                    }),
                    clearIndicator: (base) => ({
                        ...base,
                        marginRight: "1.4rem",
                        color: "#7f7f7f"
                    }),
                    loadingIndicator: (base) => ({
                        ...base,
                        marginRight: "2.4rem",
                        color: "#7f7f7f"
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        // padding: "calc(0.375rem + 1rem) 2.25rem 0.375rem 0.75rem" // Matching bootstrap, not sure the var.
                    }),
                    menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999
                    }),
                }}
                {...props}
            />
        </div>
    );
}
