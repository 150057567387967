import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type GetOrderByIdInput = {
    orderId: string;
};

export const GetOrderById: ApiHandlerWithAuth<GetOrderByIdInput, GetOrderByIdResult> = {
    handle: async (input, authHeader) => {
        let response = await fetch(`/api/v1/admin/orders/${input.orderId}`, {
            method: "GET",
            headers: getDefaultHeaders(authHeader)
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as GetOrderByIdResult;
    }
}

export type GetOrderByIdResult = Order;

export type OrderState =
    | "unknown"
    | "paymentPending"
    | "paymentIssue"
    | "paymentConfirmed"
    | "canceled"
    | "fulfilled";

export type LineItemState =
    | "unknown"
    | "pending"
    | "vendorAssigned"
    | "vendorConfirmed"
    | "paymentConfirmed"
    | "fulfilled";

export type OrderPaymentState =
    | "unknown"
    | "created"
    | "processing"
    | "failed"
    | "succeeded"
    | "canceled"
    | "requiresAction";

export type Order = {
    orderId: string;
    humanReadableOrderId: number;
    lineItemsPriceExcludingTaxes: number;
    addOnPriceExcludingTaxes: number;
    totalPriceExcludingTaxes: number;
    totalPriceIncludingTaxes: number;
    totalTaxes: number;
    specialInstructions?: string;
    state: OrderState;
    packageDetails: {
        partyTypeId: string;
        partyTypeName: string;
        headCount: number;
    };
    contactDetails: {
        name: string;
        emailAddress: string;
        phoneNumber: string;
        allowSmsNotifications: boolean;
    };
    eventAddress: {
        streetLine1: string;
        streetLine2: string;
        city: string;
        state: string;
        county: string;
        zipCode: string;
        locationTypeId: string;
        locationTypeDescription: string;
        setupTypeId: string;
        setupTypeDescription: string;
    };
    eventSchedule: {
        startOn: string;
        durationHours: number;
    };
    billingAddress: {
        streetLine1: string;
        streetLine2: string;
        city: string;
        state: string;
        county: string;
        zipCode: string;
    };
    paymentIntent: {
        stripePaymentIntentId: string;
        stripeTaxCalculationId: string;
        amount: number;
        state: OrderPaymentState;
        errorCode?: string;
        declineCodeCode?: string;
        errorMessage?: string;
        createdOn: string;
    };
    lineItems: {
        [state in LineItemState | "total"]: number | undefined;
    },
    promotionCode: {
        code?: string;
        isValid: boolean;
    };
}
