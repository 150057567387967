import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type IsProductNameAvailableInput = {
    productName: string;
};

export const IsProductNameAvailableQuery: ApiHandlerWithAuth<IsProductNameAvailableInput, IsProductNameAvailableQueryResult> = {
    handle: async ({ productName }, authHeader) => {
        let response = await fetch(`/api/v1/admin/products/name-available/${productName.trim()}`, {
            method: "GET",
            headers: getDefaultHeaders(authHeader)
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as IsProductNameAvailableQueryResult;
    }
}

export type IsProductNameAvailableQueryResult = {
    available: boolean
}
