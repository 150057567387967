import { HttpMethodEnum } from "../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../common/utilities/get-auth-request-header";
import { ServicesConfig } from "../config";

export type PlannerSurveyRequest = {
    fullName: string;
    emailAddress: string;
    phoneNumber: string;
    zipCode: string;
};

export const postPlannerSurvey = async (request: PlannerSurveyRequest, signal?: AbortSignal): Promise<void> => {
    const url = `${ServicesConfig.surveys}/planner`;
    let response: Response = await fetch(`${url}`, {
        signal,
        method: HttpMethodEnum.POST,
        body: JSON.stringify(request),
        headers: getAuthRequestHeaders()
    });

    if (!response?.ok) {
        throw response;
    }
};
