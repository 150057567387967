export type OrderState =
    | "unknown"
    | "created"
    | "paymentPending"
    | "paymentIssue"
    | "paymentConfirmed"
    | "canceled"
    | "fulfilled";

export type FormatOrderStateProps = {
    state?: OrderState;
}

export function FormatOrderState({ state }: FormatOrderStateProps) {
    switch (state) {
        case "canceled":
            return <div className="badge text-bg-secondary">Canceled</div>
        case "fulfilled":
            return <div className="badge text-bg-secondary">Fulfilled</div>
        case "paymentConfirmed":
            return <div className="badge text-bg-success">Payment Confirmed</div>
        case "paymentIssue":
            return <div className="badge text-bg-warning">Payment Issue</div>
        case "paymentPending":
            return <div className="badge text-bg-secondary">Payment Pending</div>
        default:
            return <>{state ?? "-"}</>
    }
}
