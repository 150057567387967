import clsx from "clsx";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { GetMessagesByConversation, Message } from "../../../common/api/handlers/admin/messages/get-messages-by-conversation";
import { SendMessage } from "../../../common/api/handlers/admin/messages/send-message";
import { useApiHandlerWithAuth } from "../../../common/api/use-api-handler-with-auth";
import { FormatDateWithAgo } from "../../../common/utilities/formatters/format-date";

export function MessagesByConversation() {
    let { conversation } = useParams();
    if (!conversation) {
        throw "Conversation is falsy, this shouldn't be possible."
    }
    return <MessagesByConversationImpl conversation={conversation} />
}

type MessagesByConversationImpl = {
    conversation: string;
}

function MessagesByConversationImpl({ conversation }: MessagesByConversationImpl) {

    let getMessagesHandler = useApiHandlerWithAuth(GetMessagesByConversation);
    let [messages, setMessages] = useState<Message[]>([]);

    let appendMessages = useCallback((messages: Message[]) => {
        setMessages(x => {
            let newArray = [...x];
            for (const message of messages) {
                if (!newArray.find(x => x.messageId == message.messageId)) {
                    newArray.push(message);
                }
            }
            return newArray;
        })
    }, []);

    useEffect(() => {
        void (async () => {
            let offset = 0;
            let result;
            do {
                result = await getMessagesHandler({ conversation, offset });
                offset += result.results.length;
                appendMessages(result.results);
            } while (result.hasMore);
        })();
    }, [appendMessages, conversation, getMessagesHandler]);

    let sortedMessages = useMemo(
        () => messages.toSorted((a, b) => DateTime.fromISO(a.timeStamp) > DateTime.fromISO(b.timeStamp) ? 1 : -1),
        [messages]
    );

    useEffect(() => {
        if (sortedMessages.length > 1) {
            let interval = setInterval(async () => {
                let lastMessage = sortedMessages[sortedMessages.length - 1];
                let result = await getMessagesHandler({ conversation, since: lastMessage.timeStamp });
                if (result.results.length > 1) {
                    // Avoid modifying state, since "since" is inclusive, we'll always get one result.
                    appendMessages(result.results);
                }
            }, 3 * 1000);
            return () => clearInterval(interval);
        }
    }, [appendMessages, conversation, getMessagesHandler, sortedMessages]);

    let scrollRef = useRef<HTMLDivElement>(null)
    let scrollBottom = useCallback(() => {
        let element = scrollRef.current;
        if (element) {
            element.scrollTo({
                behavior: "smooth",
                top: element.scrollHeight
            })
        }
    }, []);

    useEffect(() => scrollBottom(), [scrollBottom, sortedMessages]);

    return (
        <div className="d-flex flex-column flex-fill">
            <div className="bg-white border rounded w-100 d-flex flex-column flex-fill" style={{ maxWidth: 600 }}>
                <div className="border-bottom p-3 text-center">
                    <a href={`tel:${conversation}`}>{conversation}</a>
                </div>
                <div className="position-relative flex-fill">
                    <div className="position-absolute top-0 bottom-0 start-0 end-0">
                        <div className="h-100 overflow-y-scroll px-3 d-flex flex-column" ref={scrollRef}>
                            <div className="mb-auto">{/* Spacer */}</div>
                            {sortedMessages.map(x => (
                                <div key={x.messageId} className="mb-3 d-flex flex-column">
                                    <MessageRow message={x} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="d-flex p-3 border-top">
                    <Composer conversation={conversation} />
                </div>
            </div>
        </div>
    )
}

function MessageRow({ message }: { message: Message }) {

    if (message.kind == "fromApp") {
        return (
            <div className="d-flex flex-column border-end border-primary pe-2">
                <div className={clsx("text-end", message.hasError ? "text-danger" : "text-muted")}>
                    {message.body}
                </div>
                <div className="text-end text-muted mt-1" style={{ fontSize: "0.7rem" }}>
                    <FormatDateWithAgo dateTime={message.timeStamp} />
                    <span className="mx-1">•</span>
                    ${message.price * -1}
                    <span className="mx-1">•</span>
                    {message.fromPhoneNumber}
                </div>
            </div>
        )
    }

    if (message.kind == "toApp") {
        return (
            <div className="d-flex flex-column border-start border-success ps-2">
                <div className="text-start">
                    {message.body}
                </div>
                <div className="text-start text-muted mt-1" style={{ fontSize: "0.7rem" }}>
                    <FormatDateWithAgo dateTime={message.timeStamp} />
                    <span className="mx-1">•</span>
                    ${message.price * -1}
                    <span className="mx-1">•</span>
                    {message.fromPhoneNumber}
                </div>
            </div>
        )
    }

    return (
        <>Error rendering this message.</>
    )
}

function Composer({ conversation }: { conversation: string }) {

    let [loading, setLoading] = useState(false);
    let [message, setMessage] = useState<string>();

    let handler = useApiHandlerWithAuth(SendMessage);
    let sendMessage = useCallback(async () => {
        setLoading(true);
        if (message) {
            await handler({ conversation, message });
            setMessage("");
        }
        setLoading(false);
    }, [conversation, handler, message]);

    return (
        <>
            <input type="text"
                className="form-control"
                placeholder={`Message for ${conversation}`}
                value={message}
                disabled={loading}
                onKeyDown={e => {
                    if (e.key == "Enter") {
                        void sendMessage()
                    }
                }}
                onChange={e => setMessage(e.target.value)} />
            <button className="btn btn-primary ms-3"
                onClick={sendMessage}
                disabled={loading}>Send</button>
        </>
    )
}
