import "./public-header.scss";

import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Assets } from "../../../../assets/references";
import { publicPaths } from "../../../../pages/public/public.routes";

export const PublicHeader = () => {
  return (
    <Navbar expand="lg" collapseOnSelect className="public-header p-3">
      <div className="container-fluid">
        <Link to={publicPaths.landing} className="navbar-brand">
          <img className="logo-img" src={Assets.LogoBlackUrl.toString()} alt="Partyeezy" />
        </Link>
      </div>
    </Navbar>
  );
};
