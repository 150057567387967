import { useForm } from "react-hook-form";
import { IsProductNameAvailableQuery } from "../../../common/api/handlers/admin/products/is-product-name-available";
import { useApiHandlerWithAuth } from "../../../common/api/use-api-handler-with-auth";
import { LabelWithError } from "../../../common/forms/label-with-error";
import { CustomErrorMessage } from "../../../common/forms/custom-error-message";
import { CreateProductDraftCommand } from "../../../common/api/handlers/admin/products/create-draft-product";
import { useNavigate } from "react-router-dom";
import { productPaths } from "./products";

type CreateProductForm = {
    root: never;
    productName: string;
    hasPricingTiers: boolean;
};

export function AdminProductsCreatePage() {

    let navigator = useNavigate();
    let isNameAvailableHandler = useApiHandlerWithAuth(IsProductNameAvailableQuery);
    let createDraftHandler = useApiHandlerWithAuth(CreateProductDraftCommand);

    let {
        register,
        handleSubmit,
        formState: { errors, isValidating, isValid, isSubmitting },
        setError
    } = useForm<CreateProductForm>({
        mode: "onChange",
        defaultValues: {
            productName: "",
            hasPricingTiers: false
        }
    });

    let nameValidator = async (productName: string) => {
        let result = await isNameAvailableHandler({ productName });
        if (result?.available) {
            return true;
        }
        return "Product name is already in use";
    };

    let submit = async (data: CreateProductForm) => {
        try {
            let { productId } = await createDraftHandler(data);
            navigator(productPaths.edit.replace(":productId", productId));
        } catch (error) {
            setError("root", {
                message: "Failed to create product."
            });
        }
    };

    return (
        <form className="row" onSubmit={handleSubmit(submit)}>
            <div className="col-6 row">
                <div className="col-12">
                    Let&apos;s create that product! We&apos;ll need a name first. Afterwards, we&apos;ll be able
                    to edit the product&apos;s details.
                </div>
                <div className="col-12 mt-3">
                    <div className="form-floating">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Example Name"
                            {...register("productName", {
                                validate: nameValidator,
                                required: true
                            })}
                        />
                        <LabelWithError name="productName" errors={errors}>
                            Product Name
                        </LabelWithError>
                    </div>
                </div>
                <div className="col-12 mt-1">
                    The product name is what vendors will see. It&apos;ll also help you organize many products.
                    The product name must be unique.
                </div>
                <div className="col-12 mt-3">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" {...register("hasPricingTiers")} />
                        <label className="form-check-label" htmlFor="hasPricingTiers">
                            This product has pricing tiers
                        </label>
                    </div>
                </div>
                <div className="col-12 mt-1">
                    If the product has pricing tiers like
                    <ul className="mb-0">
                        <li>5-10 people for 1 hour is $20.00</li>
                        <li>10-20 people for 1 hour is $50.00</li>
                    </ul>then select this option.
                </div>
                <footer className="col-12 d-flex mt-3">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting || isValidating || !isValid}
                    >
                        Create Draft Product
                    </button>
                </footer>
                <div className="col-12 mt-3">
                    <CustomErrorMessage name="root" errors={errors} />
                </div>
            </div>
        </form>
    );
}
