import { HttpMethodEnum } from "../../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../../common/utilities/get-auth-request-header";

export type PostOrdersRequest = {
    headCount: number;
    partyTypeId: string;
};

export type PostOrdersResponse = {
    orderId: string;
};

export const postOrders = async (request: PostOrdersRequest, signal?: AbortSignal): Promise<PostOrdersResponse> => {
    const url = "/api/v1/orders";
    let response: Response = await fetch(`${url}`, {
        signal,
        method: HttpMethodEnum.POST,
        body: JSON.stringify(request),
        headers: getAuthRequestHeaders()
    });

    if (!response?.ok) {
        throw response;
    }

    return await response.json() as PostOrdersResponse;
};
