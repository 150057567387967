import { BannerFooter } from "../../../common/utilities/banner-footer";
import { Footer } from "../../../common/utilities/footer";
import { FaqSection } from "./faq-section";

export function FaqPage() {
    return (
        <main>
            <section className="d-flex justify-content-center my-5">
                <div className="container d-flex flex-column">
                    <h1 className="text-center my-3">FAQ</h1>
                    <p className="text-center mb-3">
                        We at Partyeezy want you to just party. Here&apos;s a list of commonly asked questions. Not answered here? We are always available, <a href="mailto:hello@partyeezy.com">contact us</a>.
                    </p>
                    <FaqSection />
                </div>
            </section>
            <BannerFooter className="col-12 p-0" />
            <Footer />
        </main>
    )
}
