import { HttpMethodEnum } from "../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../common/utilities/get-auth-request-header";
import { ServicesConfig } from "../config";

type VendorSurveyRequest = {
    fullName: string;
    businessName: string;
    emailAddress: string;
    phoneNumber: string;
    zipCode: string;
    providedServices: string[];
    otherProvidedService: string;
    businessGoals: string[];
    otherBusinessGoal: string;
};

export const postVendorSurvey = async (request: VendorSurveyRequest, signal?: AbortSignal): Promise<void> => {
    const url = `${ServicesConfig.surveys}/vendor`;
    let response: Response = await fetch(`${url}`, {
        signal,
        method: HttpMethodEnum.POST,
        body: JSON.stringify(request),
        headers: getAuthRequestHeaders()
    });

    if (!response?.ok) {
        throw response;
    }
};
