import "./counter.scss";

import clsx from "clsx";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { ReactIcon } from "../../../../../common/components/react-icon/react-icon";

type CounterProps = {
    value: number;
    onChange: (count: number, signal?: AbortSignal) => Promise<void> | void;
    disabled?: boolean;
    className?: string;
    counterLowerLimit?: number;
    unit?: string;
    unitPlural?: string;
};

export const Counter = ({ value, disabled = false, onChange, className, counterLowerLimit = 1, unit, unitPlural }: CounterProps) => {

    const handleChange = useCallback((newValue: number) => {
        if (value != newValue) {
            void onChange(Math.max(counterLowerLimit, newValue));
        }
    }, [counterLowerLimit, onChange, value]);

    const handleIncrement = useCallback(() => {
        void handleChange(value + 1);
    }, [handleChange, value]);

    const handleDecrement = useCallback(() => {
        void handleChange(value - 1);
    }, [handleChange, value]);

    let [isValid, setIsValid] = useState(true);
    let [valueStr, setValueStr] = useState<string>(() => "" + value);

    let handleOnChange = useCallback(() => {
        let parsedValue = Number.parseInt(valueStr.trim());
        if (isNaN(parsedValue)) {
            setValueStr("" + counterLowerLimit);
            handleChange(counterLowerLimit);
        } else {
            handleChange(parsedValue);
        }
    }, [counterLowerLimit, handleChange, valueStr]);

    useEffect(() => {
        setValueStr("" + value);
        setIsValid(true);
    }, [value]);

    useEffect(() => {
        if (valueStr) {
            let parsedValue = Number.parseInt(valueStr.trim());
            if (!isNaN(parsedValue)) {
                setIsValid(true);
                return;
            }
        }
        setIsValid(false);
    }, [valueStr]);

    let inputRef = useRef<HTMLInputElement>(null);
    const handleEnter = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            inputRef.current?.blur();
        }
    }, []);
    const handleFocus = useCallback(() => {
        inputRef.current?.select();
    }, []);

    const displayUnit = useMemo(() => {
        if (!unit && !unitPlural) {
            return;
        }

        let parsedValue = Number.parseInt(valueStr.trim());
        if (isNaN(parsedValue)) {
            return;
        }

        return parsedValue > 1 ? unitPlural : unit;
    }, [unit, unitPlural, valueStr]);

    return (
        <section className="d-flex flex-column align-items-center me-2">
            <div className={clsx("px-1 d-flex align-items-center counter", className)}>
                <span onClick={handleDecrement}>
                    <ReactIcon icon={FiMinusCircle} className={clsx("fs-4 count-button", (value <= counterLowerLimit || disabled) && "disabled")} />
                </span>
                <input
                    type="number"
                    className={clsx("form-control p-1 mx-1 count-input", !isValid && "border border-danger")}
                    value={valueStr}
                    onChange={e => setValueStr(e.target.value)}
                    onKeyDown={handleEnter}
                    onBlur={handleOnChange}
                    onFocus={handleFocus}
                    ref={inputRef}
                />
                <span onClick={handleIncrement}>
                    <ReactIcon icon={FiPlusCircle} className={clsx("fs-4 count-button", disabled && "disabled")} />
                </span>
            </div>
            {displayUnit && (
                <span className="counter-unit text-muted">{displayUnit}</span>
            )}
        </section>
    )
}
