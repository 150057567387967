import { HttpMethodEnum } from "../../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../../common/utilities/get-auth-request-header";

export type PutOrderEventScheduleRequest = {
    startOn: string;
    durationHours: number;
};

export const putOrderEventSchedule = async (orderId: string, request: PutOrderEventScheduleRequest, signal?: AbortSignal): Promise<void> => {
    const url = `/api/v1/orders/${orderId}/event-schedule`;
    let response: Response = await fetch(`${url}`, {
        signal,
        method: HttpMethodEnum.PUT,
        body: JSON.stringify(request),
        headers: getAuthRequestHeaders()
    });

    if (!response?.ok) {
        throw response;
    }
};
