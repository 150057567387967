import queryString from "query-string";
import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type GetMessagesByConversationInput = {
    conversation: string;
    since?: string;
    offset?: number;
    count?: number;
};

export const GetMessagesByConversation: ApiHandlerWithAuth<GetMessagesByConversationInput, GetMessagesByConversationResult> = {
    handle: async ({ conversation, ...query }, authHeader) => {
        let response = await fetch(`/api/v1/admin/messages/${conversation}/?${queryString.stringify(query)}`, {
            method: "GET",
            headers: getDefaultHeaders(authHeader)
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as GetMessagesByConversationResult;
    }
}

export type GetMessagesByConversationResult = {
    results: Message[];
    hasMore: boolean;
};

export type CustomerMessageKind =
    | "unknown"
    | "fromApp"
    | "toApp";

export type Message = {
    messageId: string;
    conversation: string;
    kind: CustomerMessageKind;
    fromPhoneNumber: string;
    toPhoneNumber: string;
    price: number;
    body: string;
    errorMessage?: string;
    hasError: boolean;
    timeStamp: string;
}
