import { HttpMethodEnum } from "../../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../../common/utilities/get-auth-request-header";

export const postOrderPay = async (
  orderId: string,
  signal?: AbortSignal
): Promise<PostOrderPayResponse> => {
  const url = `/api/v1/orders/${orderId}/pay`;
  let response: Response = await fetch(`${url}`, {
    signal,
    method: HttpMethodEnum.POST,
    headers: getAuthRequestHeaders(),
  });

  if (response.status >= 500 && response.status < 600) {
    throw new Error("A server error occurred while creating payment intent.");
  }

  if (!response?.ok) {
    return {
      type: "failure"
    }
  }

  let body = (await response.json()) as { stripeClientSecret: string; };
  return {
    type: "success",
    stripeClientSecret: body.stripeClientSecret
  }
};

export type PostOrderPayResponse =
  | { type: "success", stripeClientSecret: string; }
  | { type: "failure" }
