import "./require-auth.scss"

import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { Assets } from "../../../../assets/references";
import { publicPaths } from "../../../../pages/public/public.routes";
import { useAuth } from "../../../auth/use-auth";
import { LoadingIcon } from "../../../utilities/loading-icon";
import { FormLayout } from "../form-layout/form-layout";

export function RequireAuth({ children, scope }: { children: ReactNode, scope: "admin" | "vendor" | "any" }) {
    let { pathname } = useLocation();
    let { isLoading, user } = useAuth();
    let needsLogin = !user;

    let needsPermission = false;
    switch (scope) {
        case "admin":
            needsPermission = !user?.isAdmin;
            break;
        case "vendor":
            needsPermission = !user?.isVendor;
            break;
        case "any":
            needsPermission = !user;
            break;
        default:
            break;
    }

    if (isLoading) {
        return (
            <div className="require-auth vh-100 d-flex justify-content-center align-items-center">
                <LoadingIcon />
            </div>
        );
    }

    let needsLoginMessage = "This page requires authentication, please login.";
    let needsPermissionMessage = "This page requires permission, are you logged into the wrong account?";

    let errorMessage = null;

    if (needsLogin) {
        errorMessage = needsLoginMessage
    }

    if (needsPermission) {
        errorMessage = needsPermissionMessage
    }

    return errorMessage
        ? (
            <div className="require-auth vh-100 d-flex flex-column justify-content-center align-items-center">
                <FormLayout cardBodyClassName="content d-flex flex-column justify-content-center align-items-center">
                    <img className="logo-img" src={Assets.LogoBlackUrl.toString()} alt="Partyeezy" />
                    <p className="mt-4">{errorMessage}</p>
                    <Link className="btn btn-outline-primary" to={publicPaths.login} state={{ redirectTo: pathname }}>
                        Login
                    </Link>
                </FormLayout>
            </div>
        )
        : children;
}

export function RequireAdmin({ children }: { children: ReactNode }) {
    return (
        <RequireAuth scope="admin">
            {children}
        </RequireAuth>
    );
}

export function RequireVendor({ children }: { children: ReactNode }) {
    return (
        <RequireAuth scope="vendor">
            {children}
        </RequireAuth>
    );
}
