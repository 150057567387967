import "./vendor.scss";

import { Nav, Navbar } from "react-bootstrap";
import { NavLink, RouteObject, useOutlet } from "react-router-dom";
import { VendorsInvite } from "./vendors-invite";
import { VendorsList } from "./vendors-list";

export const adminVendorPaths = {
    list: "/admin/vendors",
    invite: "/admin/vendors/invite"
};

export const adminVendorRoutes: RouteObject[] = [
    {
        path: "/admin/vendors",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <VendorsList />
            },
            {
                path: "invite",
                element: <VendorsInvite />
            }
        ]
    }
];

function Layout() {
    const outlet = useOutlet();
    return (
        <div className="d-flex flex-column products h-100">
            <h2 className="ms-2">Vendors</h2>
            <ProductsNavbar />
            <div className="m-2 flex-fill">{outlet}</div>
        </div>
    );
}

function ProductsNavbar() {
    return (
        <Navbar expand="sm" className="border-bottom mb-2">
            <Navbar.Toggle aria-controls="admin-products-navbar" />
            <Navbar.Collapse id="admin-products-navbar">
                <Nav className="me-auto">
                    <NavLink to={adminVendorPaths.list} className="nav-link">
                        List
                    </NavLink>
                    <NavLink to={adminVendorPaths.invite} className="nav-link">
                        Invite Vendor
                    </NavLink>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
