import { useCallback, useMemo, useState } from "react";
import { groupBy } from "../../../../../common/utilities/collection-helpers";
import { OrderLineItem, PutOrderLineItemsRequest } from "../../../../../services/public/ordering/put-order-line-items";
import { useOrderContext } from "../../provider/use-order-context";
import { AddLineItems } from "./add-line-item";
import { EasyAddItems } from "./easy-add-items";
import { LineItemCard } from "./line-item-card";

export const LineItemsSection = ({ next }: { next: () => void }) => {

    let { actions: { setLineItems }, state: { order, products = [] } } = useOrderContext();
    let [isUpdatingLineItems, setIsUpdatingLineItems] = useState(false);

    let lineItems = useMemo(() => {
        return (order?.lineItems ?? [])
            .filter(item => item.kind == "rental" || item.kind == "service")
            .toSorted((a, b) => a.sortOrder < b.sortOrder ? -1 : 1);
    }, [order?.lineItems]);

    const productsGroupedByOptionType = useMemo(() => {
        return groupBy(products, (product) => product.optionType || "Other");
    }, [products]);

    const isCateringInLineItems = useMemo(() => {
        return lineItems?.some((lineItem) => lineItem.productOptionType == "catering");
    }, [lineItems]);

    const availableProducts = useMemo(() => {
        let availableProducts = products
            .filter(product => product.kind != "addOn" && product.kind != "unknown")
            .filter((product) => !lineItems?.some((lineItem) => lineItem.productId === product.id))
            .sort((a, b) => a.sortOrder < b.sortOrder ? 1 : -1);

        if (isCateringInLineItems) {
            availableProducts = availableProducts.filter((product) => product.optionType != "catering");
        }

        return availableProducts;
    }, [products, lineItems, isCateringInLineItems]);

    const updateLineItems = useCallback(async (updatedLineItems: PutOrderLineItemsRequest, signal?: AbortSignal) => {
        await setLineItems(updatedLineItems, signal);
    }, [setLineItems]);

    const handleCountUpdate = useCallback(async (lineItemId: string, count: number, signal?: AbortSignal) => {
        try {
            setIsUpdatingLineItems(true);
            const updatedLineItems: OrderLineItem[] = lineItems.map((lineItem) => {
                let newCount = lineItem.count;
                if (lineItem.lineItemId == lineItemId) {
                    newCount = count;
                }
                return {
                    productId: lineItem.productId,
                    count: newCount,
                    childrenLineItems: lineItem.childrenLineItems,
                    // arrivalDateTime: lineItem.arrivalDateTime,
                }
            }) ?? [];

            await updateLineItems(updatedLineItems, signal);
        } catch (error) {
            console.error(error);
        } finally {
            setIsUpdatingLineItems(false);
        }
    }, [lineItems, updateLineItems]);

    const handleRemoveLineItem = useCallback(async (lineItemId: string, signal?: AbortSignal) => {
        try {
            setIsUpdatingLineItems(true);
            const updatedLineItems = lineItems.filter((lineItem) => lineItem.lineItemId !== lineItemId);
            await updateLineItems(updatedLineItems, signal);
        } catch (error) {
            console.error(error);
        } finally {
            setIsUpdatingLineItems(false);
        }
    }, [lineItems, updateLineItems]);

    return (
        <>
            <ul className="mx-n3 mt-n3" style={{ listStyleType: "none", paddingInlineStart: 0 }}>
                {lineItems.map((lineItem) => (
                    <LineItemCard
                        key={lineItem.lineItemId}
                        lineItem={lineItem}
                        productsGroupedByOptionType={productsGroupedByOptionType}
                        onCountUpdate={(count, signal) => handleCountUpdate(lineItem.lineItemId, count, signal)}
                        onRemoveLineItem={(signal) => handleRemoveLineItem(lineItem.lineItemId, signal)} />
                ))}
            </ul>
            <EasyAddItems products={availableProducts} />
            <AddLineItems availableProducts={availableProducts} />
            <footer className="d-flex mt-3 align-items-center">

                <div className="text-muted">
                    Need something special? Let us know in the next section.
                </div>

                <button type="submit"
                    className="btn btn-outline-primary ms-auto"
                    onClick={next}
                    disabled={isUpdatingLineItems}>
                    Save and Continue
                </button>
            </footer>
        </>
    )
};
