import { GiPartyPopper } from "react-icons/gi";
import { ReactIcon } from "../../../common/components/react-icon/react-icon";
import { VendorFormText } from "./vendor.strings";

export const VendorSubmissionSuccess = () => {
    return (
        <section className="d-flex flex-column align-items-center p-5 text-success fw-bold">
            <ReactIcon icon={GiPartyPopper} className="success-icon" />
            <h5 className="mt-3">{VendorFormText.submitSuccess}</h5>
        </section>
    );
};
