import { useMemo } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { Address } from "../../../services/public/ordering/get-order-by-id";

export function FormatAddress({ address }: { address: Address }) {

    let addressLine = useMemo(() => {
        let query = address.streetLine1;
        if (address.streetLine2) {
            query += `, ${address.streetLine2}`
        }
        query += `, ${address.city}, ${address.state}, ${address.county} ${address.zipCode}`
        return query;
    }, [address.city, address.county, address.state, address.streetLine1, address.streetLine2, address.zipCode])

    let directionsLink = useMemo(() => `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(addressLine)}`, [addressLine]);

    return (
        <a href={directionsLink} className="text-dark me-3" target="_blank" rel="noreferrer">{addressLine}</a>
    )
}

export function FormatAddressWithDirections({ address }: { address: Address }) {

    let addressLine = useMemo(() => {
        let query = address.streetLine1;
        if (address.streetLine2) {
            query += `, ${address.streetLine2}`
        }
        query += `, ${address.city}, ${address.state}, ${address.county} ${address.zipCode}`
        return query;
    }, [address.city, address.county, address.state, address.streetLine1, address.streetLine2, address.zipCode])

    let directionsLink = useMemo(() => `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(addressLine)}`, [addressLine]);

    return (
        <>
            <FaLocationDot className="me-3" />
            <a href={directionsLink} className="text-dark me-3" target="_blank" rel="noreferrer">{addressLine}</a>
            <a href={directionsLink} className="btn btn-primary ms-auto" target="_blank" rel="noreferrer">Directions</a>
        </>
    )
}
