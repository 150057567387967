import { HttpMethodEnum } from "../../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../../common/utilities/get-auth-request-header";

export type GetAddressAutoCompletionsRequest = {
    input?: string;
};

export type GetAddressAutoCompletionsResponse = {
    results: GetAddressAutoCompletionsItem[];
    hasMore: false;
};

export type GetAddressAutoCompletionsItem = {
    description: string;
    placeId: string;
};

export const getAddressAutoCompletions = async (input: GetAddressAutoCompletionsRequest, signal?: AbortSignal): Promise<GetAddressAutoCompletionsResponse> => {
    const url = `/api/v1/addresses/suggestions/${input.input ?? ""}`;
    let response: Response = await fetch(`${url}`, {
        signal,
        method: HttpMethodEnum.GET,
        headers: getAuthRequestHeaders()
    });

    if (!response?.ok) {
        throw response;
    }

    return await response.json() as GetAddressAutoCompletionsResponse;
};
