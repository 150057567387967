import "./packages-list.scss";

import clsx from "clsx";
import { useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { pushGTagEvent } from "../../../common/utilities/gtag";
import { SimpleTooltip } from "../../../common/utilities/simple-tooltip";
import { GetEligiblePackagesResponse } from "../../../services/public/get-eligible-packages";
import { postOrders } from "../../../services/public/ordering/post-orders";
import { putOrderLineItems } from "../../../services/public/ordering/put-order-line-items";
import { plannerPaths } from "../planner.routes";

type PackagesListProps = {
    packages: GetEligiblePackagesResponse[];
    partyTypeId: string;
    partySize: number;
    partyLocation: string;
};

export function PackagesList({ packages, partyTypeId, partySize, partyLocation }: PackagesListProps) {

    const navigate = useNavigate();
    let [isLoading, setIsLoading] = useState(false);

    const updateLineItems = async (
        selectedPackage: GetEligiblePackagesResponse,
        orderId: string
    ) => {
        try {
            const lineItems = selectedPackage.products.map((product) => ({
                productId: product.productId,
                count: product.numberOfItems,
            }));
            await putOrderLineItems(orderId, lineItems);
        } catch (error) {
            console.error(error);
        }
    };

    const handlePackageSelection = async (pkg: GetEligiblePackagesResponse) => {
        setIsLoading(true);
        if (isLoading) {
            return;
        }

        try {
            pushGTagEvent("package_selected", {
                "package": pkg.name,
                "package_tier": pkg.packageTier
            });

            const response = await postOrders({
                headCount: partySize,
                partyTypeId: partyTypeId,
            });

            if (!response?.orderId) {
                throw new Error("Unable to create order");
            }

            await updateLineItems(pkg, response.orderId);

            navigate(plannerPaths.order.replace(":orderId", response.orderId));
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <main className="container package-selection">
            <div className="row align-items-center">
                {/* <header className="col-12 my-3 d-flex flex-column flex-md-row align-items-center"> */}
                <header className="col-12 mb-3 d-flex flex-column align-items-center">
                    <h4 className="mb-1">Let&apos;s get this party started!</h4>
                    <i className="fw-bold">Party packages can be fully customized</i>
                </header>
                <div className="row align-items-center">
                    {packages.map((x) => (
                        <Package key={x.id}
                            eligiblePackage={x}
                            partySize={partySize}
                            isLoading={isLoading}
                            packageSelected={() => handlePackageSelection(x)} />
                    ))}
                </div>
                <section className="col-12 order-5 mb-1 py-3 text-center">
                    <small className="text-muted">
                        * Prices may vary based on customizations and does not include
                        associated fees or taxes.
                    </small>
                </section>
            </div>
        </main>
    );
}

export type PackageProps = {
    eligiblePackage: GetEligiblePackagesResponse;
    partySize: number;
    isLoading: boolean;
    packageSelected: () => void
}

function Package({ eligiblePackage, partySize, isLoading, packageSelected }: PackageProps) {

    const isMostPopular =
        eligiblePackage.packageTier.toLocaleLowerCase() == "gold";
    const pricePerPerson = eligiblePackage.totalPrice / partySize;

    return (
        <article
            key={eligiblePackage.id}
            className={clsx("col-12 eligible-package-card mb-2", isMostPopular ? "order-md-1 order-1 most-popular" : "order-md-1 order-2")}>
            <div className="card">
                {isMostPopular && (<h6 className="most-popular-header card-header text-center p-1">
                    Most Popular
                </h6>)}
                <div className="card-body d-flex flex-column align-items-center">
                    <h2 className="card-title text-capitalize mb-4 fw-bold">
                        {eligiblePackage.packageTier}
                    </h2>
                    <h1 className="mb-4 fw-bold">
                        <span className="per-person">$</span>
                        <span className="price-amount">
                            {Math.ceil(pricePerPerson)}
                        </span>
                        <span className="per-person">/person*</span>
                    </h1>
                    <span className="mb-4 description">
                        {eligiblePackage.description}
                    </span>
                    <button
                        className="btn btn-primary"
                        disabled={isLoading}
                        onClick={packageSelected}>
                        Select Package
                    </button>
                    <ul className="list-group list-group-flush my-3 py-3 border-top align-self-start w-100 products">
                        {eligiblePackage.products.map((product) => (
                            <li key={product.productId} className="list-group-item border-0">
                                <div className="d-flex align-items-center">
                                    <FaCheck className="me-2 text-success w-10" />
                                    <div>
                                        {product.name}
                                        <SimpleTooltip title={product.description}>
                                            {/* PopperJS doesn't like to bind to svg nodes... */}
                                            <span>
                                                <BsInfoCircle className="more-info ms-2" style={{ width: 12, height: 12 }} />
                                            </span>
                                        </SimpleTooltip>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </article>
    )
}

