import { useEffect } from "react";
import { RouterProvider, ScrollRestoration, createBrowserRouter, useLocation, useNavigationType, useOutlet } from "react-router-dom";
import { Action as NavigationType } from "@remix-run/router";
import { Auth } from "./common/auth/auth";
import { PublicLayout } from "./common/components/layouts/public-layout/public-layout";
import { FrontendConfigurationProvider } from "./common/providers/frontend-configuration/frontend-configuration-provider";
import { TapfiliateProvider } from "./common/tapfiliate/tapfiliate-provider";
import { adminRoutes } from "./pages/admin/admin.routes";
import { adminLightWeightVendorsRoutes } from "./pages/admin/light-weight-vendors/light-weight-vendors";
import Landing from "./pages/landing/landing";
import NotFound from "./pages/not-found";
import { plannerRoutes } from "./pages/planner/planner.routes";
import { publicRoutes } from "./pages/public/public.routes";
import { vendorRoutes } from "./pages/vendor/vendor.routes";

const router = createBrowserRouter([
    {
        element: <RootLayout />,
        children: [
            {
                path: "/",
                element: <Landing />,
                index: true,
            },
            ...adminRoutes,
            ...vendorRoutes,
            ...plannerRoutes,
            ...publicRoutes,
            {
                element: <PublicLayout />,
                children: [
                    {
                        path: "*",
                        element: <NotFound />,
                    },
                ],
            },
        ]
    }
]);

export function AppRouter() {
    return (
        <FrontendConfigurationProvider>
            <Auth>
                <TapfiliateProvider>
                    <RouterProvider router={router} />
                </TapfiliateProvider>
            </Auth>
        </FrontendConfigurationProvider>
    )
}

function RootLayout() {
    let outlet = useOutlet();

    let location = useLocation();
    let navigationType = useNavigationType();

    useEffect(() => {
        if (navigationType == NavigationType.Push) {
            // If set to auto, scrolling appears to be canceled when the DOM resizes.
            window.scrollTo({ left: 0, top: 0, behavior: "instant" });
        }
    }, [location.key, navigationType]);

    return (
        <>
            {outlet}
            <ScrollRestoration />
        </>
    )
}
