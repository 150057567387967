import { delay } from "./delay";

export async function getOrRetry<T>(func: () => Promise<T>, retryDelayMs: number): Promise<T> {
    do {
        try {
            return await func();
        } catch (error) {
            console.warn(`Handled exception, will retry after ${retryDelayMs}ms.`, error);
        }
        await delay(retryDelayMs)
        // eslint-disable-next-line no-constant-condition
    } while (true);
}
