export function getDefaultHeaders(authHeader?: string) {
    let defaultHeaders = {
        "Content-Type": "application/json"
    };

    return authHeader ? {
        ...defaultHeaders,
        "Authorization": authHeader
    } : defaultHeaders;
}
