import { HttpMethodEnum } from "../../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../../common/utilities/get-auth-request-header";

export type GetProductsRequest = object;

export type GetProductsResponse = {
  results: GetProductItem[];
  hasMore: boolean;
};

export type GetProductKind = "unknown" | "service" | "rental" | "addOn";

export type GetProductItem = {
  arrivalTimeRequired: boolean;
  capacity: number;
  category?: GetProductCategory[];
  description?: string;
  hasTiers: boolean;
  id: string;
  kind: GetProductKind;
  minimumPerOrder: number;
  name: string;
  optionType?: string;
  parentProductId?: string;
  primaryImageLink?: string;
  sortOrder: number;
  setupTimeMinutes?: number;
  teardownTimeMinutes?: number;
  startingAtCost: number;
  tiers: GetProductTier[];
  unit: string;
  unitPluralized: string;
};

export type GetProductCategory = {
  id: string;
  name: string;
};

export type GetProductTier = {
  id: string;
  cost: number;
  startRange: number;
  endRange?: number;
};

export const getProducts = async (
  _: GetProductsRequest,
  signal?: AbortSignal
): Promise<GetProductsResponse> => {
  let response: Response = await fetch("/api/v1/order-placement/products", {
    signal,
    method: HttpMethodEnum.GET,
    headers: getAuthRequestHeaders(),
  });

  if (!response?.ok) {
    throw response;
  }

  return (await response.json()) as GetProductsResponse;
};
