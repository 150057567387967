import "./planner-order.scss";

import { useCallback, useEffect, useMemo, useReducer } from "react";
import { Accordion } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useFrontendConfiguration } from "../../../common/providers/frontend-configuration/use-frontend-configuration";
import { Footer } from "../../../common/utilities/footer";
import { AccordionSection } from "./order-section";
import { OrderSummary } from "./order-summary";
import { OrderProvider } from "./provider/order-provider";
import { StripeElementsProvider } from "./provider/stripe-elements-provider";
import { useOrderContext } from "./provider/use-order-context";
import { OrderConfirmOrderSection } from "./sections/confirm-order-section";
import { LineItemsHeader } from "./sections/line-items/line-items-header";
import { LineItemsSection } from "./sections/line-items/line-items-section";
import { CancellationInsuranceAddonProductId, HazardInsuranceAddonProductId } from "./sections/line-items/well-known-items";
import { PartyDetailsHeader } from "./sections/party-details-header";
import { PartyDetailsSection } from "./sections/party-details-section";
import { PartyOptionsHeader } from "./sections/party-options-header";
import { PartyOptionsSection } from "./sections/party-options-section";
import { PaymentDetailsHeader } from "./sections/payment-details-header";
import { PaymentDetailsSection } from "./sections/payment-details-section";
import { ServicesArrivalTimeHeader } from "./sections/services-arrival-time-header";
import { ServicesArrivalTimeSection } from "./sections/services-arrival-time-section";

export type PlannerOrderState = {
    partyType: { label: string, value: string };
    partySize: number;
    partyLocation: string;
};

export const PlannerOrder = () => {
    let { orderId } = useParams();

    let { menu } = useParams();
    let typedMenu = getMenuFromString(menu);

    return (
        <OrderProvider orderId={orderId!}>
            <StripeElementsProvider>
                <PlannerOrderImpl routeMenu={typedMenu} />
            </StripeElementsProvider>
        </OrderProvider>
    )
}

const PlannerOrderImpl = ({ routeMenu: routeMenu }: { routeMenu?: Menu }) => {

    let { state: { order, isLoading } } = useOrderContext();

    const [state, dispatch] = useReducer(reducer, {
        selectedMenu: routeMenu ?? "line-items",
        hasServicesRequiringArrivalTime: false
    });

    let nextHandler = useCallback(() => dispatch({ type: "next" }), []);
    let selectHandler = useCallback((menu: Menu) => dispatch({ type: "select", menu }), []);

    let nav = useNavigate();
    useEffect(() => {
        if (routeMenu != state.selectedMenu) {
            if (!routeMenu) {
                nav(`./${state.selectedMenu}`, { relative: "path", replace: true })
            } else {
                nav(`../${state.selectedMenu}`, { relative: "path", replace: true })
            }
        }
    }, [nav, routeMenu, state.selectedMenu]);
    useEffect(() => {
        if (!isLoading && order) {
            let orderOpen = order.state == "created" || order.state == "paymentIssue";
            if (!orderOpen) {
                nav(`/order/${order.orderId}/status`, { replace: true })
            }
        }
    }, [isLoading, nav, order])

    let { configuration } = useFrontendConfiguration();

    const { lineItems } = useMemo(() => order ?? { lineItems: undefined }, [order]);
    const arrivalRequiringLineItems = useMemo(() => {
        return lineItems?.filter((lineItem) => lineItem.arrivalTimeRequired) || [];
    }, [lineItems]);

    useEffect(() => {
        dispatch({ type: "services-requiring-arrival-time-changed", hasServicesRequiringArrivalTime: arrivalRequiringLineItems.length > 0 })
    }, [arrivalRequiringLineItems]);

    return (
        <>
            <header className="bg-primary text-white">
                <div className="container py-3 d-flex align-items-end">
                    <div className="fs-2">
                        Let&apos;s plan that <span className="text-lowercase">{order?.packageDetails.partyTypeName || "Party"}</span>!
                    </div>
                </div>
            </header>

            {!!configuration?.isDemo && (
                <div className="bg-dark text-white mx-n2">
                    <div className="container fw-bold py-1">
                        Demo Mode
                    </div>
                </div>
            )}

            <main className="planner-order container pb-2 flex-fill">
                <div className="row">
                    <div className="col-lg-9">

                        <Accordion activeKey={state.selectedMenu} className="w-100">

                            <AccordionSection
                                header={(open) => <LineItemsHeader open={open} />}
                                section={<LineItemsSection next={nextHandler} />}
                                menu="line-items"
                                selectedMenu={state.selectedMenu}
                                onSelect={selectHandler}
                                complete={!!order?.lineItems} />

                            <AccordionSection
                                header={(open) => <PartyOptionsHeader open={open} />}
                                section={<PartyOptionsSection next={nextHandler} />}
                                menu="special-instructions"
                                selectedMenu={state.selectedMenu}
                                onSelect={selectHandler}
                                complete={
                                    !!order?.specialInstructions
                                    || !!order?.lineItems.find(x => x.productId == HazardInsuranceAddonProductId || x.productId == CancellationInsuranceAddonProductId)
                                } />

                            <AccordionSection
                                header={(open) => <PartyDetailsHeader open={open} />}
                                section={<PartyDetailsSection next={nextHandler} />}
                                menu="event-details"
                                selectedMenu={state.selectedMenu}
                                onSelect={selectHandler}
                                complete={!!order?.eventSchedule && !!order?.eventAddress} />

                            {arrivalRequiringLineItems?.length > 0 && (
                                <AccordionSection
                                    header={(open) => <ServicesArrivalTimeHeader open={open} />}
                                    section={
                                        <ServicesArrivalTimeSection
                                            next={nextHandler}
                                            arrivalRequiringLineItems={arrivalRequiringLineItems}
                                        />
                                    }
                                    menu="services-arrival-time"
                                    selectedMenu={state.selectedMenu}
                                    onSelect={selectHandler}
                                    complete={!!order?.eventSchedule && !!order?.eventAddress} />
                            )}

                            <AccordionSection
                                header={(open) => <PaymentDetailsHeader open={open} arrivalRequiringLineItems={arrivalRequiringLineItems} />}
                                section={<PaymentDetailsSection next={nextHandler} />}
                                menu="billing-details"
                                selectedMenu={state.selectedMenu}
                                onSelect={selectHandler}
                                complete={!!order?.billingAddress} />

                            <AccordionSection
                                header={() => <>Review Order</>}
                                section={<OrderConfirmOrderSection />}
                                menu="review-order"
                                selectedMenu={state.selectedMenu}
                                onSelect={selectHandler}
                                complete={false} />

                        </Accordion>
                    </div>
                    <div className="col-lg-3 position-relative">
                        <OrderSummary />
                    </div>
                </div>
            </main>

            <Footer />
        </>
    );
};

export type Menu =
    | "line-items"
    | "special-instructions"
    | "event-details"
    | "services-arrival-time"
    | "billing-details"
    | "review-order";

type State = {
    selectedMenu: Menu;
    hasServicesRequiringArrivalTime: boolean;
}

type Action =
    | { type: "next" }
    | { type: "select", menu: Menu }
    | { type: "services-requiring-arrival-time-changed", hasServicesRequiringArrivalTime: boolean };

function reducer(state: State, action: Action): State {
    if (action.type == "next") {
        let nextMenu: Menu;
        switch (state.selectedMenu) {
            case "line-items":
                nextMenu = "special-instructions";
                break;
            case "special-instructions":
                nextMenu = "event-details";
                break;
            case "event-details":
                nextMenu = state.hasServicesRequiringArrivalTime ? "services-arrival-time" : "billing-details";
                break;
            case "services-arrival-time":
                nextMenu = "billing-details";
                break;
            case "billing-details":
                nextMenu = "review-order";
                break;
            default:
                nextMenu = "review-order";
                break;
        }

        return {
            ...state,
            selectedMenu: nextMenu
        }
    }

    if (action.type == "select") {
        return {
            ...state,
            selectedMenu: action.menu
        };
    }

    if (action.type == "services-requiring-arrival-time-changed") {
        return {
            ...state,
            hasServicesRequiringArrivalTime: action.hasServicesRequiringArrivalTime
        };
    }

    return {
        ...state
    };
}

function getMenuFromString(menu?: string): Menu | undefined {
    switch (menu) {
        case "line-items":
            return "line-items";
        case "special-instructions":
            return "special-instructions";
        case "event-details":
            return "event-details";
        case "services-arrival-time":
            return "services-arrival-time";
        case "billing-details":
            return "billing-details";
        case "review-order":
            return "review-order";
        default:
            return undefined;
    }
}
