import "./form-layout.scss";

import clsx from "clsx";
import { Assets } from "../../../../assets/references";

type FormLayoutType = {
    children: React.ReactNode;
    layoutClassName?: string;
    cardClassName?: string;
    cardBodyClassName?: string;
    underHangClassName?: string;
};

export const FormLayout = (props: FormLayoutType) => {
    const { children, layoutClassName, cardClassName, cardBodyClassName, underHangClassName } = props;

    return (
        <div className={clsx("form-layout mt-4 w-100", layoutClassName)}>
            <div className={clsx("card border-0", cardClassName)}>
                <div className={clsx("card-body", cardBodyClassName)}>{children}</div>
            </div>
            <img
                className={clsx("under-hang", underHangClassName)}
                src={Assets.UnderHangUrl.toString()}
                alt="Partyeezy"
            />
        </div>
    );
};
