import { HttpMethodEnum } from "../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../common/utilities/get-auth-request-header";
import { getQueryString } from "../../common/utilities/get-query-as-string";

export type GetEligiblePackagesRequest = {
    PartyTypeId: string;
    Zip: string;
    HeadCount: number;
}

type Product = {
    productId: string;
    name: string;
    price: number;
    description: string;
    numberOfItems: number;
}

export type GetEligiblePackagesResponse = {
    id: string;
    name: string;
    description: string;
    packageTier: string;
    totalPrice: number;
    products: Product[];
};


export const getEligiblePackages = async (request: GetEligiblePackagesRequest, signal?: AbortSignal): Promise<GetEligiblePackagesResponse[]> => {
    const queryString = getQueryString(request);
    const url = `/api/v1/order-placement/eligible-packages?${queryString}`;
    let response: Response = await fetch(`${url}`, {
        signal,
        method: HttpMethodEnum.GET,
        headers: getAuthRequestHeaders()
    });

    if (!response?.ok) {
        throw response;
    }

    return await response.json() as GetEligiblePackagesResponse[];
};
