export const getQueryString = (query: Object) => {
    return Object.entries(query).reduce(
        (result: string, [key, value]: [string, string | number]) => {
            if (value) {
                const queryString = `${key}=${encodeURIComponent(
                    value.toString()
                )}`;
                if (!result) {
                    result = `${queryString}`;
                } else {
                    result += `&${queryString}`;
                }
            }
            return result;
        },
        ""
    );
};
