import { Navigate, RouteObject } from "react-router-dom";
import { PublicLayout } from "../../common/components/layouts/public-layout/public-layout";
import { AcceptInvite } from "./accept-invite/accept-invite";
import { ContactUsPage } from "./contact-us/contact-us-page";
import { FaqPage } from "./faq/faq-page";
import { FinancingInfo } from "./financing/financing";
import Login from "./login/login";
import { SignUp } from "./sign-up/sign-up";
import { TermsAndConditionsPage } from "./terms-and-conditions/terms-and-conditions";
import Vendor from "./vendor/vendor";

// const ConfirmEmail = lazy(() => import("./no-auth-pages/confirm-email/confirm-email"));
// const ForgotPassword = lazy(() => import("./no-auth-pages/forgot-password/forgot-password"));
// const Login = lazy(() => import("./no-auth-pages/login/login"));
// const SignUp = lazy(() => import("./no-auth-pages/sign-up/sign-up"));
// const Landing = lazy(() => import("./no-auth-pages/landing/landing"));
// const ResetPassword = lazy(() => import("./no-auth-pages/reset-password/reset-password"));

export const publicPaths = {
    landing: "/",
    login: "/login",
    acceptInvite: "/login/accept-invite",
    vendor: "/vendor",
    signUp: "/signup",
    financingInfo: "/financing",
    faq: "/faq",
    contactUs: "/contact-us",
    termsAndConditions: "/terms-and-conditions",
    // confirmEmail: "/confirm-email",
    // forgotPassword: "/forgot-password",
    // resetPassword: "/reset-password",
};

export const publicRoutes: RouteObject[] = [
    {
        element: <PublicLayout />,
        children: [
            {
                path: publicPaths.login,
                element: <Login />
            },
            {
                path: publicPaths.acceptInvite,
                element: <AcceptInvite />
            },
            {
                path: publicPaths.vendor,
                element: <Vendor />
            },
            {
                path: publicPaths.signUp,
                element: <SignUp />
            },
            {
                path: publicPaths.financingInfo,
                element: <FinancingInfo />
            },
            {
                path: publicPaths.faq,
                element: <FaqPage />
            },
            {
                path: "/contact",
                element: <Navigate to={publicPaths.contactUs} />
            },
            {
                path: publicPaths.contactUs,
                element: <ContactUsPage />
            },
            {
                path: publicPaths.termsAndConditions,
                element: <TermsAndConditionsPage />
            },
            // {
            //     path: unAuthPaths.confirmEmail,
            //     Component: ConfirmEmail
            // },
            // {
            //     path: unAuthPaths.forgotPassword,
            //     Component: ForgotPassword
            // },
            // {
            //     path: unAuthPaths.resetPassword,
            //     Component: ResetPassword
            // },
        ]
    }
];
