import { Nav, Navbar } from "react-bootstrap";
import { NavLink, RouteObject, useOutlet } from "react-router-dom";
import { CreateLightWeightVendorButton } from "../light-weight-vendors/shared/create-light-weight-vendor-button";
import { OrderById } from "./order-by-id/order-by-id";
import { OrderList } from "./order-list/order-list";

export const adminOrderRoutes: RouteObject[] = [
    {
        path: "/admin/orders",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <OrderList />
            },
            {
                path: ":orderId",
                element: <OrderById />
            },
        ]
    }
];

function Layout() {
    const outlet = useOutlet();
    return (
        <div className="d-flex flex-column products h-100">
            <h2 className="ms-2">Orders</h2>
            <OrderNavbar />
            <div className="m-2 flex-fill d-flex flex-column">{outlet}</div>
        </div>
    );
}

function OrderNavbar() {
    return (
        <Navbar expand="sm" className="border-bottom mb-2">
            <Navbar.Toggle aria-controls="admin-products-navbar" />
            <Navbar.Collapse id="admin-products-navbar">
                <Nav className="d-flex flex-fill align-items-end">
                    <NavLink to="/admin/orders" className="nav-link">
                        List
                    </NavLink>
                    <CreateLightWeightVendorButton className="ms-auto" />
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
