import queryString from "query-string";
import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type GetPromotionCodesInput = {
    isDisabled?: boolean;
    offset?: number;
    count?: number;
};

export const GetPromotionCodes: ApiHandlerWithAuth<GetPromotionCodesInput, GetPromotionCodesResult> = {
    handle: async (input, authHeader) => {
        let response = await fetch(`/api/v1/admin/promotions?${queryString.stringify(input)}`, {
            method: "GET",
            headers: getDefaultHeaders(authHeader),
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as GetPromotionCodesResult;
    }
}

export type GetPromotionCodesResult = {
    results: PromotionCode[];
    hasMore: boolean;
};

export type PromotionCode = {
    promotionCodeId: string;
    code: string;
    discount: number;
    isDisabled: boolean;
    notes?: string;
}
