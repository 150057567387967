import { useCallback, useState } from "react";
import { useDebouncedEffect } from "../utilities/use-debounced-effect";
import { ApiHandlerWithAuth } from "./handlers/api-handler-with-auth";
import { useApiHandlerWithAuth } from "./use-api-handler-with-auth";

export function useQueryWithAuth<TInput, TResult>(
    definition: ApiHandlerWithAuth<TInput, TResult>,
    input: TInput,
    dependencies: unknown[]
) {

    let [data, setData] = useState<TResult | null>(null);
    let [isLoading, setIsLoading] = useState<boolean>(true);
    let [isError, setIsError] = useState<boolean>(false);

    let fetcher = useApiHandlerWithAuth<TInput, TResult>(definition);

    let refresh = useCallback(async (withLoading: boolean = true, signal?: AbortSignal) => {
        if (withLoading) {
            setIsLoading(true);
        }
        setIsError(false);
        try {
            let data = await fetcher(input, signal);
            setData(data ?? null);
            return data;
        } catch (error) {
            setIsError(true);
        }
        finally {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetcher, ...dependencies])

    useDebouncedEffect((signal) => {
        void refresh(true, signal);
    }, [refresh], 200);

    return {
        data,
        isLoading,
        isError,
        refresh
    }
}
