import { useMemo } from "react";
import { groupBy } from "../../../../../common/utilities/collection-helpers";
import { GetProductItem } from "../../../../../services/public/ordering/get-products";
import { useOrderContext } from "../../provider/use-order-context";
import "./easy-add-items.scss";
import { ProductThumbnail } from "./product-thumbnail";

type EasyAddItemsPropsType = {
    products: GetProductItem[];
}

export const EasyAddItems = ({ products }: EasyAddItemsPropsType) => {
    const { actions: { setLineItems }, state: { order, isLoading } } = useOrderContext();
    let { lineItems } = order ?? { lineItems: undefined };


    const popularProducts = useMemo(() => {
        const groupedProducts = Object.values(groupBy(products, ({ optionType }) => optionType || "Services")).reduce((acc, x) => acc.concat(x), []);
        return [...groupedProducts].slice(0, 3);
    }, [products]);

    const handleEasyAddItem = async (productId: string) => {
        try {
            const controller = new AbortController();
            await setLineItems([...lineItems || [], { productId, count: 1 }], controller.signal);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            {popularProducts.length > 0 && (<section className="d-flex flex-column align-items-center justify-content-center easy-add-item-card pb-3 mx-n3 mb-3 border-bottom">
                <h4 className="mb-3">Popular Party Add-Ons</h4>
                <section className="row g-3 w-100 mb-3">
                    {
                        popularProducts.map((product) => (
                            <section className="col-12 col-md-4 d-flex flex-column align-items-center flex-nowrap" key={product.id}>
                                <ProductThumbnail src={product.primaryImageLink} name={product.name} className="mb-3 easy-add-item-image" />
                                {/* <span className="fw-bold">{product.name}</span> */}
                                <button className="btn btn-outline-primary" onClick={() => handleEasyAddItem(product.id)} disabled={isLoading}>Add {product.name}</button>
                            </section>
                        ))
                    }
                </section>
            </section>)}
        </>
    )
}
