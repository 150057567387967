import { HttpMethodEnum } from "../../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../../common/utilities/get-auth-request-header";

export type PutOrderPromotionCodeRequest = {
  code?: string | null;
}

export const PutOrderPromotionCode = async (
  orderId: string,
  request: PutOrderPromotionCodeRequest,
  signal?: AbortSignal
): Promise<void> => {
  const url = `/api/v1/orders/${orderId}/promotion-code`;
  let response: Response = await fetch(`${url}`, {
    signal,
    method: HttpMethodEnum.PUT,
    body: JSON.stringify(request),
    headers: getAuthRequestHeaders(),
  });

  if (!response?.ok) {
    throw response;
  }
};
