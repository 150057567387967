export const getRequestHeaders = (options: HeadersInit = {}) => {
    const defaultHeaders: HeadersInit = {
        "Content-Type": "application/json"
    };
    let headers = { ...defaultHeaders, ...options };
    return headers;
};

export const getAuthRequestHeaders = (options: HeadersInit = {}) => {
    let headers = getRequestHeaders(options);
    // const sessionInfo = session.getSession();

    // if (sessionInfo) {
    //     headers = {
    //         ...headers,
    //         "Authorization": `${sessionInfo.token_type} ${sessionInfo.access_token}`
    //     };
    // }

    return headers;
};
