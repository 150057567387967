import { useMemo, useState } from "react";
import { SingleValue } from "react-select";
import { BootstrapAsyncSelect } from "../../../common/components/select/bootstrap-async-select";
import { addressToString } from "../../../common/utilities/address-to-string";
import { useDebouncedEffect } from "../../../common/utilities/use-debounced-effect";
import { getAddressAutoCompletions } from "../../../services/public/address-validation/get-address-autocompletions";
import { getAddressByPlaceId } from "../../../services/public/address-validation/get-address-by-place-id";

export type Address = {
    streetLine1?: string;
    streetLine2?: string;
    city?: string;
    state?: string;
    county?: string;
    zipCode?: string;
}

type ReactSelectOption = {
    label: string;
    value: string;
};

export type AddressAutocompleteProps = {
    onChange: (address: Address) => void;
    address?: Address;
    labelOverride?: string | null;
}

export const AddressAutocomplete = ({ onChange, address, labelOverride }: AddressAutocompleteProps) => {
    let [searchTerm, setSearchTerm] = useState<string>("");

    const getOptions = async (inputValue: string) => {
        try {
            const response = await getAddressAutoCompletions({ input: inputValue });
            const options = response?.results.map((address) => ({
                label: address.description,
                value: address.placeId
            }));
            return options;
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    // DEBOUNCED SEARCH - START
    // https://codesandbox.io/p/sandbox/react-select-debounce-06r5j?file=%2Ftsconfig.json
    let [reactSelectCallback, setReactSelectCallback] = useState<(option: ReactSelectOption[]) => void>(() => { });

    useDebouncedEffect(() => {
        const handleDebouncedSearch = async () => {
            if (searchTerm?.length > 0 && reactSelectCallback) {
                reactSelectCallback(await getOptions(searchTerm));
            }
        };

        void handleDebouncedSearch();
    }, [searchTerm, reactSelectCallback], 350);

    const handleAddressSearch = (inputValue: string, callback: (options: ReactSelectOption[]) => void) => {
        setSearchTerm(inputValue);
        setReactSelectCallback(() => callback);
    };
    // DEBOUNCED SEARCH - END

    const handleAddressSelection = async (selectedOption: SingleValue<ReactSelectOption>) => {
        try {
            if (!selectedOption) {
                return;
            }

            const response = await getAddressByPlaceId({ placeId: selectedOption.value });
            if (!response) {
                throw new Error("Address not found");
            }

            onChange(response);
            setSearchTerm("");
        } catch (error) {
            console.error(error);
        }
    };

    let value = useMemo(() => {
        if (!address) {
            return null;
        }

        return {
            label: labelOverride ?? addressToString({
                streetLine1: address.streetLine1,
                streetLine2: address.streetLine2,
                city: address.city,
                state: address.state,
                zipCode: address.zipCode
            }),
            value: ""
        };
    }, [address, labelOverride]);

    return (
        <BootstrapAsyncSelect
            loadOptions={handleAddressSearch}
            onChange={(newValue: SingleValue<ReactSelectOption>) => handleAddressSelection(newValue)}
            value={value}
            placeholder="Start Typing..."
        />
    )
};
