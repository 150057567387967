import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type CreatePromotionCodeInput = {
    code: string;
    notes?: string;
    discount: number;
};

export const CreatePromotionCode: ApiHandlerWithAuth<CreatePromotionCodeInput, CreatePromotionCodeResult> = {
    handle: async (input, authHeader) => {
        let response = await fetch("/api/v1/admin/promotions", {
            method: "POST",
            headers: getDefaultHeaders(authHeader),
            body: JSON.stringify(input)
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as CreatePromotionCodeResult;
    }
}

export type CreatePromotionCodeResult = {
    promotionCodeId: string;
};
