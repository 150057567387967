import { HttpMethodEnum } from "../../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../../common/utilities/get-auth-request-header";

export type InServiceAreaRequest = {
    zipCode: string;
};

export type InServiceAreaResponse = {
    isServiced: boolean;
};

export const inServiceArea = async (input: InServiceAreaRequest, signal?: AbortSignal): Promise<InServiceAreaResponse> => {
    const url = `/api/v1/addresses/in-service-area/${input.zipCode}`;
    let response: Response = await fetch(`${url}`, {
        signal,
        method: HttpMethodEnum.GET,
        headers: getAuthRequestHeaders()
    });

    if (!response?.ok) {
        throw response;
    }

    return await response.json() as InServiceAreaResponse;
};
