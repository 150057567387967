import { ErrorMessage, Props } from "@hookform/error-message";
import clsx from "clsx";
import { ReactNode } from "react";
import { DeepMap, FieldValues, FieldError } from "react-hook-form";

export type LabelProps = {
    htmlFor?: string,
    className?: string,
    children: ReactNode
}

export function LabelWithError<
    TFieldErrors extends DeepMap<FieldValues, FieldError>,
    TAs extends
    | React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>>
    | React.ComponentType<unknown>
    | keyof JSX.IntrinsicElements
    | undefined = undefined
>(props: Props<TFieldErrors, TAs> & LabelProps) {

    let htmlFor = props.htmlFor ?? props.name;
    // eslint-disable-next-line no-prototype-builtins
    let hasError = props.errors?.hasOwnProperty(props.name)
        && !!props.errors[props.name].message;
    return (
        <label htmlFor={htmlFor} {...props}>
            {
                hasError
                    ? (<ErrorMessage
                        {...props}
                        render={({ message }) => <span className="text-danger">{message}</span>} />)
                    : props.children
            }
        </label>
    )
}
