import "./planner-packages.scss";

import { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { BannerFooter } from "../../../common/utilities/banner-footer";
import { Footer } from "../../../common/utilities/footer";
import { pushGTagEvent } from "../../../common/utilities/gtag";
import { LoadingIcon } from "../../../common/utilities/loading-icon";
import { inServiceArea } from "../../../services/public/address-validation/in-service-area";
import { GetEligiblePackagesResponse, getEligiblePackages } from "../../../services/public/get-eligible-packages";
import { PartyPlannerForm, PartyPlannerFormType } from "../../landing/party-planner-form";
import { FaqSection } from "../../public/faq/faq-section";
import { publicPaths } from "../../public/public.routes";
import { PackagesList } from "./packages-list";

enum PackageTier {
    Silver = "silver",
    Gold = "gold",
    Platinum = "platinum"
}

const packageOrder = [PackageTier.Silver, PackageTier.Gold, PackageTier.Platinum]

export const PlannerPackages: React.FC = () => {

    let [query, setQuery] = useSearchParams();
    let options = useMemo(() => {
        return {
            typeId: query.get("typeId") ?? undefined,
            zipCode: query.get("zipCode") ?? undefined,
            size: query.get("size") ?? undefined
        }
    }, [query]);

    let [isLoading, setIsLoading] = useState(true);
    let [packages, setPackages] = useState<GetEligiblePackagesResponse[]>([]);
    let [warnNotServiced, setWarnNotServiced] = useState<boolean>(false);
    useEffect(() => {
        void (async () => {
            if (!options.size || !options.typeId || !options.zipCode) {
                return;
            }
            setIsLoading(true);

            let result = await inServiceArea({ zipCode: options.zipCode });
            setWarnNotServiced(!result.isServiced);

            if (!result.isServiced) {
                pushGTagEvent("outside_service_area", {
                    "zip_code": options.zipCode,
                    "party_size": options.size
                });
            }

            const packages = await getEligiblePackages({
                PartyTypeId: options.typeId,
                Zip: options.zipCode,
                HeadCount: Number(options.size),
            });
            const sortedPackages: GetEligiblePackagesResponse[] = [];
            packageOrder.forEach((tier) => {
                const pkg = packages.find((p) => p.packageTier == tier.toString());
                if (pkg) {
                    sortedPackages.push(pkg);
                }
            });
            setPackages(sortedPackages);
            setIsLoading(false);
        })();
    }, [options.size, options.typeId, options.zipCode]);

    let partyPlannerFormValues = useMemo<Partial<PartyPlannerFormType>>(() => ({
        partyLocation: options.zipCode,
        partySize: options.size ? Number(options.size) : undefined,
        partyTypeId: options.typeId,
    }), [options.size, options.typeId, options.zipCode]);
    const handlePartyPlannerFormSubmit = (data: PartyPlannerFormType) => {
        setQuery({
            zipCode: data.partyLocation,
            size: data.partySize?.toString(),
            typeId: data.partyTypeId,
        }, { replace: true })
    };

    let packagesListRef = useRef(null)

    useEffect(() => pushGTagEvent("packages_viewed"), []);

    return (
        <>
            <PartyPlannerForm {...partyPlannerFormValues} onSubmit={handlePartyPlannerFormSubmit} />

            <SwitchTransition>
                <CSSTransition
                    key={isLoading ? "loading" : "loaded"}
                    nodeRef={packagesListRef}
                    timeout={300}
                    classNames="fade">
                    <div ref={packagesListRef} className="fade planner-packages">
                        {isLoading && (
                            <div className="d-flex justify-content-center flex-fill p-5 mt-5 h-100">
                                <LoadingIcon />
                            </div>
                        )}
                        {!isLoading && (
                            <PackagesList
                                partySize={Number(options?.size)}
                                packages={packages}
                                partyTypeId={options?.typeId || ""}
                                partyLocation={options?.zipCode || ""}
                            />
                        )}
                    </div>
                </CSSTransition>
            </SwitchTransition>

            <section className="container">
                <h1 className="d-flex flex-column align-items-center my-4">FAQ</h1>
                <FaqSection />
            </section>

            <NotServicedModal
                onClose={() => setWarnNotServiced(false)}
                show={!isLoading && warnNotServiced}
                zipCode={options.zipCode} />

            <BannerFooter className="col-12 p-0" />
            <Footer />
        </>
    )
};

type NotServicedModalProps = {
    show: boolean;
    onClose: () => void;
    zipCode?: string;
}

function NotServicedModal({ show, onClose, zipCode }: NotServicedModalProps) {
    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Outside of Service Area</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column">
                    <div className="text-center">
                        Oh no! We don&apos;t serve your zip code yet, but feel free to look around!
                    </div>

                    <div className="d-flex align-items-center mt-1 justify-content-center text-center">
                        <Link to={{
                            pathname: publicPaths.signUp,
                            search: createSearchParams({ zipCode: zipCode ?? "" }).toString()
                        }}
                            target="_blank">Contact me when my area is available.</Link>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={onClose}>Got it!</button>
            </Modal.Footer>
        </Modal>
    )
}
