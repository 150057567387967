import { ErrorMessage, Props } from "@hookform/error-message";
import clsx from "clsx";
import { DeepMap, FieldValues, FieldError } from "react-hook-form";
import { BiSolidErrorCircle } from "react-icons/bi";

export class CustomErrorMessageProps {
    className?: string;
    showIcon?: boolean;
}

export function CustomErrorMessage<
    TFieldErrors extends DeepMap<FieldValues, FieldError>,
    TAs extends
    | React.ReactElement<unknown, string | React.JSXElementConstructor<unknown>>
    | React.ComponentType<unknown>
    | keyof JSX.IntrinsicElements
    | undefined = undefined
>(props: Props<TFieldErrors, TAs> & CustomErrorMessageProps) {

    // eslint-disable-next-line no-prototype-builtins
    let hasError = props.errors?.hasOwnProperty(props.name)
        && !!props.errors[props.name].message;

    return hasError
        ? (<ErrorMessage
            {...props}
            render={({ message }) => <CustomErrorMessageImpl message={message} {...props} />}
        ></ErrorMessage>)
        : <></>;
}

function CustomErrorMessageImpl({
    message,
    showIcon = false,
    className
}: { message: string } & CustomErrorMessageProps) {
    return (
        <span className={clsx("d-flex align-items-center text-danger", className)}>
            {showIcon && <BiSolidErrorCircle className="me-1" />}
            <span>{message}</span>
        </span>
    );
}
