import { useEffect, useState } from "react";
import { Jelly } from "@uiball/loaders";

export function LoadingIcon() {

    let [show, setShow] = useState(false);

    useEffect(() => {
        let timeout = setTimeout(() => setShow(true), 200);
        return () => clearTimeout(timeout);
    }, [])

    return (
        <>
            {show && <Jelly color="#F16E42" />}
        </>
    )
}
