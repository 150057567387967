import { SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { Assets } from "../../../assets/references";
import { FormLayout } from "../../../common/components/layouts/form-layout/form-layout";
import { Regex } from "../../../common/constants/regex";
import { CustomErrorMessage } from "../../../common/forms/custom-error-message";
import { BannerFooter } from "../../../common/utilities/banner-footer";
import { postPlannerSurvey } from "../../../services/surveys-service/post-planner-survey";
import { SignUpSubmissionSuccess } from "./sign-up-submission-success";
import { SignUpFormProps } from "./sign-up.constants";
import { signUpFormText } from "./sign-up.strings";
import { SignUpFormType } from "./sign-up.type";

export const SignUp = () => {

    let [query] = useSearchParams();
    let zipCode = query.get("zipCode") ?? undefined;

    return (
        <section className="d-flex flex-column align-items-center row h-100 pez-font">
            <header className="col-12 bg-primary text-white py-3 fs-2 text-center">
                {signUpFormText.caption}
            </header>
            <FormLayout layoutClassName="col-lg-8 vendor">
                <SignUpForm zipCode={zipCode} />
            </FormLayout>
            <BannerFooter className="col-12 p-0 mt-auto" />
        </section>
    )
};

export type SignUpFormProps = {
    zipCode?: string;
}

export function SignUpForm({ zipCode }: SignUpFormProps) {

    const {
        register,
        handleSubmit,
        formState: { isValid, errors, isSubmitSuccessful }
    } = useForm<SignUpFormType>({
        mode: "onChange",
        defaultValues: {
            fullName: "",
            emailAddress: "",
            phoneNumber: "",
            zipCode: zipCode ?? ""
        }
    });
    const { fullNameProp, emailAddressProp, phoneNumberProp, zipCodeProp } = SignUpFormProps;

    const { validationMessages } = signUpFormText;
    const validationRules = {
        [fullNameProp]: {
            required: true
        },
        [emailAddressProp]: {
            required: true,
            pattern: { value: Regex.email, message: validationMessages.emailAddress.pattern }
        },
        [phoneNumberProp]: {
            required: true,
            pattern: { value: Regex.phoneNumber, message: validationMessages.phoneNumber.pattern }
        },
        [zipCodeProp]: {
            required: true,
            pattern: { value: Regex.zip, message: validationMessages.zipCode.pattern }
        }
    };

    const reactHookSubmitCallback: SubmitHandler<SignUpFormType> = async (data) => {
        try {
            await postPlannerSurvey(data);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            {isSubmitSuccessful && <SignUpSubmissionSuccess />}
            {
                !isSubmitSuccessful && (
                    <form className="row g-3" noValidate onSubmit={handleSubmit(reactHookSubmitCallback)}>
                        {zipCode && (
                            <div className="col-12 px-3 text-center">
                                <p>
                                    <b>Thank you for your interest in Partyeezy.</b> <br />
                                    We are yet to service in {zipCode}. <br />
                                    Please provide your contact information to be notified once we start servicing your area.
                                </p>
                            </div>
                        )}
                        <div className="col-md-6">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={signUpFormText.fullName}
                                    {...register(fullNameProp, validationRules[fullNameProp])}
                                />
                                <label htmlFor={fullNameProp}>{signUpFormText.fullName}</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-floating">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder={signUpFormText.emailAddress}
                                    {...register(emailAddressProp, validationRules[emailAddressProp])}
                                />
                                <label htmlFor={emailAddressProp}>
                                    {errors[emailAddressProp]?.type === "pattern" ? (
                                        <CustomErrorMessage errors={errors} name={emailAddressProp} />
                                    ) : (
                                        signUpFormText.emailAddress
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-floating">
                                <input
                                    type="tel"
                                    className="form-control"
                                    placeholder={signUpFormText.phoneNumber}
                                    {...register(phoneNumberProp, validationRules[phoneNumberProp])}
                                />
                                <label htmlFor={phoneNumberProp}>
                                    {errors[phoneNumberProp]?.type === "pattern" ? (
                                        <CustomErrorMessage errors={errors} name={phoneNumberProp} />
                                    ) : (
                                        signUpFormText.phoneNumber
                                    )}
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-floating">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder={signUpFormText.zipCode}
                                    {...register(zipCodeProp, validationRules[zipCodeProp])}
                                />
                                <label htmlFor={zipCodeProp}>
                                    {errors[zipCodeProp]?.type === "pattern" ? (
                                        <CustomErrorMessage errors={errors} name={zipCodeProp} />
                                    ) : (
                                        signUpFormText.zipCode
                                    )}
                                </label>
                            </div>
                        </div>
                        <footer className="d-flex justify-content-center py-2 mt-3">
                            <button type="submit" className="btn btn-primary" disabled={!isValid}>
                                {signUpFormText.submit}
                            </button>
                        </footer>
                    </form>
                )
            }
        </>
    );
}
