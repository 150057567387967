import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CreatePromotionCode, CreatePromotionCodeInput } from "../../../common/api/handlers/admin/promotion-codes/create-promotion-code";
import { useApiHandlerWithAuth } from "../../../common/api/use-api-handler-with-auth";
import { LabelWithError } from "../../../common/forms/label-with-error";
import { usePromotionCodeValidation } from "./shared/validation";

export function PromotionCodesNew() {

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid, isValidating }
    } = useForm<CreatePromotionCodeInput>({
        mode: "onChange"
    });

    let navigate = useNavigate();

    let handler = useApiHandlerWithAuth(CreatePromotionCode);
    const onSubmit = useCallback(async ({ discount, code, notes }: CreatePromotionCodeInput) => {
        try {
            await handler({
                code,
                notes,
                discount: Math.abs(discount) / -100,
            });
            navigate("../");
        } catch (error) {
            console.warn("Failed to save promotion code.", error);
        }
    }, [handler, navigate]);

    let { codeValidator: validator } = usePromotionCodeValidation()

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column">

                <div className="col-12 col-md-6">

                    <p>
                        Create a new promotion code here.
                    </p>

                    <div className="form-floating">
                        <input type="text"
                            className="form-control text-uppercase"
                            placeholder="Promotion Code"
                            id="code"
                            {...register("code", { required: "Promotion code is required", validate: validator })} />
                        <LabelWithError errors={errors} name="code">
                            Promotion Code
                        </LabelWithError>
                    </div>
                    <p className="mt-2 text-muted">
                        Promotion codes best be unique. You can change the promotion code later. All promotion codes are case insensitive and may not contain whitespace or special characters (for the sanity of all involved).
                    </p>

                    <div className="form-floating mt-3">
                        <textarea
                            className="form-control"
                            placeholder="Notes"
                            id="notes"
                            {...register("notes")} />
                        <LabelWithError errors={errors} name="notes">
                            Notes
                        </LabelWithError>
                    </div>
                    <p className="mt-2 text-muted">
                        Any notes you may have here. Optional.
                    </p>

                    <div className="form-floating mt-3">
                        <input type="text"
                            className="form-control"
                            placeholder="Discount"
                            id="notes"
                            {...register("discount", {
                                required: "Discount is required",
                                valueAsNumber: true
                            })} />
                        <LabelWithError errors={errors} name="discount">
                            Discount (%)
                        </LabelWithError>
                    </div>
                    <p className="mt-2 text-muted">
                        The discount for this promotion code where a value of 10 would be considered a discount of 10%.
                    </p>
                    <p className="mt-2 text-muted">
                        This promotion code is applied only to line-items, and not additional fees or taxes. When calculating taxes, the system applies the discount proportionately across all line items. Care should be taken to avoid creating promotion codes that would result in a transaction of less than $0.50 as neither Stripe nor the system supports this right now.
                    </p>

                    <div className="d-flex">
                        <button className="btn btn-primary ms-auto" type="submit" disabled={isSubmitting || !isValid || isValidating}>
                            Create
                        </button>
                    </div>

                </div>

            </form>
        </div>
    )
}
