import { useMemo } from "react";
import { useOrderContext } from "../../provider/use-order-context";
import { LineItemsSummarySlim } from "./line-items-summary";

export function LineItemsHeader({ open }: { open: boolean }) {

    let { state: { order } } = useOrderContext();
    let lineItems = useMemo(() => (order?.lineItems ?? []).toSorted((a, b) => a.sortOrder < b.sortOrder ? -1 : 1), [order?.lineItems]);

    // const maxItems = Math.min(8, lineItems.length);

    // let summaryLineItems = lineItems.length > maxItems ? lineItems.slice(0, maxItems - 1) : lineItems;
    // let remainingItems = lineItems.length > maxItems ? lineItems.length - maxItems : 0;

    return (
        <div className="w-100 d-flex flex-column flex-fill">
            <div className="d-flex flex-column">
                Party Items
                {!open && (
                    <div className="text-muted">
                        {lineItems.length} items
                    </div>
                )}
            </div>
            {!open && (
                <div className="w-100 d-flex flex-wrap mt-3">
                    <LineItemsSummarySlim />
                </div>
            )}
            {/* <div className="ms-3 d-flex flex-fill pe-3" style={{ transition: "opacity 300ms ease-in-out", opacity: open ? 0 : 1 }} >
                <div className="position-relative w-100">
                    {summaryLineItems.filter(x => x.primaryImageLink).map((x, i) => (
                        <div key={x.productId} className="position-absolute" style={{ right: `${(maxItems - i - 1) * 28}px` }}>
                            <SimpleTooltip title={x.productName}>
                                <img className="img-fluid rounded-circle border bg-body-tertiary p-1"
                                    src={x.primaryImageLink}
                                    alt={x.productName}
                                    style={{ objectFit: "cover", height: 48, width: 48, }} />
                            </SimpleTooltip>
                        </div>
                    ))}
                    {remainingItems > 0 && (
                        <div className="position-absolute" style={{ right: 0 }}>
                            <SimpleTooltip title={`${remainingItems} other items...`}>
                                <div className="rounded-circle border bg-body-tertiary p-1 d-flex justify-content-center align-items-center" style={{ height: 48, width: 48 }}>
                                    <div>
                                        + {remainingItems}
                                    </div>
                                </div>
                            </SimpleTooltip>
                        </div>
                    )}
                </div>

            </div> */}
        </div>
    )
}
