import { LightWeightVendorGrid } from "./light-weight-vendor-grid";

export function LightWeightVendorList() {

    return (
        <div className="d-flex flex-fill">
            <LightWeightVendorGrid />
        </div>
    )
}
