import { useCallback, useState } from "react";
import { groupBy } from "../../../../../common/utilities/collection-helpers";
import { useDebouncedEffect } from "../../../../../common/utilities/use-debounced-effect";
import { GetProductItem } from "../../../../../services/public/ordering/get-products";
import { ProductItem } from "./product-item";
import "./products-list.scss";

type ProductsListPropType = {
    availableProducts: GetProductItem[];
}

export const ProductsList = ({ availableProducts }: ProductsListPropType) => {
    let [filteredProducts, setFilteredProducts] = useState<GetProductItem[]>(availableProducts || []);
    let [searchText, setSearchText] = useState<string>("");

    const filterProducts = useCallback(() => {
        let filteredProducts = availableProducts;
        if (searchText.length > 0) {
            filteredProducts = availableProducts?.filter((product) => {
                return product.name.toLowerCase().includes(searchText.toLowerCase());
            });
        }
        filteredProducts = filteredProducts.sort((a, b) => a.sortOrder < b.sortOrder ? 1 : -1);
        setFilteredProducts(filteredProducts);
    }, [availableProducts, searchText]);

    useDebouncedEffect(() => {
        filterProducts();
    }, [filterProducts], 250);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    return (
        <div className="container products-list">
            <h3>Available Products {filteredProducts.length > 0 ? `(${filteredProducts.length})` : ""}</h3>
            <div className="row g-3 align-items-stretch">
                <header className="col-12 d-flex flex-column align-items-center row gy-2">
                    <section className="col-12 col-md-6">
                        <input type="search" className="form-control" placeholder="Search Products. Start Typing..." value={searchText} onChange={handleSearchChange} />
                    </section>
                </header>
                {filteredProducts?.length === 0 ? (
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        <div className="py-5 fst-italic">No products found</div>
                    </div>
                ) : (
                    Object.entries(groupBy(filteredProducts, ({ optionType }) => optionType || "Services")).map(([optionType, products]) => (
                        <div className="col-12 mt-4" key={optionType}>
                            <h4 className="text-capitalize option-type-title">{optionType}</h4>
                            <div className="row g-3">
                                {
                                    products?.map((product) => (
                                        <ProductItem
                                            product={product}
                                            key={product.id} />
                                    ))
                                }
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};
