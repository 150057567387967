import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type EnableProductInput = {
    productId: string;
};

export const EnableProductCommand: ApiHandlerWithAuth<EnableProductInput, void> = {
    handle: async ({ productId }, authHeader) => {
        let response = await fetch(`/api/v1/admin/products/${productId}/enable`, {
            method: "POST",
            headers: getDefaultHeaders(authHeader)
        });

        if (!response.ok) {
            throw response;
        }
    }
}
