import queryString from "query-string";
import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type GetLightWeightVendorsInput = {
    offset?: number;
    count?: number;
};

export const GetLightWeightVendors: ApiHandlerWithAuth<GetLightWeightVendorsInput, GetLightWeightVendorsResult> = {
    handle: async (input, authHeader) => {
        let response = await fetch(`/api/v1/admin/light-weight-vendors?${queryString.stringify(input)}`, {
            method: "GET",
            headers: getDefaultHeaders(authHeader)
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as GetLightWeightVendorsResult;
    }
}

export type GetLightWeightVendorsResult = {
    results: LightWeightVendor[];
    hasMore: boolean;
};

export type LightWeightVendor = {
    lightWeightVendorId: string;
    name: string;
    description?: string;
    emailAddress: string;
    phoneNumber?: string;
    disabled: boolean;
}
