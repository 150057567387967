import { Address } from "../../services/public/ordering/get-order-by-id";

export const addressToString = (address: Partial<Address>) => {
  const {
    streetLine1 = "",
    streetLine2 = "",
    city = "",
    state = "",
    zipCode = "",
  } = address;
  const hydrateAddressItem = (item: string | undefined) => {
    return item ? `${item} ` : "";
  };
  return `${hydrateAddressItem(streetLine1)}${hydrateAddressItem(
    streetLine2
  )}${hydrateAddressItem(city)}${hydrateAddressItem(state)}${hydrateAddressItem(
    zipCode
  )}`;
};
