import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type CreateProductImageInput = {
    productId: string;
    file: File;
};

export const CreateProductImageCommand: ApiHandlerWithAuth<CreateProductImageInput, CreateProductImageResult> = {
    handle: async ({ productId, file }, authHeader) => {

        let body = new FormData();
        body.append("file", file);

        let response = await fetch(`/api/v1/admin/products/${productId}/images`, {
            method: "POST",
            headers: {
                "Authorization": authHeader!
            },
            body: body
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as CreateProductImageResult;
    }
}

export type CreateProductImageResult = {
    productImageId: string;
}
