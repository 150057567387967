import { ColDef } from "ag-grid-community";
import clipboard from "clipboardy";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { renderToStaticMarkup } from "react-dom/server";
import { GetLightWeightVendors } from "../../../../../common/api/handlers/admin/light-weight-vendors/get-light-weight-vendors";
import { Order } from "../../../../../common/api/handlers/admin/orders/get-order-by-id";
import { GetOrderLineItems, LineItem, LineItemVendor } from "../../../../../common/api/handlers/admin/orders/get-order-line-items";
import { UpdateLineItem } from "../../../../../common/api/handlers/admin/orders/update-line-item";
import { useApiHandlerWithAuth } from "../../../../../common/api/use-api-handler-with-auth";
import { useQueryWithAuth } from "../../../../../common/api/use-query-with-auth";
import { DataGrid } from "../../../../../common/utilities/data-grid";
import { FormatDateWithAgo } from "../../../../../common/utilities/formatters/format-date";
import { Money } from "../../../../../common/utilities/formatters/money";
import { GetProductKind } from "../../../../../services/public/ordering/get-products";
import { LightWeightVendorSelector } from "../../../light-weight-vendors/shared/light-weight-vendor-selector";
import { FormatLineItemAdminState, FormatLineItemAdminStateLabel, LineItemAdminState } from "../../shared/format-admin-line-item-status";

export function LineItemsSection(order: Order) {

    let columns = useMemo<ColDef<LineItem>[]>(() => [
        { field: "adminState", headerName: "", initialWidth: 138, cellRenderer: LineItemAdminStateRenderer },
        { field: "adminAssignedVendor", headerName: "Vendor", initialWidth: 110, cellRenderer: VendorRenderer },
        { field: "adminComment", headerName: "Notes", initialWidth: 145, cellRenderer: CommentRenderer },
        { field: "productName", headerName: "Product", initialWidth: 275, cellRenderer: ProductRenderer },
        { field: "count", headerName: "Count", initialWidth: 97, cellRenderer: CountRenderer },
        { field: "kind", headerName: "Kind", initialWidth: 92, cellRenderer: KindRenderer },
        { field: "totalPrice", headerName: "Total", initialWidth: 124, cellRenderer: MoneyRenderer },
        { field: "comment", headerName: "Comment", cellRenderer: CommentRenderer, initialWidth: 145 },
        { field: "arrivalDateTime", headerName: "Arrive On", cellRenderer: DateTimeRenderer },
        { headerName: "", cellRenderer: AdminColumnRenderer, pinned: "right", initialWidth: 100 },
    ], []);

    let { data, isError, isLoading, refresh } = useQueryWithAuth(
        GetOrderLineItems,
        { orderId: order.orderId },
        [order.orderId]
    );

    return (
        <DataGrid columnDefs={columns}
            gridOptions={{ suppressCellFocus: true }}
            suppressColumnVirtualisation
            detailRowAutoHeight
            domLayout="autoHeight"
            rowHeight={50}
            rowData={data?.results ?? []}
            containerClassName="flex-fill"
            context={{ order, refresh }}
        />
    )
}

function ProductRenderer({ data }: { data?: LineItem }) {
    if (!data) {
        return <>-</>
    }
    return (
        data.kind == "addOn"
            ? <i>{data.productName}</i>
            : <>{data.productName}</>
    )
}

function KindRenderer({ value }: { value?: GetProductKind }) {
    if (!value) {
        return <>-</>
    }
    return (
        <div className="text-capitalize">{value}</div>
    )
}

function CommentRenderer({ value }: { value?: string }) {
    if (value) {
        return <>{value}</>
    }
    return <span className="text-muted">-</span>
}

function VendorRenderer({ value }: { value?: LineItemVendor }) {
    if (value) {
        return <>
            <a href={`mailto:${value.emailAddress}`} className="text-dark">{value.name}</a>
        </>
    }
    return <span className="text-muted">-</span>
}

function DateTimeRenderer({ value }: { value?: string }) {
    return (
        <FormatDateWithAgo dateTime={value} />
    )
}

function MoneyRenderer({ value }: { value?: number }) {
    return (
        <Money amount={value} />
    )
}

function CountRenderer({ value, data }: { value?: number, data?: LineItem }) {
    if (!value || data?.kind == "addOn") {
        return <span className="text-muted">-</span>
    }

    return value == 1
        ? (
            <>
                <span className="fw-semibold">{value}</span> {data?.productUnit}
            </>
        )
        : (
            <>
                <span className="fw-semibold">{value}</span> {data?.productUnitPluralized}
            </>
        )
}

function LineItemAdminStateRenderer({ value }: { value?: LineItemAdminState }) {
    return (
        <div className="d-flex align-items-center h-100" title={value}>
            <FormatLineItemAdminState state={value} />
            <div className="ms-2"></div>
            <FormatLineItemAdminStateLabel state={value} />
        </div>
    )
}

function AdminColumnRenderer({ data, context }: { data?: LineItem, context?: { order: Order, refresh: () => void } }) {

    let [showModal, setShowModal] = useState(false);

    let [vendorId, setVendorId] = useState<string>();
    let [adminComment, setAdminComment] = useState<string>();
    let [adminState, setAdminState] = useState<LineItemAdminState>("pending");

    useEffect(() => {
        setVendorId(data?.adminAssignedVendor?.lightWeightVendorId);
        setAdminComment(data?.adminComment);
        setAdminState(data?.adminState ?? "pending");
    }, [data?.adminAssignedVendor?.lightWeightVendorId, data?.adminComment, data?.adminState]);

    useEffect(() => {
        if (vendorId && adminState == "pending") {
            setAdminState("vendorAssigned");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vendorId]);

    let updateHandler = useApiHandlerWithAuth(UpdateLineItem)
    let save = useCallback(async () => {
        setShowModal(false);
        if (context?.order.orderId && data?.lineItemId) {
            await updateHandler({
                orderId: context.order.orderId,
                lineItemId: data.lineItemId,
                state: adminState,
                comment: adminComment,
                lightWeightVendorId: vendorId
            });
            context.refresh();
        }
    }, [context, data?.lineItemId, updateHandler, adminState, adminComment, vendorId])

    return (
        <div className="d-flex align-items-center h-100">
            <div className="btn btn-sm btn-outline-primary ms-auto" onClick={() => setShowModal(true)}>Update</div>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Update {data?.productName}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column p-0">

                    <div className="row g-0">
                        <div className="col-6">
                            <div className="border-bottom border-end p-3">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="sub-title">
                                            Product
                                        </div>
                                        <div>
                                            {data?.productName ?? "-"} x {data?.count ?? "-"}
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-6">
                                        <div className="sub-title">
                                            Total Price
                                        </div>
                                        <div>
                                            <Money amount={data?.totalPrice} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="sub-title">
                                            Arrival Date/Time
                                        </div>
                                        <div>
                                            <FormatDateWithAgo dateTime={data?.arrivalDateTime} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <div className="sub-title">
                                            Comment
                                        </div>
                                        <div>
                                            {data?.comment ?? "-"}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="px-3 p-3 border-end">
                                <div className="sub-title">Vendor</div>
                                <LightWeightVendorSelector value={vendorId} onChange={setVendorId} />

                                <div className="sub-title">Admin State</div>
                                <select className="form-select" value={adminState} onChange={e => setAdminState(e.target.value as LineItemAdminState)}>
                                    <option value="pending">No Assignment</option>
                                    <option value="vendorAssigned">Vendor Assigned</option>
                                    <option value="vendorConfirmed">Vendor Confirmed</option>
                                    <option value="fulfilled">Vendor Fulfilled</option>
                                </select>

                                <div className="sub-title mt-3">Admin Notes</div>
                                <textarea className="form-control"
                                    value={adminComment}
                                    onChange={e => setAdminComment(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-6 p-3 d-flex flex-column">
                            <div className="sub-title">Email Template</div>
                            {!vendorId && (
                                <div className="d-flex flex-fill align-items-center justify-content-center text-muted">
                                    No Vendor Selected
                                </div>
                            )}
                            {!!vendorId && (
                                <div className="d-flex flex-fill">
                                    {!!data && !!context?.order && <RenderEmailBoilerplate
                                        vendorId={vendorId}
                                        lineItem={data}
                                        order={context?.order} />}
                                </div>
                            )}
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <div className="btn btn-primary" onClick={save}>
                        Save
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

type RenderEmailBoilerplateProps = {
    vendorId: string;
    lineItem: LineItem;
    order: Order;
}

function RenderEmailBoilerplate({ lineItem, vendorId, order }: RenderEmailBoilerplateProps) {

    // TODO Super hacky here...

    let { data, isLoading, isError } = useQueryWithAuth(GetLightWeightVendors, {}, []);

    let selectedVendor = useMemo(() => {
        return data?.results.find(x => x.lightWeightVendorId == vendorId)
    }, [data?.results, vendorId]);

    let arrivalDateTime = useMemo(() => {
        if (lineItem.arrivalDateTime) {
            return DateTime.fromISO(lineItem.arrivalDateTime);
        }
        return DateTime.fromISO(order.eventSchedule.startOn);
    }, [lineItem.arrivalDateTime, order.eventSchedule.startOn])

    let template = useMemo(() => renderToStaticMarkup(
        <>
            Product: {lineItem.productName}
            <br />
            Units: {lineItem.count} {lineItem.count == 1 ? lineItem.productUnit : lineItem.productUnitPluralized}
            <br />
            <br />
            Arrival Date: {arrivalDateTime.toLocaleString(DateTime.DATE_MED)} ({arrivalDateTime.weekdayShort})
            <br />
            Arrival Time: {arrivalDateTime.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)}
            <br />
            <br />
            Address:
            <br />
            {order.eventAddress.streetLine1}
            <br />
            {order.eventAddress.streetLine2 ?? "-"}
            <br />
            {order.eventAddress.city}, {order.eventAddress.state} {order.eventAddress.zipCode}
            <br />
            <br />
            Setup Type: {order.eventAddress.setupTypeDescription}
            <br />
            Location Type: {order.eventAddress.locationTypeDescription}
            <br />
            Comment: {lineItem.comment ?? "-"}
            <br />
        </>
    ), [arrivalDateTime, lineItem.comment, lineItem.count, lineItem.productName, lineItem.productUnit, lineItem.productUnitPluralized, order.eventAddress.city, order.eventAddress.locationTypeDescription, order.eventAddress.setupTypeDescription, order.eventAddress.state, order.eventAddress.streetLine1, order.eventAddress.streetLine2, order.eventAddress.zipCode]);

    let [copied, setCopied] = useState(false);
    let copyToClipboard = useCallback(async () => {
        let formattedTemplate = template.replaceAll("<br/>", "\r\n")
        await clipboard.write(formattedTemplate);
        setCopied(true);
    }, [template])

    if (isLoading) {
        return <>Generating...</>
    }

    if (isError || !selectedVendor) {
        return <>Error</>
    }


    return (
        <div className="d-flex flex-column flex-fill">

            <div className="flex-fill position-relative">
                <code className="top-0 bottom-0 start-0 end-0 text-dark border rounded bg-body-tertiary overflow-scroll position-absolute">
                    <pre className="mb-0 p-3 h-100" dangerouslySetInnerHTML={{ __html: template }} />
                </code>
                <div className="position-absolute top-0 end-0">
                    <button className="btn btn-primary mt-2 me-2" onClick={copyToClipboard}>
                        {copied ? "Copied" : "Copy"}
                    </button>
                </div>
            </div>

            <div className="mt-2">
                <div className="sub-title">To</div>
                <a href={`mailto:${selectedVendor.emailAddress}`}>{selectedVendor.emailAddress}</a>
            </div>

        </div>
    )
}
