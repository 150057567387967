// Restricted to  *.partyeezy.com/partyeezy.com.
// TODO, Should let the backend control this...

import { useMemo } from "react";
import { Address } from "../../../services/public/ordering/get-order-by-id";

// Also should be created by TF...
const MapsApiKey = "AIzaSyA1WHUQ78q60VVpmkLPI4FbIDm8WC9Kpg4";

export function AddressMap({ address }: { address: Address }) {

    let query = useMemo(() => {
        let query = address.streetLine1;
        if (address.streetLine2) {
            query += `, ${address.streetLine2}`
        }
        query += `, ${address.city}, ${address.state}, ${address.county} ${address.zipCode}`
        return encodeURI(query);
    }, [address.city, address.county, address.state, address.streetLine1, address.streetLine2, address.zipCode]);

    return (
        <iframe
            width="100%"
            className="overflow-hidden border rounded"
            style={{ border: 0, aspectRatio: "3 / 2" }}
            referrerPolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/place?key=${MapsApiKey}&q=${query}`}>
        </iframe>
    )
}
