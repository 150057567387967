import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type CreateLightWeightVendorsInput = {
    name: string;
    description?: string;
    emailAddress: string;
    phoneNumber?: string;
};

export const CreateLightWeightVendors: ApiHandlerWithAuth<CreateLightWeightVendorsInput, CreateLightWeightVendorsResult> = {
    handle: async (input, authHeader) => {
        let response = await fetch("/api/v1/admin/light-weight-vendors", {
            method: "POST",
            headers: getDefaultHeaders(authHeader),
            body: JSON.stringify(input)
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as CreateLightWeightVendorsResult;
    }
}

export type CreateLightWeightVendorsResult = {
    lightWeightVendorId: string;
};
