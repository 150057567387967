import { RouteObject } from "react-router-dom";
import { Home } from "./home/home";
import { VendorLayout } from "../../common/components/layouts/vendor-layout/vendor-layout";

export const vendorPaths = {
    home: "/vendor/home"
};

export const vendorRoutes: RouteObject[] = [
    {
        Component: VendorLayout,
        children: [
            {
                path: vendorPaths.home,
                Component: Home
            }
        ]
    },
];
