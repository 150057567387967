export const signUpFormText = {
    caption: "Let's get this party started!",
    fullName: "Full Name",
    emailAddress: "Email Address",
    phoneNumber: "Phone Number",
    zipCode: "Zip Code",
    submit: "Submit",
    validationMessages: {
        emailAddress: {
            pattern: "Email Address is invalid"
        },
        phoneNumber: {
            pattern: "Phone Number is invalid"
        },
        zipCode: {
            pattern: "Zip Code is invalid"
        }
    },
    submitSuccess: "Thank you for your interest in partying with us! We will be in touch soon."
};
