import clsx from "clsx";
import { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { MdOutlineErrorOutline } from "react-icons/md";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useFrontendConfiguration } from "../../../common/providers/frontend-configuration/use-frontend-configuration";
import { pushGTagEvent } from "../../../common/utilities/gtag";
import { LoadingIcon } from "../../../common/utilities/loading-icon";
import { plannerPaths } from "../planner.routes";
import { useOrderContext } from "./provider/use-order-context";

type PlaceOrderButtonProps = {
    className?: string;
}

export function PlaceOrderButton({ className }: PlaceOrderButtonProps) {
    let { state: { termsAccepted } } = useOrderContext();
    let { reset, submit, state } = useCheckout();
    let { configuration } = useFrontendConfiguration();

    const buttonClassName = clsx("btn btn-primary", className);

    return (
        <>
            {!configuration?.isDemo && (
                <button type="submit"
                    className={buttonClassName}
                    disabled={!termsAccepted || state.type != "idle"}
                    onClick={submit}>
                    Place Order
                </button>
            )}
            {!!configuration?.isDemo && (
                <button type="submit"
                    className={buttonClassName}
                    disabled>
                    Ordering Disabled, Demo Mode
                </button>
            )}
            <Modal show={state.type == "submitting"} centered contentClassName="bg-transparent border-0 align-items-center">
                <div className="d-flex align-items-center">
                    <LoadingIcon />
                </div>
            </Modal>
            <Modal show={state.type == "in-error"} centered onHide={reset}>
                <div className="modal-header">
                    <h5 className="modal-title">Submission Error</h5>
                </div>
                <div className="modal-body">
                    <div className="d-flex align-items-center justify-content-center">
                        <MdOutlineErrorOutline style={{ width: 64, height: 64 }} />
                    </div>
                    <p className="text-center mt-3">
                        Oops, an error occurred while submitting the order.
                    </p>
                    <p className="text-center">
                        {state.type == "in-error" ? state.humanMessage : "Generic Error"}
                    </p>
                    <p className="text-muted text-center" style={{ fontSize: "0.9rem" }}>
                        Having trouble? We are always here at <a href="mailto:hello@partyeezy.com">hello@partyeezy.com</a>.
                    </p>
                </div>
                <div className="modal-footer">
                    <button type="button"
                        className="btn btn-primary d-flex align-items-center"
                        data-bs-dismiss="modal"
                        onClick={reset}>
                        Try Again
                    </button>
                </div>
            </Modal>
        </>
    )
}

type CheckoutSubmissionContext =
    | { type: "idle" }
    | { type: "awaiting-redirect" }
    | { type: "is-loading" }
    | { type: "submitting" }
    | { type: "in-error", humanMessage?: string; }

export function useCheckout() {

    const stripe = useStripe();
    const elements = useElements();
    let { state: { order }, actions: { pay } } = useOrderContext();

    let isLoading = useMemo(() => !stripe || !elements || !order?.orderId, [elements, order, stripe]);
    let totalIsValid = useMemo(() => !!order?.totalPriceIncludingTaxes, [order?.totalPriceIncludingTaxes])

    let confirmPaymentHandler = useCallback(async () => {

        if (!stripe || !elements || !order?.orderId || isLoading) {
            throw new Error("Checkout context is still loading.");
        }

        let elementsResult = await elements.submit();
        if (elementsResult.error) {
            console.warn("Stripe reports payment details are invalid.", elementsResult.error)
            return {
                success: false,
                humanMessage: elementsResult.error.message
            }
        }

        let paymentIntentResult = await pay();
        if (paymentIntentResult.type == "failure") {
            console.warn("Failure while creating payment intent.")
            return {
                success: false,
                humanMessage: "Order validation failure, check your order details and try again."
            }
        }

        const result = await stripe.confirmPayment({
            elements,
            clientSecret: paymentIntentResult.stripeClientSecret,
            confirmParams: {
                return_url: `${window.location.origin}${plannerPaths.status.replace(":orderId", order.orderId)}`,
                payment_method_data: {
                    billing_details: {
                        email: order.contactDetails?.emailAddress,
                        name: order.contactDetails?.name,
                        phone: order.contactDetails?.phoneNumber,
                        address: {
                            city: order.billingAddress?.city,
                            country: "US",
                            line1: order.billingAddress?.streetLine1,
                            line2: order.billingAddress?.streetLine2,
                            state: order.billingAddress?.state,
                            postal_code: order.billingAddress?.zipCode
                        }
                    }
                }
            },
        });

        if (result.error) {
            console.warn("Failure confirming payment with Stripe.", result.error);

            pushGTagEvent("payment_failure");

            return {
                success: false,
                humanMessage: result.error.message
            };
        }

        console.log("Payment intent created and confirmed by Stripe, assuming success.");

        pushGTagEvent("payment_success");

        return {
            success: true
        }
    }, [elements, order, pay, stripe, isLoading]);

    let [submissionState, setSubmissionState] = useState<CheckoutSubmissionContext>({ type: "idle" });

    let submitHandler = useCallback(async () => {
        setSubmissionState({ type: "submitting" });
        try {
            let result = await confirmPaymentHandler();
            if (!result.success) {
                setSubmissionState({
                    humanMessage: result.humanMessage,
                    type: "in-error"
                })
            }
        } catch (error) {
            setSubmissionState({
                humanMessage: "An error occurred while submitting the payment.",
                type: "in-error"
            })
        }
    }, [confirmPaymentHandler])

    let effectiveState = useMemo<CheckoutSubmissionContext>(() => isLoading || !totalIsValid
        ? { type: "is-loading" }
        : submissionState,
        [isLoading, submissionState, totalIsValid])

    return {
        total: order?.totalPriceIncludingTaxes ?? 0,
        submit: submitHandler,
        reset: () => setSubmissionState({ type: "idle" }),
        state: effectiveState
    }
}
