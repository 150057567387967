import { HttpMethodEnum } from "../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../common/utilities/get-auth-request-header";

export type GetLocationTypesResponse = {
    id: string;
    description: string;
};


export const getLocationTypes = async (signal?: AbortSignal): Promise<GetLocationTypesResponse[]> => {
    const url = "/api/v1/order-placement/location-types";
    let response: Response = await fetch(`${url}`, {
        signal,
        method: HttpMethodEnum.GET,
        headers: getAuthRequestHeaders()
    });

    if (!response?.ok) {
        throw response;
    }

    return await response.json() as GetLocationTypesResponse[];
};
