import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type EditProductInput = {
    productId: string;
    name: string;
    productDescription: string;
    vendorDescription: string;
    published?: boolean;
    capacity: number;
    categoryId?: string;
};

export const EditProductCommand: ApiHandlerWithAuth<EditProductInput, void> = {
    handle: async (query, authHeader) => {
        const { productId, ...body } = query;
        let response = await fetch(`/api/v1/admin/products/${productId}`, {
            method: "PUT",
            headers: getDefaultHeaders(authHeader),
            body: JSON.stringify({
                ...body
            })
        });

        if (!response.ok) {
            throw response;
        }
    }
};
