import clsx from "clsx";
import { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { ReactIcon } from "../../../../../common/components/react-icon/react-icon";
import { LineItem } from "../../../../../services/public/ordering/get-order-by-id";
import { GetProductItem } from "../../../../../services/public/ordering/get-products";
import { useOrderContext } from "../../provider/use-order-context";
import { ProductThumbnail } from "./product-thumbnail";

type ChangeLineItemPropsType = {
    lineItem: LineItem;
    className?: string;
    products: GetProductItem[];
};

export const ChangeLineItem = ({ lineItem, className, products }: ChangeLineItemPropsType) => {
    const { actions: { setLineItems }, state: { order, isLoading } } = useOrderContext();
    const { lineItems } = order ?? { lineItems: undefined };
    const [showChangeLineItemModal, setShowChangeLineItemModal] = useState(false);
    const { productOptionType } = lineItem ?? { productOptionType: undefined };
    let [selectedProduct, setSelectedProduct] = useState<GetProductItem | undefined>(undefined);

    const title = useMemo(() => {
        return `Change ${productOptionType} Type`;
    }, [productOptionType]);

    if (!productOptionType) {
        return null;
    }


    const handleChangeLineItemClick = () => {
        setShowChangeLineItemModal(true);
    };

    const handleClose = () => {
        setShowChangeLineItemModal(false);
    }

    const handleSelection = (product: GetProductItem) => {
        if (selectedProduct?.id === product.id) {
            setSelectedProduct(undefined);
            return;
        }
        setSelectedProduct(product);
    };

    const handleUpdate = async () => {
        if (selectedProduct) {
            try {
                const controller = new AbortController();
                const updatedLineItems = lineItems?.map((item) => {
                    if (item.productId == lineItem.productId) {
                        return {
                            productId: selectedProduct?.id || "",
                            count: item.count,
                            childrenLineItems: []
                        };
                    }
                    return {
                        productId: item.productId,
                        count: item.count,
                        childrenLineItems: item.childrenLineItems
                    };
                }) ?? [];
                await setLineItems(updatedLineItems, controller.signal);
                handleClose();
            } catch (error) {
                console.error(error);
            }
        }
    }

    return (
        <>
            <button className={clsx("btn btn-outline-success text-capitalize", className)} onClick={handleChangeLineItemClick}>
                {title}
            </button>
            <Modal show={showChangeLineItemModal} onHide={handleClose} size="lg" scrollable>
                <Modal.Header closeButton>
                    <Modal.Title className="text-capitalize">{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Current Selection</h4>
                    <article className="row g-0 mb-3">
                        <div className="col-12 product-item">
                            <div className="card">
                                <div className="row gx-3">
                                    <div className="col-6 col-lg-2 d-flex align-items-center">
                                        <ProductThumbnail src={lineItem.primaryImageLink} name={lineItem.productName} className="m-3 me-md-0 rounded-start" />
                                    </div>
                                    <div className="col-12 col-lg-10">
                                        <div className="card-body d-flex flex-column p-2 w-80">
                                            <h5 className="card-title">{lineItem.productName}</h5>
                                            <div className="d-flex align-items-center mb-2">
                                                <span className="badge rounded-pill py-1 text-bg-primary text-white me-2 text-capitalize">{lineItem.kind}</span>
                                            </div>
                                            <p className="card-text">{lineItem.productDescription || ""}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <h4>Available Options</h4>
                    <section className="row gy-2 mb-3">
                        {products.map((product) => {
                            const isSelectedProduct = selectedProduct?.id === product.id;
                            return (
                                <div
                                    className="col-12 product-item"
                                    role="button"
                                    key={product.id}
                                    aria-disabled={isLoading}
                                    onClick={() => handleSelection(product)}>
                                    <div className="card">
                                        <div className="row gx-3">
                                            <div className="col-6 col-lg-2 d-flex align-items-center">
                                                <ProductThumbnail src={product.primaryImageLink} name={product.name} className="m-3 me-md-0 rounded-start" />
                                            </div>
                                            <div className="col-12 col-lg-10 d-flex align-items-center">
                                                <div className="card-body d-flex flex-column w-80 p-2">
                                                    <section className="d-flex align-items-center mb-2">
                                                        <h5 className="card-title mb-0">{product.name}</h5>
                                                        {isSelectedProduct && (
                                                            <span className="badge rounded-pill py-1 text-bg-primary text-white ms-3">
                                                                <ReactIcon icon={FaCheck} className="me-1" />
                                                                Selected
                                                            </span>
                                                        )}
                                                    </section>
                                                    <p className="card-text">{product.description || ""}</p>
                                                </div>
                                                <button className="btn ms-auto me-3 btn-outline-primary" disabled={isLoading}>
                                                    {isSelectedProduct ? "Unselect" : "Select"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={handleUpdate} disabled={!selectedProduct || isLoading}>Confirm Update</button>
                    <button className="btn btn-outline-secondary ms-2" onClick={handleClose}>Cancel</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
