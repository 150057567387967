import { HttpMethodEnum } from "../../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../../common/utilities/get-auth-request-header";

export type GetAddressByPlaceIdRequest = {
    placeId: string;
};

export type GetAddressByPlaceIdResponse = {
    streetLine1?: string;
    streetLine2?: string;
    city?: string;
    state?: string;
    county?: string;
    zipCode?: string;
};

export const getAddressByPlaceId = async (input: GetAddressByPlaceIdRequest, signal?: AbortSignal): Promise<GetAddressByPlaceIdResponse> => {
    const url = `/api/v1/addresses/places/${input.placeId}`;
    let response: Response = await fetch(`${url}`, {
        signal,
        method: HttpMethodEnum.GET,
        headers: getAuthRequestHeaders()
    });

    if (!response?.ok) {
        throw response;
    }

    return await response.json() as GetAddressByPlaceIdResponse;
};
