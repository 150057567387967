import { ColDef } from "ag-grid-community";
import { useMemo } from "react";
import { GetOrderAdditionalFees } from "../../../../../common/api/handlers/admin/orders/get-order-additional-fees";
import { useQueryWithAuth } from "../../../../../common/api/use-query-with-auth";
import { DataGrid } from "../../../../../common/utilities/data-grid";
import { Money } from "../../../../../common/utilities/formatters/money";
import { getAdditionalFeeName } from "../../../../../common/utilities/orders/get-additional-fee-name";
import { AdditionalFee } from "../../../../../services/public/ordering/get-order-by-id";

export function AdditionalFeesSection({ orderId }: { orderId: string }) {

    let columns = useMemo<ColDef<AdditionalFee>[]>(() => [
        { field: "name", headerName: "Name", cellRenderer: AdditionalFeeNameRenderer, flex: 2 },
        { field: "comment", headerName: "Comment", cellRenderer: CommentRenderer, flex: 2 },
        { field: "totalPrice", headerName: "Total Price", cellRenderer: MoneyRenderer, flex: 1 },
    ], []);

    let { data, isError, isLoading, refresh } = useQueryWithAuth(GetOrderAdditionalFees, { orderId }, [orderId]);

    return (
        <DataGrid columnDefs={columns}
            gridOptions={{ suppressCellFocus: true }}
            suppressColumnVirtualisation
            detailRowAutoHeight
            domLayout="autoHeight"
            rowHeight={50}
            rowData={data?.results ?? []}
            containerClassName="flex-fill"
            context={{ orderId, refresh }}
        />
    )
}

function AdditionalFeeNameRenderer({ value }: { value?: string }) {
    if (value) {
        return <>{getAdditionalFeeName(value)}</>
    }
    return <span className="text-muted">-</span>
}

function MoneyRenderer({ value }: { value?: number }) {
    return (
        <Money amount={value} />
    )
}

function CommentRenderer({ value }: { value?: string }) {
    if (value) {
        return <>{value}</>
    }
    return <span className="text-muted">-</span>
}
