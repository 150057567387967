import clsx from "clsx";
import Select, { Options } from "react-select";
import { useWindowSize } from "@uidotdev/usehooks";

export const PartyTypeSelect: Select = (props) => {

    let { width } = useWindowSize();
    let isBsLg = (width ?? 0) >= 992;

    return (
        <div className={clsx("form-control rounded-4")} style={{
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: 0,
            paddingLeft: 0,
            height: "100%"
        }}>
            <Select
                styles={{
                    container: (base, state) => ({
                        ...base,
                        minWidth: state.hasValue
                            ? `min(${getValueWidth(state.getValue())}px, var(--placeholder-width))`
                            : state.isFocused ? "calc(var(--placeholder-width) + 12px)" : "var(--placeholder-width)",
                        transition: "all var(--transition)"
                    }),
                    option: (base, { isDisabled, isFocused, isSelected }) => ({
                        ...base,
                        backgroundColor: isSelected
                            ? "var(--bs-primary)"
                            : isFocused
                                ? "var(--bs-secondary-bg)"
                                : "transparent",
                        color: isDisabled
                            ? "var(--bs-black)"
                            : isSelected
                                ? "var(--bs-white)"
                                : "inherit",
                        ":active": {
                            backgroundColor: !isDisabled
                                ? isSelected
                                    ? "var(--bs-primary)"
                                    : "var(--bs-primary-border-subtle)"
                                : undefined,
                        },
                    }),
                    control: (base, state) => ({
                        ...base,
                        border: "0",
                        alignItems: "end",
                        outline: 0,
                        boxShadow: "none",
                        minHeight: "0",
                        backgroundColor: state.isFocused ? "var(--bs-white)" : "transparent",
                        transition: "all var(--transition)",
                        borderRadius: "var(--bs-border-radius-xl)"
                    }),
                    indicatorsContainer: (base) => ({
                        ...base,

                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        // display: "none"
                        paddingLeft: 0
                    }),
                    indicatorSeparator: (base) => ({
                        ...base,
                        display: "none"
                    }),
                    clearIndicator: (base) => ({
                        ...base,
                        marginRight: "1.4rem",
                        color: "#7f7f7f"
                    }),
                    loadingIndicator: (base) => ({
                        ...base,
                        marginRight: "2.4rem",
                        color: "#7f7f7f"
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        paddingRight: 0,
                        margin: isBsLg ? "1rem 0" : ""
                    }),
                    menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999
                    }),
                    singleValue: (base) => ({
                        ...base,
                        marginLeft: "0.5rem",
                        marginRight: "0.5rem"
                    }),
                }}
                {...props}
            />
        </div>
    );
}

function getValueWidth(options: Options<unknown>) {

    if (options.length != 1) {
        return undefined;
    }

    let strValue = (options[0] as { label: string }).label;
    if (!strValue?.length) {
        return undefined;
    }
    return getTextWidth(strValue, "400 1rem 'Raleway Variable'");
}


function getTextWidth(text: string, font?: string) {
    // https://stackoverflow.com/a/58705306

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    context!.font = font || getComputedStyle(document.body).font;

    return context!.measureText(text).width + 50;
}
