import { useMemo, useState } from "react";
import { AnimatedCheckbox } from "../../../../../../common/utilities/animated-checkbox";
import { Money } from "../../../../../../common/utilities/formatters/money";
import { ChildrenLineItem, LineItem } from "../../../../../../services/public/ordering/get-order-by-id";
import { useOrderContext } from "../../../provider/use-order-context";
import { ProductThumbnail } from "../product-thumbnail";
import "./addon-card.scss";

type AddOnPropsType = {
    addOn: ChildrenLineItem;
    parentLineItem: LineItem;
    onAddChildLineItem: (productId: string, signal?: AbortSignal) => Promise<void>;
    onRemoveChildLineItem: (productId: string, signal?: AbortSignal) => Promise<void>;
};

export const AddonCard = ({ addOn, parentLineItem, onAddChildLineItem, onRemoveChildLineItem }: AddOnPropsType) => {
    let { productId } = addOn;
    let { state: { isLoading } } = useOrderContext();

    const childLineItem = useMemo(() => {
        const result = parentLineItem.childrenLineItems.find(x => x.productId == productId);
        return result;
    }, [parentLineItem.childrenLineItems, productId]);

    const [hasAddon, setHasAddon] = useState(!!childLineItem);

    const handleToggleAddon = async () => {
        if (isLoading) {
            return;
        }

        if (!hasAddon) {
            setHasAddon(true);
            await onAddChildLineItem(productId);
        } else {
            setHasAddon(false);
            await onRemoveChildLineItem(productId);
        }
    }

    return (
        <li className="mb-2 px-0 addon-card list-group-item">
            <section className="d-flex flex-row align-items-center">
                <AnimatedCheckbox
                    className="form-check-input me-2 border-primary border-2"
                    name={addOn.productId}
                    id={addOn.productId}
                    checked={hasAddon}
                    onChange={handleToggleAddon}
                    disabled={isLoading}
                    style={{ width: 24, height: 24 }} />
                <section
                    className="w-80 d-flex flex-column flex-md-row align-items-md-center justify-content-between justify-content-md-start"
                    role="button"
                    onClick={handleToggleAddon}>
                    <ProductThumbnail className="addon-image me-3" src={addOn.primaryImageLink} name={addOn.productName} />
                    <header className="d-flex flex-column w-85 mb-2 mb-md-0 align-items-start p-2">
                        <h6 className="card-title mb-1">{addOn.productName}</h6>
                        <i className="card-subtitle text-muted mb-1">{addOn.productDescription}</i>
                    </header>
                </section>
                <div className="w-15 fs-6 d-flex justify-content-end">
                    {hasAddon && <Money amount={childLineItem?.totalPrice} />}
                </div>
            </section>
        </li>
    )
}
