import React from "react";
import { IconContext } from "react-icons";

type ReactIconType = IconContext & {
    icon: React.FC;
};

export const ReactIcon = (props: ReactIconType) => {
    const { icon: Icon, ...contextValue } = props;

    return (
        <IconContext.Provider value={contextValue || {}}>
            <Icon />
        </IconContext.Provider>
    );
};
