import "./line-item-card.scss";

import { useCallback, useMemo } from "react";
import { Money } from "../../../../../common/utilities/formatters/money";
import { ChildrenLineItem, LineItem } from "../../../../../services/public/ordering/get-order-by-id";
import { GetProductItem } from "../../../../../services/public/ordering/get-products";
import { OrderLineItem } from "../../../../../services/public/ordering/put-order-line-items";
import { useOrderContext } from "../../provider/use-order-context";
import { AddonCard } from "./addons/addon-card";
import { ChangeLineItem } from "./change-line-item";
import { Counter } from "./counter";
import { ProductThumbnail } from "./product-thumbnail";

type LineItemPropsType = {
    lineItem: LineItem;
    productsGroupedByOptionType: Record<string, GetProductItem[]>;
    onCountUpdate: (count: number, signal?: AbortSignal) => Promise<void>;
    onRemoveLineItem: (signal?: AbortSignal) => Promise<void>;
};

export const LineItemCard = ({ lineItem, productsGroupedByOptionType, onCountUpdate, onRemoveLineItem }: LineItemPropsType) => {
    let { productId, count: previousCount = 0 } = lineItem;
    const { state: { products = [], order, isLoading }, actions: { setLineItems } } = useOrderContext();
    let { lineItems } = order ?? { lineItems: undefined };

    const addOns: ChildrenLineItem[] = useMemo(() => {
        const result = products?.filter((product) => product.kind == "addOn" && product.parentProductId == productId)
            .map((product) => ({
                lineItemId: product.id,
                arrivalDateTime: "",
                arrivalTimeRequired: product.arrivalTimeRequired,
                productId: product.id,
                count: previousCount,
                productName: product.name,
                productDescription: product.description || "",
                primaryImageLink: product.primaryImageLink || "",
                productOptionType: product.optionType || "",
                totalPrice: 0,
                kind: product.kind,
                sortOrder: 0,
                productUnit: product.unit,
                productUnitPluralized: product.unitPluralized
            }));
        return result ?? [];
    }, [products, productId, previousCount]);

    const productsByOptionType = useMemo(() => {
        return productsGroupedByOptionType[lineItem.productOptionType]?.filter((product) => product.id !== productId) ?? [];
    }, [productsGroupedByOptionType, lineItem, productId]);

    const handleRemoveLineItem = useCallback(async () => {
        try {
            await onRemoveLineItem();
        } catch (error) {
            console.error(error);
        }
    }, [onRemoveLineItem]);

    const handleCountUpdate = useCallback(async (count: number, signal?: AbortSignal) => {
        try {
            await onCountUpdate(count, signal);
        } catch (error) {
            console.error(error);
        }
    }, [onCountUpdate]);

    const updateLineItems = useCallback(async (updatedLineItem: OrderLineItem, signal?: AbortSignal) => {
        try {
            const updatedLineItems = lineItems?.map((lineItem) => {
                if (lineItem.productId == updatedLineItem.productId) {
                    return updatedLineItem;
                }
                return {
                    productId: lineItem.productId,
                    count: lineItem.count,
                    childrenLineItems: lineItem.childrenLineItems
                };
            }) ?? [];
            await setLineItems(updatedLineItems, signal);
        } catch (error) {
            console.error(error);
        }
    }, [lineItems, setLineItems]);

    const handleAddChildLineItem = useCallback(async (childProductId: string, signal?: AbortSignal) => {
        try {
            const updatedChildrenLineItems = [...lineItem.childrenLineItems, {
                productId: childProductId,
                count: 1
            }];
            const updatedLineItem: OrderLineItem = {
                productId: lineItem.productId,
                count: lineItem.count,
                childrenLineItems: updatedChildrenLineItems
            }
            await updateLineItems(updatedLineItem, signal);
        } catch (error) {
            console.error(error);
        }
    }, [lineItem.childrenLineItems, lineItem.count, lineItem.productId, updateLineItems])

    const handleRemoveChildLineItem = useCallback(async (childProductId: string, signal?: AbortSignal) => {
        try {
            const updatedLineItem = {
                ...lineItem,
                childrenLineItems: lineItem.childrenLineItems.filter(x => x.productId !== childProductId)
            }
            await updateLineItems(updatedLineItem, signal);
        } catch (error) {
            console.error(error);
        }
    }, [lineItem, updateLineItems])

    return (
        <li className="mb-2 p-3 line-item-card border-bottom">
            <section className="d-flex flex-row align-items-center">
                <section className="w-85 d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between justify-content-md-start">
                    <ProductThumbnail className="line-item-image me-3 mb-2" src={lineItem.primaryImageLink} name={lineItem.productName} />
                    <header className="d-flex flex-column w-70 mb-2 mb-md-0 align-items-start px-0 px-md-2">
                        <section className="d-flex flex-row align-items-center mb-1">
                            <h6 className="card-title me-2">{lineItem.productName}</h6>
                        </section>
                        <i className="card-subtitle text-muted mb-2">{lineItem.productDescription}</i>
                        <div className="d-flex flex-column flex-md-row align-self-start align-items-start align-items-md-center">
                            <button
                                className="btn btn-outline-danger border-0 py-1 px-2 ms-n2 me-2 mb-2 remove-button"
                                disabled={isLoading}
                                onClick={handleRemoveLineItem}>
                                Remove
                            </button>
                            {productsByOptionType?.length > 0 &&
                                <ChangeLineItem
                                    className="border-0 py-1 px-2 ms-n2 me-2 mb-2 remove-button"
                                    lineItem={lineItem}
                                    products={productsByOptionType} />
                            }
                        </div>
                    </header>
                    <Counter value={previousCount} onChange={handleCountUpdate} disabled={isLoading} unit={lineItem.productUnit} unitPlural={lineItem.productUnitPluralized} />
                </section>
                <div className="w-15 fs-6 d-flex justify-content-end">
                    <Money amount={lineItem.totalPrice} />
                </div>
            </section>
            {addOns?.length > 0 && (
                <section className="d-flex justify-content-end">
                    <ul className="list-group list-group-flush w-95">
                        {addOns?.map((addOn) => (
                            <AddonCard
                                key={addOn.productId}
                                addOn={addOn}
                                parentLineItem={lineItem}
                                onAddChildLineItem={handleAddChildLineItem}
                                onRemoveChildLineItem={handleRemoveChildLineItem} />
                        ))}
                    </ul>
                </section>
            )}
        </li>
    )
}
