import { useForm } from "react-hook-form";
import { useOrderContext } from "../provider/use-order-context";
import { CancellationInsuranceAddon } from "./line-items/addons/cancellation-insurance-addon";
import { HazardInsuranceAddon } from "./line-items/addons/hazard-insurance-addon";

type SpecialInstructionsForm = {
    specialInstructions: string | null;
};

export const PartyOptionsSection = ({ next }: { next: () => void }) => {

    let { actions: { setSpecialInstructions }, state: { order } } = useOrderContext();
    let { specialInstructions } = order ?? { specialInstructions: null };

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid }
    } = useForm<SpecialInstructionsForm>({
        mode: "onChange",
        defaultValues: {
            specialInstructions
        }
    });

    const onSubmit = async (data: SpecialInstructionsForm) => {
        try {
            await setSpecialInstructions({
                specialInstructions: data.specialInstructions
            });
            next();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
            <CancellationInsuranceAddon />
            <HazardInsuranceAddon />
            <section className="col">
                <div className="form-group">
                    <label htmlFor="partyDate">Special Requests</label>
                    <div className="text-muted my-2">Is there another service or rental you need that we&apos;re currently not offering?  Just let us know and we&apos;ll try to make it happen.  Our commitment is to make every effort to make your Partyeezy.</div>
                    <textarea {...register("specialInstructions")} className="form-control"></textarea>
                </div>
            </section>
            <footer className="d-flex justify-content-end">
                <button type="submit"
                    className="btn btn-outline-primary"
                    disabled={!isValid}>
                    Save and Continue
                </button>
            </footer>
        </form>
    );
};
