export function getAdditionalFeeName(name: string) {
    switch (name) {
        case "ServiceFee":
            return "Service & Support Fee"
        case "OvernightChargeFee":
            return "Overnight Service Fee"
        case "ShortNoticeFee":
            return "Rush Fee"
        case "PromoCodeDiscount":
            return "Discount"
        default:
            return name;
    }
}
