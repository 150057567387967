import { useEffect, useState } from "react";
import ConfettiExplosion, { ConfettiProps } from "react-confetti-explosion";
import { LuPartyPopper } from "react-icons/lu";
import { useParams } from "react-router-dom"
import { useWindowSize } from "@uidotdev/usehooks";
import { tapfiliateConversion } from "../../../common/tapfiliate/tapfiliate-provider";
import { Footer } from "../../../common/utilities/footer";
import { FormatDateWithAgo } from "../../../common/utilities/formatters/format-date";
import { Money } from "../../../common/utilities/formatters/money";
import { GetOrderByIdResponse } from "../../../services/public/ordering/get-order-by-id";
import { OrderProvider } from "../ordering/provider/order-provider";
import { useOrderContext } from "../ordering/provider/use-order-context";
import { OrderDetails } from "./order-details";
import { OrderTimeline } from "./order-timeline";

export function PlannerOrderStatus() {

    let { orderId } = useParams();
    if (!orderId) {
        throw "OrderId was falsely, this shouldn't be possible.";
    }

    return (
        <OrderProvider orderId={orderId}>
            <div className="d-flex flex-column flex-fill">
                <PlannerOrderImpl />
            </div>
            <Footer />
        </OrderProvider>
    )
}

function PlannerOrderImpl() {

    let { state: { order } } = useOrderContext();

    if (!order) {
        return <OrderNotFound />
    }

    return !order
        ? <OrderNotFound />
        : <OrderFound order={order} />
}

function OrderNotFound() {
    return (
        <div className="bg-white border rounded p-3 d-flex flex-column align-items-center mt-3 text-center" style={{
            width: "100%",
            maxWidth: "400px"
        }}>
            <p>We looked all over, but can&apos;t find your order.</p>
            <p>
                Something wrong?
                We are always here <a href="mailto:hello@partyeezy.com">hello@partyeezy.com</a>
            </p>
        </div>
    )
}

function OrderFound({ order }: { order: GetOrderByIdResponse }) {

    let { actions: { reload } } = useOrderContext();

    useEffect(() => {
        if (order.state == "paymentPending") {
            let interval = setInterval(() => reload(), 10 * 1000);
            return () => clearInterval(interval);
        }
    }, [order.state, reload]);

    useEffect(() => {
        // This can be safely called multiple times.
        // Although... sub-optimal, would be best to check for stripe's success redirect.
        // Created might be a useless check here.
        if (order.state != "created") {
            tapfiliateConversion(`#${order.humanReadableOrderId}`, order.totalPriceExcludingTaxes);
        }
    }, [order.humanReadableOrderId, order.state, order.totalPriceExcludingTaxes]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-7">
                    <OrderTimeline order={order} />
                </div>
                <div className="col-12 col-md-5">
                    <OrderDetails order={order} />
                </div>
            </div>
        </div>
    )
}

function OrderSuccess({ order }: { order: GetOrderByIdResponse }) {
    return (
        <>
            <div className="bg-white border rounded p-3 d-flex flex-column align-items-center" style={{
                width: "100%",
                maxWidth: "400px"
            }}>
                {/* <SimpleConfettiExplosion /> */}
                <LuPartyPopper className="mt-2" style={{
                    width: "40px",
                    height: "40px",
                }} />
                <div className="fs-3 mt-3">Order Placed!</div>
                <p className="text-center mt-3">
                    We&apos;ll start working on your party for <FormatDateWithAgo dateTime={order.eventSchedule?.startOn} />
                </p>
                <p className="text-center">
                    Look for a confirmation email after your payment method has been charged <Money amount={order.totalPriceIncludingTaxes ?? order.totalPriceExcludingTaxes} fallback="?" />.
                </p>
            </div>

            <div className="bg-white border rounded p-3 d-flex flex-column align-items-center mt-3 text-center" style={{
                width: "100%",
                maxWidth: "400px"
            }}>
                Something wrong?
                We are always here <a href="mailto:hello@partyeezy.com">hello@partyeezy.com</a>
            </div>
        </>
    )
}

function SimpleConfettiExplosion(props: ConfettiProps) {
    let [show, setShow] = useState(false);
    useEffect(() => setShow(true), [show]);

    let { width } = useWindowSize();

    return (
        <>
            {show && <ConfettiExplosion
                zIndex={-10}
                onComplete={() => setShow(false)}
                force={0.9}
                duration={3000}
                particleCount={300}
                width={width ?? undefined}
                {...props}
            />}
        </>
    )
}
