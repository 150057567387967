import { useCallback, useMemo, useState } from "react";
import { AnimatedCheckbox } from "../../../../../../common/utilities/animated-checkbox";
import { Money } from "../../../../../../common/utilities/formatters/money";
import { ProductDescription } from "../../../../../../common/utilities/product-description";
import { OrderLineItem } from "../../../../../../services/public/ordering/put-order-line-items";
import { useOrderContext } from "../../../provider/use-order-context";
import { HazardInsuranceAddonProductId } from "../well-known-items";

export function HazardInsuranceAddon() {

    let { state: { products } } = useOrderContext();

    let [isLoading, setIsLoading] = useState(false);
    let { actions: { setLineItems }, state: { order } } = useOrderContext();

    let addOn = useMemo(
        () => products?.find(x => x.id == HazardInsuranceAddonProductId),
        [products]
    );
    let hasAddon = useMemo(
        () => !!order?.lineItems.find(x => x.productId == HazardInsuranceAddonProductId),
        [order?.lineItems]
    );
    let pricing = useMemo(() => order?.lineItems.find(x => x.productId == HazardInsuranceAddonProductId)?.totalPrice ?? null, [order?.lineItems])

    let handleToggleAddon = useCallback(async () => {
        setIsLoading(true);
        try {
            let removeAddon = hasAddon;
            if (removeAddon) {
                // Remove
                let lineItems: OrderLineItem[] = order?.lineItems
                    .filter(x => x.productId != HazardInsuranceAddonProductId) ?? [];
                await setLineItems(lineItems);
            } else {
                // Add
                let lineItems: OrderLineItem[] = [...order?.lineItems ?? []];
                if (!lineItems.find(x => x.productId == HazardInsuranceAddonProductId)) {
                    lineItems.push({
                        productId: HazardInsuranceAddonProductId,
                        count: 1
                    })
                    await setLineItems(lineItems);
                }
            }
        } finally {
            setIsLoading(false);
        }
    }, [hasAddon, order?.lineItems, setLineItems]);

    return (
        <div className="form-group">
            <div className="text-capitalize">
                {addOn?.name}
            </div>
            <div className="d-flex flex-column mt-2 p-3 border bg-white rounded">
                <div className="d-flex">
                    <div className="form-check mb-0 d-flex align-items-center justify-content-center">
                        <AnimatedCheckbox
                            className="form-check-input"
                            name="hazard-insurance-addon"
                            id="hazard-insurance-addon"
                            checked={hasAddon}
                            onChange={handleToggleAddon}
                            disabled={isLoading} style={{ width: 24, height: 24 }} />
                        <label className="form-check-label user-select-none ms-2 mt-2" htmlFor="hazard-insurance-addon">
                            I need <span className="text-capitalize">{addOn?.name}</span>
                            {!hasAddon && (
                                <span className="text-muted ms-2">(Recommended)</span>
                            )}
                        </label>
                    </div>
                    {!!pricing && (
                        <div className="ms-auto fw-semibold">
                            +<Money amount={pricing} />
                        </div>
                    )}
                </div>
                <div className="text-muted mt-3">
                    <ProductDescription text={addOn?.description} />
                </div>
            </div>
        </div>
    )
}
