import clsx from "clsx";
import { useCallback, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { CreateLightWeightVendors, CreateLightWeightVendorsInput } from "../../../../common/api/handlers/admin/light-weight-vendors/create-light-weight-vendor";
import { useApiHandlerWithAuth } from "../../../../common/api/use-api-handler-with-auth";
import { Regex } from "../../../../common/constants/regex";
import { LabelWithError } from "../../../../common/forms/label-with-error";

export type CreateLightWeightVendorButtonProps = {
    className?: string;
}

export function CreateLightWeightVendorButton({ className }: CreateLightWeightVendorButtonProps) {

    const [show, setShow] = useState(false);
    const handleClose = useCallback(() => setShow(false), []);
    const handleShow = useCallback(() => setShow(true), []);

    return (
        <>
            <button className={clsx("btn btn-primary", className)} onClick={handleShow}>
                Create Vendor
            </button>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Vendor</Modal.Title>
                </Modal.Header>
                <CreateVendor onClose={handleClose} />
            </Modal>
        </>
    );
}

type CreateVendorProps = {
    onClose: () => void;
}

function CreateVendor({ onClose }: CreateVendorProps) {

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isValid },
        watch
    } = useForm<CreateLightWeightVendorsInput>({
        mode: "onChange"
    });

    let handler = useApiHandlerWithAuth(CreateLightWeightVendors);
    const onSubmit = useCallback(async (data: CreateLightWeightVendorsInput) => {
        await handler(data);
        onClose();
    }, [handler, onClose])

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column">

            <div className="p-3">
                <div className="row">
                    <div className="col">
                        <p>This modal creates a vendor. Names best be unique, but we aren&apos;t validating that right now.</p>
                        <div className="form-floating">
                            <input type="text"
                                className="form-control"
                                placeholder="Name"
                                {...register("name", { required: "Name is required" })} />
                            <LabelWithError errors={errors} name="name">
                                Name
                            </LabelWithError>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <div className="form-floating">
                            <input type="text"
                                className="form-control"
                                placeholder="Description"
                                {...register("description")} />
                            <LabelWithError errors={errors} name="description">
                                Description
                            </LabelWithError>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-6">
                        <div className="form-floating">
                            <input type="email"
                                className="form-control"
                                placeholder="Email Address"
                                {...register("emailAddress", {
                                    required: "Email Address is required",
                                    pattern: {
                                        value: Regex.email,
                                        message: "Email Address must be valid"
                                    }
                                })} />
                            <LabelWithError errors={errors} name="emailAddress">
                                Email Address
                            </LabelWithError>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-floating">
                            <input type="text"
                                className="form-control"
                                placeholder="Phone Number"
                                {...register("phoneNumber", {
                                    pattern: {
                                        value: Regex.phoneNumber,
                                        message: "Phone Number must be valid"
                                    }
                                })} />
                            <LabelWithError errors={errors} name="phoneNumber">
                                Phone Number
                            </LabelWithError>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal-footer">
                <button type="submit" className="btn btn-primary" disabled={isSubmitting || !isValid}>
                    Create Vendor
                </button>
            </div>
        </form>
    )
}
