import "./party-planner-form.scss";

import clsx from "clsx";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import { Controller, FieldPath, FieldValues, UseFormWatch, useForm } from "react-hook-form";
import { BsArrowRight, BsChevronRight } from "react-icons/bs";
import { Regex } from "../../common/constants/regex";
import { SimpleTooltip } from "../../common/utilities/simple-tooltip";
import { GetPartyTypesResponse, getPartyTypes } from "../../services/public/get-party-types";
import { PartyTypeSelect } from "./party-type-select";

export type PartyPlannerFormType = {
    partyTypeId: string;
    partySize: number;
    partyLocation: string;
};

export type PartyPlannerFormProps = Partial<PartyPlannerFormType> & {
    onSubmit?: (data: PartyPlannerFormType) => Promise<void> | void;
}

export const PartyPlannerForm = (props: PartyPlannerFormProps) => {
    const {
        control,
        register,
        handleSubmit,
        formState: { errors, isValid, isSubmitting },
        setValue,
        watch
        // if defaultValue is set, then react-select validation will fail.
        // since react-value is set to {} instead of null.
        // even if defaultValue is set to null, react-select will still set it to {}.
    } = useForm<PartyPlannerFormType>({ mode: "all" });

    useEffect(() => {
        if (props) {
            if (props.partyTypeId) {
                setValue("partyTypeId", props.partyTypeId, { shouldValidate: true });
            }
            if (props.partySize) {
                setValue("partySize", props.partySize, { shouldValidate: true });
            }
            if (props.partyLocation) {
                setValue("partyLocation", props.partyLocation, { shouldValidate: true });
            }
        }
    }, [props, setValue]);

    const reactHookSubmitCallback = async (data: PartyPlannerFormType) => {
        try {
            await props.onSubmit?.(data);
        } catch (error) {
            console.warn(error);
        }
    };

    let partyTypes = usePartyTypes();
    const partTypeSelectOptions = useMemo(() => {
        return partyTypes.map(x => ({ label: x.name, value: x.id }));
    }, [partyTypes]);

    let [typeActive, setTypeActive] = useState(false);
    let [headCountActive, setHeadCountActive] = useState(false);
    let [locationActive, setLocationActive] = useState(false);

    let active = useMemo(() => typeActive || headCountActive || locationActive, [typeActive, headCountActive, locationActive]);

    let headCountStyle = useValueStyle(watch, "partySize");
    let locationStyle = useValueStyle(watch, "partyLocation");

    let errorMessage = useMemo(
        () => errors.partyTypeId?.message ?? errors.partySize?.message ?? errors.partyLocation?.message,
        [errors.partyLocation?.message, errors.partySize?.message, errors.partyTypeId?.message]
    );

    return (
        <form className="party-planner-form d-flex flex-column flex-lg-row align-items-lg-end justify-content-center p-3"
            noValidate
            onSubmit={handleSubmit(reactHookSubmitCallback)}>

            <div className={clsx("search rounded-4 d-flex flex-column flex-lg-row justify-content-center position-relative", active && "active")}>
                <Controller
                    name="partyTypeId"
                    control={control}
                    rules={{ required: "Party Type is required" }}
                    render={
                        ({ field: { onChange, value } }) => {
                            let selected = partTypeSelectOptions.find(x => x.value == value);
                            return (
                                <PartyTypeSelect
                                    onChange={x => onChange(x?.value)}
                                    value={selected}
                                    options={partTypeSelectOptions}
                                    placeholder="Select Party Type"
                                    className="input input-type"
                                    onFocus={() => setTypeActive(true)}
                                    onBlur={() => setTypeActive(false)}
                                />
                            );
                        }
                    }
                />
                <input
                    type="text"
                    className="form-control input input-headcount py-lg-3 rounded-4"
                    placeholder="Head Count"
                    {...register("partySize", {
                        required: "Wait, we still need the head count for your party.",
                        min: {
                            value: 1,
                            message: "Wait, we still need the head count cannot be below 1. That would be very lonely."
                        },
                        max: {
                            value: 1000,
                            message: "Whoa, that's a lot of people, we currently can't handle events quite that large yet."
                        },
                        pattern: {
                            value: Regex.positiveNonDecimalNumber,
                            message: "Looks like head count isn't a number."
                        }
                    })}
                    style={{ ...headCountStyle }}
                    onFocus={() => setHeadCountActive(true)}
                    onBlur={() => setHeadCountActive(false)} />
                <input
                    type="text"
                    className="form-control input input-location py-lg-3 rounded-4"
                    placeholder="Zip Code"
                    {...register("partyLocation", {
                        required: "We need your zip code.",
                        pattern: { value: Regex.zipFull, message: "Looks like this zip code may be invalid." }
                    })}
                    style={{ ...locationStyle }}
                    onFocus={() => setLocationActive(true)}
                    onBlur={() => setLocationActive(false)}
                />

                {errorMessage && (
                    <div className="position-absolute top-100 start-0 end-0 text-danger pt-2 text-center">
                        {errorMessage}
                    </div>
                )}
            </div>

            <SimpleTooltip title="Search">
                <button type="submit"
                    className="submit btn btn-primary ms-3 d-none d-lg-block m-1 rounded-4 align-self-stretch"
                    style={{ aspectRatio: "1 / 1" }}
                    disabled={isSubmitting}>
                    <BsArrowRight style={{ fontSize: 24 }} aria-label="Search" />
                </button>
            </SimpleTooltip>

            <button type="submit"
                className={clsx("submit btn btn-primary d-block d-lg-none align-self-end", errorMessage && "has-error")}
                disabled={isSubmitting}>
                Search Packages <BsChevronRight className="ms-1" aria-label="Search" />
            </button>

        </form>
    );
};

function useValueStyle<T extends FieldValues>(watch: UseFormWatch<T>, field: FieldPath<T>): CSSProperties {
    let value = watch(field);

    return useMemo(() => {
        if (value == null) {
            return {};
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        let strValue = value.toString() as string;
        if (!strValue?.length) {
            return {};
        }

        let offsetWidth = getTextWidth(strValue, "400 1rem 'Raleway Variable'");

        return { "--input-offset-width": `${offsetWidth}px` } as CSSProperties;
    }, [value])
}

function getTextWidth(text: string, font?: string) {
    // https://stackoverflow.com/a/58705306

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    context!.font = font || getComputedStyle(document.body).font;

    return context!.measureText(text).width + 60;
}

function usePartyTypes() {
    let [partyTypes, setPartyTypes] = useState<GetPartyTypesResponse[]>([]);
    useEffect(() => {
        if (partyTypes?.length === 0) {
            const loadPartyTypes = async () => {
                try {
                    let response = await getPartyTypes();
                    setPartyTypes(response);
                } catch (error) {
                    console.warn(error);
                }
            };
            void loadPartyTypes();
        }
    }, [partyTypes]);

    return partyTypes;
}
