import "property-information"; // https://github.com/remarkjs/react-markdown/issues/747
import "./terms-and-conditions.scss";

import Text from "bundle-text:./terms-and-conditions.md";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { BannerFooter } from "../../../common/utilities/banner-footer";
import { Footer } from "../../../common/utilities/footer";

export function TermsAndConditionsPage() {
    return (
        <main className="terms-and-conditions">
            <section className="d-flex justify-content-center my-5">
                <div className="container d-flex flex-column">
                    <Markdown remarkPlugins={[remarkGfm]} className="markdown">
                        {Text}
                    </Markdown>
                </div>
            </section>
            <BannerFooter className="col-12 p-0" />
            <Footer />
        </main>
    )
}
