import { useCallback, useMemo } from "react";
import { useAuth } from "../auth/use-auth";
import { ApiHandlerWithAuth } from "./handlers/api-handler-with-auth";

export function useApiHandlerWithAuth<TInput, TResult>(
    handler: ApiHandlerWithAuth<TInput, TResult>
) {

    let { user } = useAuth();

    let authHeader = useMemo<string | undefined>(
        () => user ? `${user.tokenType} ${user.accessToken}` : undefined,
        [user]
    );

    let executor = useCallback((input: TInput, signal?: AbortSignal) => {
        try {
            return handler.handle(input, authHeader, signal);
        } catch (error) {
            console.warn("Error while executing handler: ", error, ", with input: ", input);
            throw error;
        }
    }, [authHeader, handler]);

    return executor;
}
