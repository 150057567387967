import { HttpMethodEnum } from "../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../common/utilities/get-auth-request-header";

export type GetPartyTypesResponse = {
    id: string;
    name: string;
    order: number;
};


export const getPartyTypes = async (signal?: AbortSignal): Promise<GetPartyTypesResponse[]> => {
    const url = "/api/v1/order-placement/party-types";
    let response: Response = await fetch(`${url}`, {
        signal,
        method: HttpMethodEnum.GET,
        headers: getAuthRequestHeaders()
    });

    if (!response?.ok) {
        throw response;
    }

    return await response.json() as GetPartyTypesResponse[];
};
