import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { LabelWithError } from "../../../../common/forms/label-with-error";
import { inServiceArea } from "../../../../services/public/address-validation/in-service-area";
import { Address, AddressAutocomplete } from "../address-autocomplete";
import { useOrderContext } from "../provider/use-order-context";
import { SameDayPickupAddon } from "./line-items/addons/same-day-pickup-addon";

type PartyDetailsForm = {
    partyDate: Date;
    partyTime: string;
    partyDuration: number;
    eventAddress: Address;
    locationTypeId: string;
    setupTypeId: string;
};

export const PartyDetailsSection = ({ next }: { next: () => void }) => {

    let { actions: { setEventSchedule, setEventAddress, setLineItems }, state: { order, locationTypes, setupTypes } } = useOrderContext();
    let { eventSchedule, eventAddress, lineItems } = useMemo(() => order ?? { eventSchedule: undefined, eventAddress: undefined, lineItems: undefined }, [order]);

    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors, isValid },
        watch
    } = useForm<PartyDetailsForm>({
        mode: "onChange",
    });
    // const today = useMemo(() => new Date().toISOString().split("T")[0], []);
    const today = useMemo(() => new Date(), []);
    const maxDate = useMemo(() => {
        const now = new Date();
        return new Date(now.setDate(today.getDate() + 180));
    }, [today]);

    const watchPartyDate = watch("partyDate");
    const watchPartyTime = watch("partyTime");
    const watchPartyDuration = watch("partyDuration");

    const startOn = useMemo(() => {
        if (watchPartyDate && watchPartyTime) {
            // Parse the date object to Luxon DateTime
            const date = DateTime.fromJSDate(watchPartyDate);

            // Parse the time string to Luxon DateTime, assuming the time is in local timezone
            const time = DateTime.fromFormat(watchPartyTime, "h:mm a");

            // Combine the date and time
            const combinedDateTime = date.set({
                hour: time.hour,
                minute: time.minute,
                second: time.second
            });
            return combinedDateTime.toISO() || "";
        }
        return "";
    }, [watchPartyDate, watchPartyTime]);

    const durationHours = useMemo(() => {
        if (watchPartyDuration) {
            return Number(watchPartyDuration);
        }
        return 0;
    }, [watchPartyDuration]);

    useEffect(() => {
        if (eventSchedule?.startOn) {
            const startOnDateTime = new Date(eventSchedule.startOn);
            const partyDate = new Date(startOnDateTime.toLocaleDateString());
            // const partyDate = `${localDateTime.getFullYear()}-${(localDateTime.getMonth() + 1).toString().padStart(2, "0")}-${localDateTime.getDate().toString().padStart(2, "0")}`;
            const partyTime = startOnDateTime.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
            });
            setValue("partyDate", partyDate, { shouldValidate: !!partyDate });
            setValue("partyTime", partyTime, { shouldValidate: !!partyTime });
        }
        if (eventSchedule?.durationHours) {
            setValue("partyDuration", eventSchedule.durationHours, { shouldValidate: eventSchedule.durationHours > 0 });
        }
        if (eventAddress) {
            let { locationTypeId, setupTypeId, ...address } = eventAddress;
            setValue("eventAddress", address, { shouldValidate: true });
            setValue("locationTypeId", locationTypeId, { shouldValidate: true });
            setValue("setupTypeId", setupTypeId, { shouldValidate: true });
        }
    }, [eventAddress, eventSchedule, setValue]);

    const saveEventSchedule = async () => {
        if (!startOn || !durationHours) {
            throw new Error("Invalid date");
        }

        // CHECK WHY THIS CHECK IS NOT WORKING
        // ^ Likely captured in the closure.
        if (startOn != eventSchedule?.startOn || durationHours != eventSchedule?.durationHours) {
            await setEventSchedule({
                startOn,
                durationHours
            });
            // Clear arrival date times if the start date and start time were changed
            // TODO: probably should only clear on time or duration hour change
            await clearArrivalDateTimes();
        }
    };

    const clearArrivalDateTimes = async () => {
        if (lineItems?.some(x => x.arrivalDateTime)) {
            const updatedLineItems = lineItems.map(item => {
                return {
                    ...item,
                    arrivalDateTime: null,
                }
            })
            await setLineItems(updatedLineItems);
        }
    }

    const saveEventAddress = async (address: Address, locationTypeId: string, setupTypeId: string) => {
        if (!address) {
            throw new Error("No address selected");
        }
        await setEventAddress({ ...address, locationTypeId, setupTypeId });
    };

    const onSubmit = async (data: PartyDetailsForm) => {
        try {
            const { eventAddress, locationTypeId, setupTypeId, ...eventSchedule } = data || {};
            await saveEventSchedule();
            await saveEventAddress(eventAddress, locationTypeId, setupTypeId);
            next();
        } catch (error) {
            console.error(error);
        }
    };

    // Generate options for party duration select
    const partyDurationOptions = Array.from({ length: 12 }, (_, index) => index + 1);

    const getTimeRange = () => {
        const timeRange = [];
        const startOnDateTime = new Date();
        startOnDateTime.setHours(9);
        startOnDateTime.setMinutes(0);
        const endDateTime = new Date(startOnDateTime);
        endDateTime.setHours(23);

        while (startOnDateTime <= endDateTime) {
            const time = startOnDateTime.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
            });
            timeRange.push(time);
            startOnDateTime.setMinutes(startOnDateTime.getMinutes() + 30);
        }

        return timeRange;
    };

    let validateAddress = useCallback(async (address: Address) => {
        if (!address.zipCode) {
            return "Address is required.";
        }

        if (address.zipCode == eventAddress?.zipCode) {
            return;
        }

        let result = await inServiceArea({ zipCode: address.zipCode });
        if (!result.isServiced) {
            return "Address is outside of our service area."
        }
        return true;
    }, [eventAddress?.zipCode]);

    return (
        <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
            <section className="col-12 col-md-5">
                <div className="form-group">
                    {/* To test: 101 W main st Alhambra CA */}
                    <LabelWithError name="eventAddress" errors={errors}>Address</LabelWithError>
                    <Controller
                        name="eventAddress"
                        control={control}
                        rules={{ required: true, validate: validateAddress }}
                        render={({ field: { onChange, value } }) => (
                            <AddressAutocomplete
                                onChange={onChange}
                                address={value}
                            />)} />
                </div>
            </section>

            <section className="col-12 col-md-3">
                <div className="form-group">
                    <LabelWithError name="locationTypeId" errors={errors}>Address Type</LabelWithError>
                    <select
                        className="form-select"
                        id="locationTypeId"
                        {...register("locationTypeId", { required: true })}>
                        {
                            locationTypes.map(({ id, description }) => (
                                <option key={id} value={id}>{description}</option>
                            ))
                        }
                    </select>
                </div>
            </section>

            <section className="col-12 col-md-4">
                <div className="form-group">
                    <LabelWithError name="setupTypeId" errors={errors}>Setup Location</LabelWithError>
                    <select
                        className="form-select"
                        id="setupTypeId"
                        {...register("setupTypeId", { required: true })}>
                        {
                            setupTypes.map(({ id, description }) => (
                                <option key={id} value={id}>{description}</option>
                            ))
                        }
                    </select>
                </div>
            </section>

            <section className="col-12 col-md-5">
                <div className="form-group">
                    <LabelWithError name="partyDate" errors={errors}>Date</LabelWithError>
                    <Controller
                        control={control}
                        name='partyDate'
                        rules={{ required: true, validate: validateDate }}
                        render={({ field: { onChange, value } }) => {
                            return <ReactDatePicker
                                wrapperClassName="w-100 d-block"
                                placeholderText='Select date'
                                onChange={onChange}
                                className="form-control"
                                selected={value}
                                minDate={today}
                                maxDate={maxDate}
                            />
                        }}
                    />
                </div>
            </section>
            <section className="col-12 col-md-3">
                <div className="form-group">
                    <LabelWithError name="partyTime" errors={errors}>Start Time</LabelWithError>
                    <select
                        className="form-select"
                        id="partyTime"
                        {...register("partyTime", { required: true })}>
                        <option value="">Select a time</option>
                        {getTimeRange().map((time) => (
                            <option key={time} value={time}>{time}</option>
                        ))}
                    </select>
                </div>
            </section>
            <section className="col-12 col-md-4">
                <div className="form-group">
                    <LabelWithError name="partyDuration" errors={errors}>Party Duration</LabelWithError>
                    <select className="form-select" id="partyDuration" {...register("partyDuration", { required: true })}>
                        {partyDurationOptions.map((option) => (
                            <option key={option} value={option}>
                                {option} hour{option !== 1 ? "s" : ""}
                            </option>
                        ))}
                    </select>
                </div>
            </section>
            <section>
                <SameDayPickupAddon
                    startOn={startOn}
                    durationHours={durationHours} />
            </section>
            <footer className="d-flex justify-content-end">
                <button type="submit"
                    className="btn btn-outline-primary"
                    disabled={!isValid}>
                    Save and Continue
                </button>
            </footer>
        </form>
    );
};

function validateDate(dateIso: Date) {
    if (dateIso) {
        let dateTime = DateTime.fromJSDate(dateIso);
        if (dateTime.isValid) {
            let fromNow = dateTime.diffNow("days");
            if (fromNow.days < 0) {
                return "Date must be in the future."
            }
            if (fromNow.days >= 180) {
                return "Date must not be more than 180 days in the future."
            }
            return true;
        } else {
            return "Date is incomplete."
        }
    } else {
        return "Date is required.";
    }
}
