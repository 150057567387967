import { RouteObject } from "react-router-dom";
import { AdminLayout } from "../../common/components/layouts/admin-layout/admin-layout";
import { Home } from "./home/home";
import { adminLightWeightVendorsRoutes } from "./light-weight-vendors/light-weight-vendors";
import { adminMessagesRoutes } from "./messages/messages";
import { adminOrderRoutes } from "./orders/orders";
import { PlannerSurveys } from "./planner-surveys/planner-surveys";
import { productRoutes } from "./products/products";
import { adminPromotionCodesRoutes } from "./promotion-codes/promotion-codes";
import { VendorSurveys } from "./vendor-surveys/vendor-surveys";
import { adminVendorRoutes } from "./vendors/vendors";

export const adminPaths = {
    home: "/admin/home",
    vendorSurveys: "/admin/vendor-surveys",
    plannerSurveys: "/admin/planner-surveys",
    products: "/admin/products",
};

export const adminRoutes: RouteObject[] = [
    {
        Component: AdminLayout,
        children: [
            {
                path: adminPaths.home,
                Component: Home
            },
            {
                path: adminPaths.vendorSurveys,
                Component: VendorSurveys
            },
            {
                path: adminPaths.plannerSurveys,
                Component: PlannerSurveys
            },
            ...productRoutes,
            ...adminVendorRoutes,
            ...adminOrderRoutes,
            ...adminMessagesRoutes,
            ...adminLightWeightVendorsRoutes,
            ...adminPromotionCodesRoutes
        ]
    }
];
