import { useMemo } from "react";

export type MoneyProps = {
    amount?: number | null;
    fallback?: string;
}

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2
});

export function Money({ amount, fallback }: MoneyProps) {

    let isNegative = amount && amount < 0
    let normalizedAmount = useMemo(() => amount != null ? formatter.format(amount) : null, [amount]);

    return normalizedAmount == null
        ? <>{fallback ?? "-"}</>
        : (
            isNegative
                ? <>({normalizedAmount})</>
                : <>{normalizedAmount}</>
        );
}
