import "./vendor.scss";

import { ChangeEvent, MouseEvent as ReactMouseEvent, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormLayout } from "../../../common/components/layouts/form-layout/form-layout";
import { Regex } from "../../../common/constants/regex";
import { CustomErrorMessage } from "../../../common/forms/custom-error-message";
import { BannerFooter } from "../../../common/utilities/banner-footer";
import { postVendorSurvey } from "../../../services/surveys-service/post-vendor-survey";
import { VendorSubmissionSuccess } from "./vendor-submission-success";
import { VendorFormProps } from "./vendor.constants";
import { VendorFormText } from "./vendor.strings";
import { VendorFormType } from "./vendor.type";

// TODO:
// Animation
// Loading statep
// Error state
// Refactor
// UI cleanup
// updating route in each step: https://react-hook-form.com/advanced-usage#WizardFormFunnel

export function Vendor() {
    return (
        <section className="d-flex flex-column align-items-center mx-n2 pez-font h-100">
            <header className="w-100 bg-primary text-white py-3 fs-2 text-center">
                {VendorFormText.caption}
            </header>
            <FormLayout layoutClassName="col-lg-8 vendor">
                <VendorForm />
            </FormLayout>
            <BannerFooter className="col-12 p-0" />
        </section>
    );
}

export function VendorForm() {
    const {
        register,
        handleSubmit,
        watch,
        trigger,
        formState: { errors, isSubmitSuccessful, isValid, dirtyFields },
        setValue,
        getValues,
        setFocus
    } = useForm<VendorFormType>({
        mode: "onChange",
        defaultValues: {
            firstName: "",
            lastName: "",
            businessName: "",
            emailAddress: "",
            phoneNumber: "",
            zipCode: "",
            providedServices: [],
            otherProvidedService: "",
            businessGoals: [],
            otherBusinessGoal: ""
        }
    });
    const totalQuestions = Object.keys(VendorFormText.questions).length;
    const allQuestions = Object.values(VendorFormText.questions);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [isAllRentalsListSelected, setIsAllRentalsListSelected] = useState(false);
    const [isAllServicesListSelected, setIsAllServicesListSelected] = useState(false);
    const {
        firstNameProp,
        lastNameProp,
        businessNameProp,
        emailAddressProp,
        phoneNumberProp,
        zipCodeProp,
        providedServicesProp,
        otherProvidedServiceProp,
    } = VendorFormProps;
    const step1Props = [firstNameProp, lastNameProp, businessNameProp, emailAddressProp, phoneNumberProp, zipCodeProp];

    useEffect(() => {
        setFocus?.(firstNameProp);
    }, [firstNameProp, setFocus]);

    const rentalsList = Object.values(VendorFormText.rentalsList);
    const servicesList = Object.values(VendorFormText.servicesList);
    const updatedProvidedServices = watch(providedServicesProp);

    useEffect(() => {
        if (updatedProvidedServices?.length > 0) {
            const allRentalsSelectedInProvidedServices = rentalsList.every(
                (item) => updatedProvidedServices.includes(item)
            );
            const allServicesSelectedInProvidedServices = servicesList.every(
                (item) => updatedProvidedServices.includes(item)
            );
            setIsAllRentalsListSelected(allRentalsSelectedInProvidedServices);
            setIsAllServicesListSelected(allServicesSelectedInProvidedServices);
        } else {
            setIsAllRentalsListSelected(false);
            setIsAllServicesListSelected(false);
        }
    }, [rentalsList, servicesList, updatedProvidedServices]);

    const handleSelectAllRentalsList = (event: ChangeEvent<HTMLInputElement>): void => {
        const { checked } = event.target;
        let selectedRentalsList = (getValues(providedServicesProp) as string[]) || [];

        if (checked) {
            setIsAllRentalsListSelected(true);
            selectedRentalsList = [...selectedRentalsList, ...rentalsList];
        } else {
            setIsAllRentalsListSelected(false);
            selectedRentalsList = selectedRentalsList.filter((item) => !rentalsList.includes(item));
        }

        setValue(providedServicesProp, selectedRentalsList);
        void trigger(providedServicesProp);
    };

    const handleSelectAllServicesList = (event: ChangeEvent<HTMLInputElement>): void => {
        const { checked } = event.target;
        let selectedServicesList = (getValues(providedServicesProp) as string[]) || [];

        if (checked) {
            setIsAllServicesListSelected(true);
            selectedServicesList = [...selectedServicesList, ...servicesList];
        } else {
            setIsAllServicesListSelected(false);
            selectedServicesList = selectedServicesList.filter((item) => !servicesList.includes(item));
        }

        setValue(providedServicesProp, selectedServicesList);
        void trigger(providedServicesProp);
    };

    const reactHookSubmitCallback: SubmitHandler<VendorFormType> = async (data) => {
        try {
            if (!isValid) {
                throw new Error("Form is not valid");
            }

            const { firstName, lastName, ...rest } = data;

            const request = {
                ...rest,
                fullName: `${data.firstName} ${data.lastName}`
            };
            await postVendorSurvey(request);
        } catch (error) {
            console.error(error);
        }
    };

    const { validationMessages } = VendorFormText;
    const validationRules = {
        [firstNameProp]: {
            required: true
        },
        [lastNameProp]: {
            required: true
        },
        [businessNameProp]: {
            required: true
        },
        [emailAddressProp]: {
            required: true,
            pattern: { value: Regex.email, message: validationMessages.emailAddress.pattern }
        },
        [phoneNumberProp]: {
            required: true,
            pattern: { value: Regex.phoneNumber, message: validationMessages.phoneNumber.pattern }
        },
        [zipCodeProp]: {
            required: true,
            pattern: { value: Regex.zip, message: validationMessages.zipCode.pattern }
        },
        [providedServicesProp]: {
            required: true
        },
    };

    let isActionDisabled = true;

    switch (currentQuestion) {
        case 0:
            isActionDisabled = false;
            break;
        case 1:
            isActionDisabled =
                !step1Props.every((prop) => Object.keys(dirtyFields || {}).includes(prop))
                || step1Props.some((prop) => errors[prop]);
            break;
        case 2:
            isActionDisabled = !getValues(providedServicesProp)?.length;
            break;
        default:
            break;
    }

    const handleNextClick = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.preventDefault();
        setCurrentQuestion((prev) => prev + 1);
    };

    const handlePrevClick = (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.preventDefault();
        setCurrentQuestion((prev) => prev - 1);
    };

    return (
        <>
            {isSubmitSuccessful && <VendorSubmissionSuccess />}
            {!isSubmitSuccessful && (
                <form className="d-flex flex-column h-100 py-4" onSubmit={handleSubmit(reactHookSubmitCallback)} noValidate>
                    <article className="d-flex flex-column align-items-center justify-content-center h-100">
                        <div className="d-flex flex-column flex-md-row px-4 pb-4 align-self-stretch align-items-center justify-content-between">
                            <h5 className="fw-normal text-center mx-auto mb-0">{allQuestions[currentQuestion]}</h5>
                            <span className="text-secondary step-tracker text-center">
                                {`${currentQuestion + 1} of ${totalQuestions}`}
                            </span>
                        </div>

                        {currentQuestion == 0 && (
                            (
                                <div className="d-flex flex-column align-items-center" >
                                    <div style={{ maxWidth: "80%" }}>
                                        <p>
                                            We&apos;re always looking for top-notch vendors to join the Partyeezy team. Here&apos;s what we can do for your business!
                                        </p>
                                        <ul className="d-flex flex-column" >
                                            <li>
                                                No more having to find leads on Facebook Marketplace or Offerup! We provide all the marketing and send bookings directly to you.
                                            </li>
                                            <li>
                                                No more haggling with customers on pricing! We handle all customer communication.
                                            </li>
                                            <li>
                                                No more long distance deliveries! We match you with local bookings in your area.
                                            </li>
                                            <li>
                                                No cost to sign up!
                                            </li>
                                        </ul>
                                        <p>
                                            We do the hard stuff so you can Partyeezy!
                                        </p>
                                        <p>
                                            Ready to help us get the party started? Sign up to become a Partyeezy Partner!
                                        </p>
                                    </div>
                                </div>
                            )
                        )}

                        {currentQuestion === 1 && (
                            <section className="animate row g-3">
                                <div className="col-lg-6">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={VendorFormText.firstName}
                                            {...register(firstNameProp, validationRules[firstNameProp])}
                                        />
                                        <label htmlFor={firstNameProp}>{VendorFormText.firstName}</label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={VendorFormText.lastName}
                                            {...register(lastNameProp, validationRules[lastNameProp])}
                                        />
                                        <label htmlFor={lastNameProp}>{VendorFormText.lastName}</label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={VendorFormText.businessName}
                                            {...register(businessNameProp, validationRules[businessNameProp])}
                                        />
                                        <label htmlFor={businessNameProp}>{VendorFormText.businessName}</label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-floating">
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder={VendorFormText.emailAddress}
                                            {...register(emailAddressProp, validationRules[emailAddressProp])}
                                        />
                                        <label htmlFor={emailAddressProp}>
                                            {errors[emailAddressProp]?.type === "pattern" ? (
                                                <CustomErrorMessage errors={errors} name={emailAddressProp} />
                                            ) : (
                                                VendorFormText.emailAddress
                                            )}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-floating">
                                        <input
                                            type="tel"
                                            className="form-control"
                                            placeholder={VendorFormText.phoneNumber}
                                            {...register(phoneNumberProp, validationRules[phoneNumberProp])}
                                        />
                                        <label htmlFor={phoneNumberProp}>
                                            {errors[phoneNumberProp]?.type === "pattern" ? (
                                                <CustomErrorMessage errors={errors} name={phoneNumberProp} />
                                            ) : (
                                                VendorFormText.phoneNumber
                                            )}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={VendorFormText.zipCode}
                                            {...register(zipCodeProp, validationRules[zipCodeProp])}
                                        />
                                        <label htmlFor={zipCodeProp}>
                                            {errors[zipCodeProp]?.type === "pattern" ? (
                                                <CustomErrorMessage errors={errors} name={zipCodeProp} />
                                            ) : (
                                                VendorFormText.zipCode
                                            )}
                                        </label>
                                    </div>
                                </div>
                            </section>
                        )}
                        {currentQuestion === 2 && (
                            <section className="animate row g-3">
                                <div className="col-lg-3">
                                    <div className="px-3 py-2 bg-white border rounded">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={isAllRentalsListSelected}
                                                id="selectAllRental"
                                                onChange={handleSelectAllRentalsList}
                                            />
                                            <label className="form-check-label" htmlFor="selectAllRental">
                                                {VendorFormText.rentals}
                                            </label>
                                        </div>
                                        {rentalsList.map((rental, index) => (
                                            <div className="form-check ms-4" key={index}>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={rental}
                                                    id={rental}
                                                    {...register(
                                                        providedServicesProp,
                                                        validationRules[providedServicesProp]
                                                    )}
                                                />
                                                <label htmlFor={rental} className="form-check-label">
                                                    {rental}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-lg-9">
                                    <div className="px-3 py-2 bg-white border rounded">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={isAllServicesListSelected}
                                                id="selectAllServices"
                                                onChange={handleSelectAllServicesList}
                                            />
                                            <label className="form-check-label" htmlFor="selectAllServices">
                                                {VendorFormText.services}
                                            </label>
                                        </div>
                                        <div className="row g-0">
                                            {servicesList.map((service, index) => (
                                                <div className="form-check col-md-5 ms-4" key={index}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={service}
                                                        id={service}
                                                        {...register(
                                                            providedServicesProp,
                                                            validationRules[providedServicesProp]
                                                        )}
                                                    />
                                                    <label htmlFor={service} className="form-check-label">
                                                        {service}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="offset-lg-3 col-lg-6">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={VendorFormText.otherProvidedService}
                                            {...register(
                                                otherProvidedServiceProp,
                                                validationRules[otherProvidedServiceProp]
                                            )}
                                        />
                                        <label htmlFor={otherProvidedServiceProp}>
                                            {VendorFormText.otherProvidedService}
                                        </label>
                                    </div>
                                </div>
                            </section>
                        )}
                        <footer className="d-flex justify-content-center py-2 mt-auto">
                            {currentQuestion !== 0 && (
                                <button className="btn btn-primary me-2" type="submit" onClick={handlePrevClick}>
                                    {VendorFormText.back}
                                </button>
                            )}
                            {currentQuestion !== totalQuestions - 1 ? (
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                    onClick={handleNextClick}
                                    disabled={isActionDisabled}
                                >
                                    {VendorFormText.next}
                                </button>
                            ) : (
                                <button className="btn btn-primary" type="submit" disabled={isActionDisabled}>
                                    {VendorFormText.submit}
                                </button>
                            )}
                        </footer>
                    </article>
                </form>
            )}
        </>
    );
}

export default Vendor;
