import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type SetPrimaryProductImageInput = {
    productId: string;
    productImageId: string;
};

export const SetPrimaryProductImageCommand: ApiHandlerWithAuth<SetPrimaryProductImageInput, void> = {
    handle: async ({ productId, productImageId }, authHeader) => {

        let response = await fetch(`/api/v1/admin/products/${productId}/images/${productImageId}/primary`, {
            method: "POST",
            headers: getDefaultHeaders(authHeader)
        });

        if (!response.ok) {
            throw response;
        }
    }
}
