import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type GetPromotionCodeByIdInput = {
    promotionCodeId: string;
};

export const GetPromotionCodeById: ApiHandlerWithAuth<GetPromotionCodeByIdInput, GetPromotionCodeByIdResult> = {
    handle: async ({ promotionCodeId }, authHeader) => {
        let response = await fetch(`/api/v1/admin/promotions/${promotionCodeId}`, {
            method: "GET",
            headers: getDefaultHeaders(authHeader),
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as GetPromotionCodeByIdResult;
    }
}

export type GetPromotionCodeByIdResult = {
    promotionCodeId: string;
    code: string;
    discount: number;
    isDisabled: boolean;
    notes?: string;
};
