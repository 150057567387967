import { useParams } from "react-router-dom"
import { GetOrderById, Order } from "../../../../common/api/handlers/admin/orders/get-order-by-id";
import { useQueryWithAuth } from "../../../../common/api/use-query-with-auth";
import { AdditionalFeesSection } from "./sections/additional-fees";
import { OrderDetailsHeaderSection } from "./sections/header";
import { LineItemsSection } from "./sections/line-items";

export function OrderById() {
    let { orderId } = useParams();
    if (!orderId) {
        throw "OrderId is falsy, this shouldn't be possible."
    }

    let { data, isError, isLoading } = useQueryWithAuth(GetOrderById, { orderId }, [orderId]);

    if (isLoading) {
        return <>Loading...</>
    }

    if (isError || !data) {
        return <>Error</>
    }

    return <OrderByIdImpl order={data} />
}

type OrderByIdImpl = {
    order: Order;
}

function OrderByIdImpl({ order }: OrderByIdImpl) {

    return (
        <div className="pb-3">
            <OrderDetailsHeaderSection order={order} />
            <div className="row mt-3">
                <div className="col-12">
                    <div className="fs-4">Additional Fees</div>
                    <AdditionalFeesSection {...order} />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12">
                    <div className="fs-4">Line Items</div>
                    <LineItemsSection {...order} />
                </div>
            </div>
        </div>
    )
}
