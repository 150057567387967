import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { AuthContext, AuthContextType, AuthContextUser } from "./use-auth";
import { UserManager, UserManagerSettings, User } from "oidc-client-ts";

function AuthProvider({ children, settings }: { children: ReactNode; settings: UserManagerSettings }) {
    let userManager = useMemo(() => new UserManager(settings), [settings]);

    let [isLoading, setIsLoading] = useState<boolean>(true);
    let [user, setUser] = useState<AuthContextUser | null>(null);

    // useEffect(() => {
    //     Log.setLogger(console);
    // })

    useEffect(() => {
        userManager.events.addAccessTokenExpiring(() => {
            console.log("addAccessTokenExpiring");
        });
        userManager.events.addAccessTokenExpired(() => {
            console.log("addAccessTokenExpired");
        });
        userManager.events.addUserSignedIn(() => {
            console.log("addUserSignedIn");
        });
        userManager.events.addUserSignedOut(() => {
            console.log("addUserSignedOut");
        });
        userManager.events.addUserSessionChanged(() => {
            console.log("addUserSessionChanged");
        });
        userManager.events.addUserLoaded((x) => {
            console.log("addUserLoaded");
            setUser(ocidUserToUser(x));
        });
        userManager.events.addUserUnloaded(() => {
            console.log("addUserUnloaded");
            setUser(null);
        });
    }, [userManager]);

    let loadUser = useCallback(async () => {
        setIsLoading(true);
        let user = await userManager.getUser()
        setUser(ocidUserToUser(user));
        setIsLoading(false);
    }, [userManager]);

    useEffect(() => {
        void loadUser();
    }, [loadUser]);

    let login = async (username: string, password: string) => {
        try {
            let user = await userManager.signinResourceOwnerCredentials({ username, password });
            void userManager.storeUser(user);
            return ocidUserToUser(user);
        } catch (error) {
            console.warn(error);
            return null;
        }
    };

    let logout = () => userManager.removeUser();

    let context: AuthContextType = {
        login: login,
        logout: logout,
        isLoading: isLoading,
        user: user
    };

    return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
}

export function Auth({ children }: { children: ReactNode }) {
    const oidcConfig: UserManagerSettings = {
        client_id: "frontend",
        authority: `${window.location.protocol}//${window.location.host}`,
        redirect_uri: "ignore",
        scope: "email profile",
        monitorSession: true,
        loadUserInfo: true
    };

    return <AuthProvider settings={oidcConfig}>{children}</AuthProvider>;
}

function ocidUserToUser(oidcUser?: User | null): AuthContextUser | null {
    if (!oidcUser) {
        return null;
    }
    return {
        emailAddress: oidcUser.profile.email,
        familyName: oidcUser.profile.family_name,
        givenName: oidcUser.profile.given_name,
        isAdmin: !!oidcUser.profile.is_admin,
        isVendor: !!oidcUser.profile.is_vendor,
        accessToken: oidcUser.access_token,
        tokenType: oidcUser.token_type
    };
}
