import { HttpMethodEnum } from "../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../common/utilities/get-auth-request-header";

export type GetSetupTypesResponse = {
    id: string;
    description: string;
};


export const getSetupTypes = async (signal?: AbortSignal): Promise<GetSetupTypesResponse[]> => {
    const url = "/api/v1/order-placement/setup-types";
    let response: Response = await fetch(`${url}`, {
        signal,
        method: HttpMethodEnum.GET,
        headers: getAuthRequestHeaders()
    });

    if (!response?.ok) {
        throw response;
    }

    return await response.json() as GetSetupTypesResponse[];
};
