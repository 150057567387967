import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";

export function useDateTime(isoDateTime?: string | null) {
    return useMemo(() => getDateTime(isoDateTime), [isoDateTime]);
}

export function useTimeAgo(isoDateTime?: string | null) {
    let dateTime = useDateTime(isoDateTime);

    let [cache, setCache] = useState<string | null>();

    let refresh = useCallback(() => {
        setCache(dateTime?.toRelative())
    }, [dateTime]);

    useEffect(() => {
        refresh();
        let interval = setInterval(refresh, 10 * 1000);
        return () => clearInterval(interval);
    }, [refresh]);

    return cache;
}

function getDateTime(isoDateTime?: string | null) {
    if (!isoDateTime) {
        return null;
    }
    return DateTime.fromISO(isoDateTime);
}
