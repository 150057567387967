import { HttpMethodEnum } from "../../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../../common/utilities/get-auth-request-header";

export type PutOrderContactDetailsRequest = {
  name: string;
  emailAddress: string;
  phoneNumber: string;
  allowSmsNotifications: boolean;
};

export const putOrderContactDetails = async (
  orderId: string,
  request: PutOrderContactDetailsRequest,
  signal?: AbortSignal
): Promise<void> => {
  const url = `/api/v1/orders/${orderId}/contact-details`;
  let response: Response = await fetch(`${url}`, {
    signal,
    method: HttpMethodEnum.PUT,
    body: JSON.stringify(request),
    headers: getAuthRequestHeaders(),
  });

  if (!response?.ok) {
    throw response;
  }
};
