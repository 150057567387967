import "./order-summary.scss";

import clsx from "clsx";
import { useMemo, useState } from "react";
import { PaymentMethodMessagingElement } from "@stripe/react-stripe-js";
import { Money } from "../../../common/utilities/formatters/money";
import { LoadingIcon } from "../../../common/utilities/loading-icon";
import { getAdditionalFeeName } from "../../../common/utilities/orders/get-additional-fee-name";
import { PlaceOrderButton } from "./place-order-button";
import { useOrderContext } from "./provider/use-order-context";

export function OrderSummary() {
    let { state: { order, isLoading } } = useOrderContext();

    let finalAmount = useMemo(
        () => order?.totalPriceIncludingTaxes ?? order?.totalPriceExcludingTaxes ?? 0,
        [order?.totalPriceExcludingTaxes, order?.totalPriceIncludingTaxes]
    );

    let [messagingReady, setMessagingReady] = useState(false);

    let showPaymentMessaging = useMemo(
        () => messagingReady
            && finalAmount > 50 // Affirm: $50 to $25,000
            && finalAmount < 25000,
        [messagingReady, finalAmount]);

    if (!order) {
        return (
            <div className="d-flex justify-content-center align-items-center mt-3">
                <LoadingIcon />
            </div>
        )
    }

    let additionalFees = order?.additionalFees ?? [];

    return (
        <div className="order-summary d-flex flex-column mt-3 sticky-top" style={{ top: 100 }}>

            <div className="d-flex flex-column border rounded p-3 bg-white">

                <div className="fs-4">Summary</div>

                <div className="d-flex flex-column position-relative">

                    <div className="mt-2 d-flex">
                        <div className="title">Party Items</div>
                        <div className="ms-auto">
                            <Money amount={order.lineItemsPriceExcludingTaxes} />
                        </div>
                    </div>

                    {!!order.addOnPriceExcludingTaxes && (
                        <div className="mt-2 d-flex">
                            <div className="title">Additional Options</div>
                            <div className="ms-auto">
                                <Money amount={order.addOnPriceExcludingTaxes} />
                            </div>
                        </div>
                    )}

                    {additionalFees.toSorted((a, b) => a.name > b.name ? -1 : 1).map(x => (
                        <div key={x.name} className="mt-2 d-flex">
                            <div className="title">{getAdditionalFeeName(x.name)}</div>
                            <div className="ms-auto">
                                <Money amount={x.totalPrice} />
                            </div>
                        </div>
                    ))}

                    <div className="mt-2 d-flex">
                        <div className="title">Subtotal</div>
                        <div className="ms-auto">
                            <Money amount={order.totalPriceExcludingTaxes} />
                        </div>
                    </div>

                    <hr />

                    <div className="mt-2 d-flex">
                        <div className="title">Taxes</div>
                        <div className="ms-auto">
                            <Money amount={order.totalTaxes} />
                        </div>
                    </div>

                    <div className="mt-2 d-flex">
                        <div className="title">Total</div>
                        <div className="ms-auto">
                            <strong>
                                <Money amount={finalAmount} />
                            </strong>
                        </div>
                    </div>

                    {isLoading && (
                        <div className="position-absolute top-0 bottom-0 start-0 end-0 d-flex align-items-center justify-content-center"
                            style={{ backgroundColor: "rgba(var(--bs-white-rgb), 0.8)" }}>
                            <LoadingIcon />
                        </div>
                    )}
                </div>
            </div>

            <div className={clsx("mt-3 rounded bg-white py-3 px-4 border", !showPaymentMessaging && "d-none")}>
                <PaymentMethodMessagingElement onReady={() => setMessagingReady(true)} options={{
                    paymentMethodTypes: ["affirm"],
                    countryCode: "US",
                    currency: "USD",
                    amount: (Math.floor(finalAmount * 100))
                }} />
            </div>

            <div className="d-block d-md-none my-3">
                <PlaceOrderButton className="w-100" />
            </div>

        </div>
    )
}

