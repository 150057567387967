import { Link } from "react-router-dom";
import { AnimatedCheckbox } from "../../../../common/utilities/animated-checkbox";
import { Money } from "../../../../common/utilities/formatters/money";
import { PlaceOrderButton, useCheckout } from "../place-order-button";
import { useOrderContext } from "../provider/use-order-context";

export function OrderConfirmOrderSection() {
    let { state: { termsAccepted }, actions: { setTermsAccepted } } = useOrderContext();
    let { total } = useCheckout();

    return (
        <>
            <section>
                <TermsAndConditionsAcceptance accepted={termsAccepted} setAccepted={setTermsAccepted} total={total} />
            </section>
            <section className="d-none d-md-flex mt-3">
                <PlaceOrderButton className="ms-auto" />
            </section>
        </>
    )
}

function TermsAndConditionsAcceptance({ accepted, setAccepted, total }: { accepted: boolean, setAccepted: (accepted: boolean) => void, total: number }) {
    return (
        <div className="d-flex flex-column mt-2 p-3 border bg-white rounded">
            <div className="d-flex">
                <div className="form-check mb-0 d-flex align-items-start justify-content-center">
                    <AnimatedCheckbox
                        className="form-check-input mt-2 me-2"
                        name="terms-and-conditions"
                        id="terms-and-conditions"
                        checked={accepted}
                        onChange={() => setAccepted(!accepted)}
                        style={{ width: 24, height: 24 }} />
                    <label className="form-check-label user-select-none ms-2 mt-2" htmlFor="terms-and-conditions">
                        I acknowledge that I have reviewed the <Link to="/terms-and-conditions" target="_blank">Cancellation Policy</Link> and <Link to="/terms-and-conditions" target="_blank">Terms and Conditions</Link> and agree to pay <Money amount={total} />. I have reviewed the <Link to="/faq" target="_blank">FAQ page</Link> and understand that any modifications, changes, inquiries, or cancellation requests need to be directed to Partyeezy.com. I also acknowledge that if my party is within seven days of today you will be charged in full, otherwise 25%. If you choose to use our &quot;Party Now, Pay Later&quot; option through Affirm, you may be eligible to pay over time at 0% APY, depending on eligibility.
                    </label>
                </div>
            </div>
        </div>
    )
}
