import { useMemo, useState } from "react";
import { SimpleTooltip } from "../../../../../common/utilities/simple-tooltip";
import { GetProductItem } from "../../../../../services/public/ordering/get-products";
import { useOrderContext } from "../../provider/use-order-context";
import { Counter } from "./counter";
import { ProductThumbnail } from "./product-thumbnail";

type ProductItemProps = {
    product: GetProductItem;
}

export const ProductItem = ({ product }: ProductItemProps) => {
    const { actions: { setLineItems }, state: { order, isLoading } } = useOrderContext();
    let { lineItems } = order ?? { lineItems: undefined };
    let [count, setCount] = useState<number>(0);

    const buttonTooltip = useMemo(() => {
        return count <= 0 || isLoading ? "Update count to add item" : "";
    }, [count, isLoading]);

    const handleAddItem = async () => {
        try {
            const controller = new AbortController();
            await setLineItems([...lineItems || [], { productId: product.id, count }], controller.signal);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCountUpdate = (count: number) => {
        setCount(count);
    };

    return (
        <div className="col-12 product-item" key={product.id}>
            <div className="card">
                <div className="row g-0">
                    <div className="col-6 col-lg-2 d-flex align-items-center">
                        <ProductThumbnail src={product.primaryImageLink} name={product.name} className="m-3 me-md-0 rounded-start" />
                    </div>
                    <div className="col-12 col-lg-10">
                        <div className="w-100 card-body d-flex flex-column flex-md-row align-items-md-center">
                            <header className="d-flex flex-column col-12 col-lg-6 p-2">
                                <h5 className="card-title">{product.name}</h5>
                                <p className="card-text">{product.description || ""}</p>
                            </header>
                            <Counter
                                value={count}
                                disabled={isLoading}
                                onChange={(count) => handleCountUpdate(count)}
                                counterLowerLimit={0}
                                className="mb-3 mb-md-0 ms-md-auto"
                                unit={product.unit}
                                unitPlural={product.unitPluralized} />
                            <SimpleTooltip title={buttonTooltip}>
                                <span className=" ms-auto d-inline-block" tabIndex={0}>
                                    <button
                                        className="btn btn-outline-primary"
                                        onClick={handleAddItem}
                                        disabled={count <= 0 || isLoading}>
                                        Add Item
                                    </button>
                                </span>
                            </SimpleTooltip>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}
