import "./order-timeline.scss";

import clsx from "clsx";
import { DateTime } from "luxon";
import React, { ReactNode, useMemo } from "react";
import { BsClipboard2CheckFill } from "react-icons/bs";
import { FaTent } from "react-icons/fa6";
import { LuPartyPopper } from "react-icons/lu";
import { MdOutlinePayment } from "react-icons/md";
import { ColorRing } from "react-loader-spinner";
import { FormatDate, FormatDateWithAgo } from "../../../common/utilities/formatters/format-date";
import { Money } from "../../../common/utilities/formatters/money";
import { GetOrderByIdResponse } from "../../../services/public/ordering/get-order-by-id";

type Order = GetOrderByIdResponse;

type PossibleSegment = {
    element: (props: SegmentProps) => ReactNode;
    isCompleted: (order: Order) => boolean;
}

const segments: PossibleSegment[] = [
    {
        element: (props) => <OrderPlacedSegment {...props} />,
        isCompleted: (order) => order.state == "paymentPending" || order.state == "paymentConfirmed" || order.state == "paymentIssue"
    },
    {
        element: (props) => <PaymentConfirmationSegment {...props} />,
        isCompleted: (order) => order.state == "paymentConfirmed"
    },
    {
        element: (props) => <RentalDeliverySegment {...props} />,
        isCompleted: (order) => order.state == "paymentConfirmed"
            && DateTime.now() >= DateTime.fromISO(order.eventSchedule.startOn).startOf("day")
    },
    {
        element: (props) => <EventSegment {...props} />,
        isCompleted: (order) => order.state == "fulfilled"
    },
];

export function OrderTimeline({ order }: { order: Order }) {

    // order.state = "fulfilled";

    let lastCompletedIndex = segments.findLastIndex(x => x.isCompleted(order));
    let currentlyProcessingIndex = lastCompletedIndex + 1;

    return (
        <div className="order-timeline py-5 ms-3">
            {segments.map((x, i) => {

                let completed = i <= lastCompletedIndex;
                let processing = i == currentlyProcessingIndex;

                let state: SegmentState = "future";
                if (completed) {
                    state = "completed";
                } else if (processing) {
                    state = "processing";
                }

                return (
                    <React.Fragment key={i}>
                        {x.element({ order, state })}
                    </React.Fragment>
                )
            })}
        </div>
    )
}

type SegmentState =
    | "completed"
    | "processing"
    | "future";

type SegmentProps = {
    order: GetOrderByIdResponse
    state: SegmentState;
}

function OrderPlacedSegment({ order, state }: SegmentProps) {

    let title = state == "completed"
        ? <>Order #{order.humanReadableOrderId} Placed!</>
        : <>Order Placement Pending</>
    let description = state == "completed"
        ? <>Order #{order.humanReadableOrderId} was received by Partyeezy!</>
        : <>Awaiting order...</>

    return (
        <BaseSegment
            state={state}
            icon={className => <BsClipboard2CheckFill className={className} />}
            title={title}
            description={description}
        />
    );
}

function PaymentConfirmationSegment({ order, state }: SegmentProps) {

    let body = useMemo(() => {
        if (state == "completed") {
            // Payment Confirmed
            return {
                title: <>Payment Confirmed!</>,
                description: <>Payment of <Money amount={order.totalPriceIncludingTaxes} /> confirmed!</>
            }
        } else if (order.state == "paymentIssue") {
            return {
                title: <>Payment Issue</>,
                description: <>
                    <div>Our payment processor failed to confirm the payment. We&apos; ll try again soon.</div>
                    <div>Reach out to us if it appears our system continues having trouble.</div>
                    <EmailUsButton />
                </>
            }
        } else if (order.state == "paymentPending") {
            return {
                title: <>Payment Awaiting Confirmation</>,
                description: <>
                    <div>Awaiting payment confirmation from our payment processor for <Money amount={order.totalPriceIncludingTaxes} />.</div>
                    <div>This step is normally fast, but sometimes it can take a bit. <i>Banks, right?</i></div>
                </>
            }
        } else {
            return {
                title: <>Payment Awaiting Confirmation</>,
                description: <>Awaiting payment of <Money amount={order.totalPriceIncludingTaxes} /></>
            }
        }
    }, [order.state, order.totalPriceIncludingTaxes, state]);

    return (
        <BaseSegment
            state={state}
            icon={className => <MdOutlinePayment className={className} />}
            title={body.title}
            description={body.description}
        />
    );
}


function RentalDeliverySegment({ order, state }: SegmentProps) {

    let rentalItems = useMemo(() => order.lineItems.filter(x => x.kind == "rental"), [order.lineItems]);

    let body = useMemo(() => {
        if (state == "future") {
            return {
                title: <>Pending Rental Delivery Scheduling</>,
                description: <>
                    <div>
                        Delivery of {rentalItems.length} rental items on will be scheduled to occur before <FormatDateWithAgo dateTime={order.eventSchedule.startOn} /> after payment has been confirmed.
                    </div>
                    <ul className="mt-3">
                        {rentalItems.map((x, i) => (
                            <li key={i}>
                                {x.productName} x{x.count}
                            </li>
                        ))}
                    </ul>
                </>
            }
        } else if (state == "processing") {
            return {
                title: <>Rental Delivery Scheduled</>,
                description: <>
                    <div>
                        Awaiting delivery of {rentalItems.length} rental items for the event on <FormatDateWithAgo dateTime={order.eventSchedule.startOn} />.
                    </div>
                    <ul className="mt-3">
                        {rentalItems.map((x, i) => (
                            <li key={i}>
                                {x.productName} (x{x.count})
                            </li>
                        ))}
                    </ul>
                    <div>Something missing? We are always here.</div>
                    <EmailUsButton />
                </>
            }
        } else {
            return {
                title: <>Rentals Delivered!</>,
                description: <>
                    <div>
                        {rentalItems.length} rentals were delivered.
                    </div>
                    <ul className="mt-3">
                        {rentalItems.map((x, i) => (
                            <li key={i}>
                                {x.productName} (x{x.count})
                            </li>
                        ))}
                    </ul>
                </>
            }
        }
    }, [order.eventSchedule.startOn, rentalItems, state]);

    return (
        <BaseSegment
            state={state}
            icon={className => <FaTent className={className} />}
            title={body.title}
            description={body.description}
        />
    );
}


function EventSegment({ order, state }: SegmentProps) {

    let serviceItems = useMemo(() => order.lineItems.filter(x => x.kind == "service"), [order.lineItems]);

    let body = useMemo(() => {
        if (state == "future") {
            return {
                title: <>The Big Event</>,
                description: <>
                    <div>We can&apos;t wait! Expect the following on <FormatDate dateTime={order.eventSchedule.startOn} />:</div>
                    <ul className="mt-3">
                        {serviceItems.map((x, i) => (
                            <li key={i}>
                                {x.productName}
                            </li>
                        ))}
                    </ul>
                </>
            }
        } else if (state == "processing") {
            return {
                title: <>The Big Event</>,
                description: <>
                    <div>Woohoo! The big day is here! Are you excited? Because we are!</div>
                    <div className="mt-3">Expect the following today:</div>
                    <ul className="mt-3">
                        {serviceItems.map((x, i) => (
                            <li key={i}>
                                {x.productName}
                            </li>
                        ))}
                    </ul>
                    <div>Something go wrong? We are always here.</div>
                    <EmailUsButton />
                </>
            }
        } else {
            return {
                title: <>The Big Event</>,
                description: <>
                    <div>Well, that was fun!</div>
                    <div>Something go wrong? We are always here.</div>
                    <EmailUsButton />
                </>
            }
        }
    }, [order.eventSchedule.startOn, serviceItems, state]);

    return (
        <BaseSegment
            state={state}
            icon={className => <LuPartyPopper className={className} />}
            title={body.title}
            description={body.description}
        />
    );
}

type BaseSegmentProps = {
    icon: (className?: string) => ReactNode;
    title: ReactNode;
    description?: ReactNode;
    state: SegmentState;
}

function BaseSegment({ icon, title, description, state }: BaseSegmentProps) {
    return (
        <div className={clsx("segment ms-5 position-relative mt-5", `state-${state}`)}>
            <div className="position-absolute top-0 start-0">
                <div className="dot d-flex align-items-center justify-content-center">
                    {icon("icon")}
                </div>
            </div>
            <h2 className="fw-light d-flex align-items-center title">
                <ColorRing
                    visible={state == "processing"}
                    colors={["var(--bs-primary)", "var(--bs-primary)", "var(--bs-primary)", "var(--bs-primary)", "var(--bs-primary)"]}
                    ariaLabel="rings-loading"
                    height={32}
                    width={32}
                    wrapperStyle={{}}
                    wrapperClass="progressing-icon me-2"
                />
                {title}
            </h2>
            {!!description && (
                <div className="text-muted">
                    {description}
                </div>
            )}
        </div>
    )
}

function EmailUsButton() {
    return (
        <a href="mailto:hello@partyeezy.com" className="btn btn-outline-primary mt-2">Contact Us</a>
    )
}
