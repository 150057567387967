export const NotFoundTestId = "not-found";

export const NotFound = () => {
    return (
        <h2 className="pt-5 pl-5" data-testid={NotFoundTestId}>
            Page Not Found.
        </h2>
    );
};

export default NotFound;
