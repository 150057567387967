import { getDefaultHeaders } from "../../../get-default-headers";
import { ApiHandlerWithAuth } from "../../api-handler-with-auth";

export type IsPromotionCodeAvailableInput = {
    code: string;
};

export const IsPromotionCodeAvailable: ApiHandlerWithAuth<IsPromotionCodeAvailableInput, IsPromotionCodeAvailableResult> = {
    handle: async ({ code }, authHeader) => {
        let response = await fetch(`/api/v1/admin/promotions/code-available/${code}`, {
            method: "GET",
            headers: getDefaultHeaders(authHeader),
        });

        if (!response.ok) {
            throw response;
        }

        return await response.json() as IsPromotionCodeAvailableResult;
    }
}

export type IsPromotionCodeAvailableResult = {
    available: boolean;
};
