import { HttpMethodEnum } from "../../../common/enums/http-method-enum";
import { getAuthRequestHeaders } from "../../../common/utilities/get-auth-request-header";

export type OrderLineItem = {
  productId: string;
  count: number;
  arrivalDateTime?: string | null;
  comment?: string;
  childrenLineItems?: Omit<OrderLineItem, "childrenLineItems">[];
};

export type PutOrderLineItemsRequest = OrderLineItem[];

export const putOrderLineItems = async (
  orderId: string,
  request: PutOrderLineItemsRequest,
  signal?: AbortSignal
): Promise<void> => {
  const url = `/api/v1/orders/${orderId}/line-items`;
  let response: Response = await fetch(`${url}`, {
    signal,
    method: HttpMethodEnum.PUT,
    body: JSON.stringify(request),
    headers: getAuthRequestHeaders(),
  });

  if (!response?.ok) {
    throw response;
  }
};
