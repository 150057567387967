import { createContext } from "react";
import { GetFrontendConfigurationResponse } from "../../../services/configuration/get-frontend-configuration";

export type FrontendConfiguration = GetFrontendConfigurationResponse;

export type FrontendConfigurationContextProps = {
    isLoading: boolean;
    configuration?: FrontendConfiguration
}

export const FrontendConfigurationContextDefaults: FrontendConfigurationContextProps = {
    isLoading: true,
    configuration: undefined
}

export const FrontendConfigurationContext = createContext<FrontendConfigurationContextProps>(FrontendConfigurationContextDefaults);
