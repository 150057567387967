import clsx from "clsx";

export type ProductThumbnailProps = {
    name: string;
    src?: string | null;
    className?: string;
}

export function ProductThumbnail({ src, name, className }: ProductThumbnailProps) {
    return (
        <div className={clsx("ratio ratio-1x1", className)}>
            {src
                ? (
                    <img className="rounded border img-fluid bg-body-tertiary" src={src} alt={name} style={{ objectFit: "cover" }} />
                )
                : (
                    <div className="rounded border bg-body-tertiary"></div>
                )}
        </div>
    )
}
